export const wel = {
  LOGIN: "Mewngofnodi",
  SIGNUP: "Cofrestru",
  LOGO_TEXT_ONE: "gan",
  HOME_HEADER: "Hafan",
  CIRCLES_HEADER: "Cylchoedd ",
  NOTIFICATIONS_HEADER: "Hysbysiadau",
  SEARCH_PLACEHOLDER: "Chwilio Meddyginiaethau, Nodiadau, Ac Ati...",
  QUICK_ADD: "Eitem newydd",
  MESSAGE_QUICKADD: "Neges newydd",
  NOTE_QUICKADD: "Nodyn newydd",
  TASK_QUICKADD: "Tasg newydd",
  EVENT_QUICKADD: "Digwyddiad newydd",
  MEDICATION_QUICKADD: "Meddyginiaeth newydd",
  CONTACT_QUICKADD: "Cyswllt newydd",
  SIGNUP_TITLE: "Cofrestru i Jointly",
  SIGNUP_SUBTITLE:
    "Cofrestrwch i greu eich cyfrif Jointly.  Yna, gallwch ddefnyddio eich enw defnyddiwr a'ch cyfrinair i gael mynediad i Jointly ar unrhyw ddyfais",
  FIRST_NAME_SIGNUP_PLACEHOLDER: "Enw cyntaf",
  SUR_NAME_SIGNUP_PLACEHOLDER: "Cyfenw",
  EMAIL_SIGNUP_PLACEHOLDER: "Cyfeiriad e-bost",
  PASSWORD_SIGNUP_PLACEHOLDER: "Dewiswch gyfrinair",
  TERMS_SIGNUP_TEXT_ONE: "Rwyf wedi darllen a chytuno i",
  TERMS_SIGNUP_TEXT_TWO: "Delerau Defnyddio",
  AND: "a",
  TERMS_SIGNUP_TEXT_THREE: "Pholisi Preifatrwydd Jointly",
  UPDATES_NEWS_SIGNUP_TEXT:
    "Cliciwch yma os ydych chi eisiau derbyn newyddion a diweddariadau",
  SIGNUP_BUTTON: "Cofrestru",
  LOGIN_LINK_SIGNUP: "Gyda chyfrif yn barod?",
  FIRST_NAME_BLANK_ERROR_SIGNUP: "Enw cyntaf",
  LAST_NAME_BLANK_ERROR_SIGNUP: "Cyfenw",
  EMAIL_BLANK_ERROR: "E-bost",
  PASSWORD_BLANK_ERROR: "Cyfrinair",
  TERMS_BLANK_ERROR_SIGNUP: "Derbyniwch y Telerau Gwasanaeth",
  EMAIL_INVALID_ERROR: "Rhowch gyfeiriad e-bost dilys",
  EMAIL_DUPLICATE_ERROR_SIGNUP:
    "Sori, mae'r e-bost wedi cael ei ddefnyddio'n barod.",

  PASSWORD_INVALID_LENGTH_ERROR:
    "Mae'n rhaid i'r cyfrinair fod o leiaf 8 cymeriad o hyd",
  PASSWORD_INVALID_UPPER_ERROR:
    "Mae'n rhaid i'r cyfrinair gynnwys o leiaf un llythyren fawr ",
  PASSWORD_INVALID_LOWER_ERROR:
    "Mae'n rhad i'r cyfrinair gynnwys o leiaf un llythyren fach",
  PASSWORD_INVALID_NUM_ERROR:
    "Mae'n rhaid i'r cyfrinair gynnwys o leiaf un rhif",
  PASSWORD_INVALID_ALPHANUM_ERROR:
    "Mae'n rhaid i'r cyfrinair gynnwys o leiaf un cymeriad sydd ddim yn alffaniwmerig (! @ # $ % ^ & * ( ) _ + - = [ ] { } | ')",
  PASSWORD_WEEK: "Cyfrinair Gwan",

  LOGIN_TITLE: "Mewngofnodi i Jointly",
  OTP_VERIFY:  "Otp Gwirio",
  VERIFY_SUBTITLE: "Rhowch yr OTP a anfonwyd i'ch cyfeiriad e-bost cofrestredig",
  VERIFY_BUTTON: "Gwirio",
  VERIFY_RESEND_OTP: "Ail-anfon OTP",
  OTPRESENDTEXT: "Onid ydych wedi derbyn eich cod?",
  OTPLOGIN: "ID e-bost ddim yn gywir?",
  LOGIN_SUBTITLE:
    "Please note - if this is the first time you are logging in to the new version of Jointly you will need to reset your password first.",
  LOGIN_SUBTITLE:
    "Sylwer - os mai dyma'r tro cyntaf rydych chi'n mewngofnodi i fersiwn newydd Jointly, bydd angen i chi ailosod eich cyfrinair i ddechrau",
  EMAIL_LOGIN_PLACEHOLDER: "Cyfeiriad e-bost",
  PASSWORD_LOGIN_PLACEHOLDER: "Cyfrinair",
  FORGOT_PASSWORD_LINK_LOGIN: "Wedi Anghofio'ch Cyfrinair?",
  LOGIN_BUTTON: "Mewngofnodi",
  SIGNUP_LINK_LOGIN_ONE: "Dim gyda cyfrif eto?",
  SIGNUP_LINK_LOGIN_TWO: "Cofrestrwch",

  FORGOT_PASSWORD_TITLE: "Wedi anghofio'r cyfrinair",
  FORGOT_PASSWORD_SUBTITLE:
    "Rhowch eich cyfeiriad e-bost i ailosod eich cyfrinair",
  FORGOT_PASSWORD_BUTTON: "Wedi anghofio'r cyfrinair",
  LOGIN_LINK_FORGOT_PASSWORD: "Dychwelwch i'r sgrîn mewngofnodi",
  SUCCESS_FORGOT_PASSWORD: `Os oes gennych chi gyfrif wedi'i gofrestru gyda'r cyfeiriad e-bost hwn, rydym wedi anfon e-bost atoch chi sy'n cynnwys dolen i ailosod eich cyfrinair.\n
        Os nad ydy'r e-bost yn cyrraedd o fewn ychydig funudau, ewch i'ch ffolderi sbam neu jync, a gwiriwch eich bod chi wedi rhoi'r un cyfeiriad e-bost a ddefnyddioch chi i gofrestru eich cyfrif Jointly.`,

  LANDING_TITLE: "Creu Cylch newydd",
  LANDING_SUBTITLE_ONE: "Creu cylch Jointly newydd",
  LANDING_SUBTITLE_TWO: "Prynu cylch",
  LANDING_SUBTITLE_THREE: "Beth sy'n digwydd pan rydych chi'n prynu cylch?",
  LANDING_SUBTITLE_FOUR: "Creu demo gylch",
  LANDING_SUBTITLE_ONE_TEXT:
    "Drwy greu cylch gofal ar gyfer y sawl rydych chi'n gofalu amdano,",
  LANDING_SUBTITLE_ONE_TEXT_SUB:
    " fe fyddwch chi'n gallu gwahodd cyn gymaint o bobl ag y dymunwch, neu ddefnyddio Jointly ar eich pen eich hun i drefnu gofal.",
  LANDING_SUBTITLE_TWO_TEXT_ONE:
    "I greu eich cylch, mae angen i chi dalu £#FIELD# (unwaith yn unig) neu roi Cod Mynediad am Ddim os oes gennych chi un.",
  LANDING_SUBTITLE_TWO_TEXT_ONEA:
    "If someone has invited you to join their Jointly circle then this will appear in your Notifications (top right of this screen), please accept the invitation to join their circle.",
  LANDING_SUBTITLE_TWO_TEXT_TWO: "Does dim ",
  LANDING_SUBTITLE_TWO_TEXT_TWO_SUB: "ffïoedd ychwanegol ar",
  LANDING_SUBTITLE_TWO_TEXT_TWO_SUB2:
    "eich cyfer chi nac unrhyw aelod arall o'r cylch tu hwnt i hynny.",
  LANDING_SUBTITLE_TWO_TEXT_THREE:
    "Gallwch brynu mwy nag un cylch os ydych chi eisiau (er enghraifft, os ydych chi'n gofalu am fwy nag un person).",
  LANDING_SUBTITLE_THREE_TEXT_ONE:
    "Gallwch gyfathrebu  a chydweithio gydag eraill yn y cylch gofal",
  LANDING_SUBTITLE_THREE_TEXT_TWO:
    "Gallwch drefnu gwell gofal drwy gael mynediad i holl nodweddion Jointly, sy'n cynnwys meddyginiaeth a rhestri tasgau, calendr, ac ati.",
  LANDING_SUBTITLE_THREE_TEXT_THREE:
    "Mae eich data'n cael ei storio'n rheolaidd ar weinydd diogel",
  LANDING_SUBTITLE_FOUR_TEXT:
    "Fel arall, gallwch greu demo gylch, a fydd yn caniatau i chi roi gynnig ar Jointly am 14 diwrnod, a'i brynu wedyn am £#FIELD#. Gallwch gael mynediad i'ch gwybodaeth o hyd, ond bydd angen i chi brynu trwydded er mwyn cofnodi unrhyw wybodaeth newydd ar ôl i'r cyfnod demo ddod i ben.",
  LANDING_PAYABLE_BUTTON: "Yn daladwy - £#FIELD#",
  LANDING_ACCESS_CODE_BUTTON: "Defnyddiwch y Cod Mynediad am Ddim",
  LANDING_CREATE_DEMO_CIRCLE_BUTTON: "Creu demo gylch",

  DASHBOARD_TITLE: "Croeso i Jointly",
  DASHBOARD_SUBTITLE: "Eich cylchoedd",
  DASHBOARD_SUB_ONE: "Tasgau, digwyddiadau a nodiadau atgoffa sydd ar ddod",
  DASHBOARD_NO_TASKS_MESSAGE: "Dim tasgau a digwyddiadau i ddod",
  DASHBOARD_SUB_TWO: "Gweithgareddau'ch cylchoedd ",
  DASHBOARD_END: "Iei! Rydych chi wedi gweld bob eitem",
  DASHBOARD_CREATED: "Crëwyd ar",

  SIDE_MENU_MEMBERS: "Aelodau",
  SIDE_MENU_CIRCLE_SETTINGS: "Gosodiadau cylch",
  SIDE_MENU_OVERVIEW: "Trosolwg",
  SIDE_MENU_PROFILE: "Proffil",
  SIDE_MENU_MESSAGES: "Negeseuon",
  SIDE_MENU_CALENDAR: "Calendr",
  SIDE_MENU_NOTES: "Nodiadau",
  SIDE_MENU_TASKS: "Tasgau",
  SIDE_MENU_REMINDER: "Atgof",
  SIDE_MENU_CONTACTS: "Cysylltiadau",
  SIDE_MENU_MEDICATIONS: "Meddyginiaethau",
  SIDE_MENU_DEVICES: "Dyfeisiau",
  SIDE_MENU_YOUR_CIRCLES: "Eich cylchoedd",
  SIDE_MENU_CIRCLE_SHOW_HIDE_TOOLTIP: "Dangos/Cuddio fy nghylchoedd",
  CIRCLE_SHOW_HIDE_MODAL_TITLE: "Dangos/Cuddio fy nghylchoedd",
  SELECT_CIRCLE_LABEL: "Dewiswch gylch",
  CHOOSE_CIRCLE_VISIBILITY_TYPE_LABEL: "Dewiswch fath",
  HIDE_SHOW_CIRCLE_OPTION1: "Cuddio cylch o fy marn i yn unig",
  HIDE_SHOW_CIRCLE_OPTION2: "Cuddio cylch rhag pob aelod",
  HIDE_SHOW_CIRCLE_OPTION3: "Dangos cylch i bob aelod",
  HIDE_SHOW_CIRCLE_OPTION4: "Dangos cylch o fy marn i yn unig",
  SIDE_MENU_HIDDEN_CARE_CIRCLES: "Cylchoedd Gofal Cudd",
  CIRCLE_HIDE_MODAL_TITLE: "Cuddiwch gylchoedd eraill",
  CIRCLE_SHOW_MODAL_TITLE: "Dangos cylchoedd cudd",
  CIRCLE_HIDE_BUTTON_LABEL: "Cuddiwch fy nghylch",
  CIRCLE_SHOW_BUTTON_LABEL: "Dangoswch fy nghylch",
  SIDE_MENU_CREATE_CIRCLE: "Creu cylch newydd",
  SIDE_MENU_DASHBOARD: "Dangosfwrdd",
  SIDE_MENU_HOME: "Hafan",
  SIDE_MENU_MY_ACCOUNT: "Fy Nghyfrif",
  SIDE_MENU_NOTIFICATION_SETTINGS: "Gosodiadau hysbysiadau",
  SIDE_MENU_LOGOUT: "Allgofnodi",

  FOOTER_ONE:
    "Mae eich treial yn dod i ben mewn #FIELD# diwrnod.  Os ydych chi eisiau parhau i ddefnyddio Jointly, dylech uwchraddio eich cylch",
  FOOTER_BUTTON_UPGRADE_NOW: "Uwchraddio nawr",

  MY_ACCOUNT_TITLE: "Opsiynau Dewislen",
  MY_ACCOUNT_FIRSTNAME: "Fy nghyfrif",
  MY_ACCOUNT_FIRSTNAME_PLACEHOLDER: "Enw cyntaf",
  MY_ACCOUNT_SURNAME: "Eich cyfenw",
  MY_ACCOUNT_SURNAME_PLACEHOLDER: "Cyfenw",
  MY_ACCOUNT_PROFILE_PICTURE: "Eich llun proffil",
  MY_ACCOUNT_EDIT_IMAGE_BUTTON: "Golygu'r llun",
  MY_ACCOUNT_ADD_IMAGE_BUTTON: "ychwanegu llun",
  MY_ACCOUNT_CONTACT_TITLE: "Manylion cyswllt",
  MY_ACCOUNT_CONTACT_SUBTITLE:
    "Gall yr wybodaeth gyswllt hon gael ei gweld gan aelodau eraill o'r cylch",
  MY_ACCOUNT_EMAIL: "Eich e-bost",
  MY_ACCOUNT_ADDRESS: "Eich cyfeiriad",
  MY_ACCOUNT_ADDRESS_PLACEHOLDER: "Cyfeiriad",
  MY_ACCOUNT_CITY: "Eich dinas",
  MY_ACCOUNT_CITY_PLACEHOLDER: "Dinas",
  MY_ACCOUNT_POSTCODE: "Eich cod post",
  MY_ACCOUNT_TELEPHONE: "Eich rhif ffôn",
  MY_ACCOUNT_TELEPHONE_PLACEHOLDER: "Rhif ffôn",
  MY_ACCOUNT_ALT_PHONE: "Rhif ffôn arall",
  MY_ACCOUNT_ALT_PHONE_PLACEHOLDER: "Rhif ffôn arall",
  MY_ACCOUNT_RELATIONSHIP: "Perthynas",
  MY_ACCOUNT_NOTE: "Nodyn",
  MY_ACCOUNT_SAVE_BUTTON: "Arbed",
  MY_ACCOUNT_CANCEL_BUTTON: "Canslo",
  MY_ACCOUNT_FIRST_NAME_BLANK: "Enw cyntaf!",
  MY_ACCOUNT_LAST_NAME_BLANK: "Cyfenw!",
  MY_ACCOUNT_SUCCESS_MESSAGE: "Proffil y defnyddiwr wedi'i ddiweddaru!",
  MY_ACCOUNT_SUCCESS_MESSAGE2:
    "Rydych chi wedi newid eich cyfeiriad e-bost yn llwyddiannus.  Cofiwch fewngofnodi yn defnyddio'r cyfeiriad newydd hwn.",

  MY_ACCOUNT_CHANGE_PW_TITLE: "Newid eich cyfrinair",
  MY_ACCOUNT_CHANGE_PW_NEW_PASSWORD: "Cyfrinair newydd",
  MY_ACCOUNT_CHANGE_PW_CONFIRM_PASSWORD: "Cadarnhau eich cyfrinair",
  MY_ACCOUNT_CHANGE_PW_SAVE_BUTTON: "Arbed",
  MY_ACCOUNT_CHANGE_PW_CANCEL_BUTTON: "Canslo",
  MY_ACCOUNT_CHANGE_PW_NEW_PASSWORD_BLANK: "Cyfrinair newyddl",
  MY_ACCOUNT_CHANGE_PW_CONFIRM_PASSWORD_BLANK:
    "Cadarnhau eich cyfrinair newydd!",
  MY_ACCOUNT_CHANGE_PW_DIFF_PWDS: "Dydy eich cyfrineiriau ddim yr un fath",
  MY_ACCOUNT_CHANGE_PW_SUCCESS: "Cyfrinair wedi'i newid yn llwyddiannus",

  MY_ACCOUNT_DLT_ACCOUNT_INFO_ONE:
    "Mae'n rhaid i chi adael ac/neu ddileu unrhyw gylchoedd cyn i chi fedru dileu eich cyfrif",
  MY_ACCOUNT_DLT_ACCOUNT_INFO_TWO:
    "Mae'r rhan fwyaf o'r wybodaeth yn Jointly yn cael ei storio yn eich cylchoedd. Os nad ydy chi eisiau defnyddio Jointly rhagor, gadewch y sgrîn hon, ewch i osodiadau'r Cylch, a gadewch neu dilewch unrhyw gylchoedd. Yna, byddwch yn gallu dileu eich cyfrif yma.",
  MY_ACCOUNT_DLT_BUTTON: "Dileu",

  ACC_SETTINGS_TITLE: "Gosodiadau'r cyfrif",
  ACC_SETTINGS_MY_CONTACT_INFO_LINK: "Fy manylion cyswllt",
  ACC_SETTINGS_CHANGE_PW_LINK: "Newid eich cyfrinair",
  ACC_SETTINGS_DELETE_ACCOUNT_LINK: "Dileu",
  JOINTLY_AT_A_GLANCE_LINK: "Jointly - cipolwg",
  USER_GUIDE_LINK: "Canllaw defnyddwyr",
  ABOUT_CONTACT_LINK: "Amdanom/Cysylltu",

  NOTIFICATION_SETTINGS_TITLE: "Gosodiadau",
  NOTIFICATION_SETTINGS_CREATE_CIRCLE_BUTTON: "Creu Cylch newydd",
  NOTIFICATION_SETTINGS_OPT_ONE: "Rhestr o e-byst gweithgareddau'r cylch ",
  NOTIFICATION_SETTINGS_OPT_ONE_TEXT:
    "Bydd Jointly yn anfon e-bost atoch chi gyda rhestr o weithgareddau diweddar cylchoedd ",
  NOTIFICATION_SETTINGS_OPT_ONE_SUB_ONE: "Every day",
  NOTIFICATION_SETTINGS_OPT_ONE_SUB_TWO: "Every week",
  NOTIFICATION_SETTINGS_OPT_ONE_SUB_THREE: "Get jointly email in my digest",
  NOTIFICATION_SETTINGS_OPT_ONE_SUB_FOUR: "Include device messages",
  NOTIFICATION_SETTINGS_OPT_ONE_SAVE_BUTTON: "Arbed",
  NOTIFICATION_SETTINGS_OPT_ONE_CANCEL_BUTTON: "Canslo",

  NOTIFICATION_SETTINGS_OPT_TWO: "Hysbysiadau unigol",
  NOTIFICATION_SETTINGS_OPT_TWO_TEXT:
    "Bydd Jointly yn anfon hysbysiad drwy e-bost ac/neu ap atoch chi",
  NOTIFICATION_SETTINGS_OPT_TWO_SELECT_ALL: "Dewiswch bob un",
  NOTIFICATION_SETTINGS_OPT_TWO_SUB_ONE: "A circle profile is edited",
  NOTIFICATION_SETTINGS_OPT_TWO_SUB_TWO: "A new message is posted",
  NOTIFICATION_SETTINGS_OPT_TWO_SUB_THREE: "A task is assigned to you",
  NOTIFICATION_SETTINGS_OPT_TWO_SUB_FOUR: "A task reminder for you is due",
  NOTIFICATION_SETTINGS_OPT_TWO_SUB_FIVE:
    "A task assigned to you is edited or deleted",
  NOTIFICATION_SETTINGS_OPT_TWO_SUB_SIX: "A task is set as unassigned",
  NOTIFICATION_SETTINGS_OPT_TWO_SUB_SEVEN:
    "An unassigned task is edited or deleted",
  NOTIFICATION_SETTINGS_OPT_TWO_SUB_EIGHT: "A note is created",
  NOTIFICATION_SETTINGS_OPT_TWO_SUB_NINE: "A note is edited or deleted",
  NOTIFICATION_SETTINGS_OPT_TWO_SUB_TEN: "A calendar event is created",
  NOTIFICATION_SETTINGS_OPT_TWO_SUB_ELEVEN:
    "A calendar reminder for you is due",
  NOTIFICATION_SETTINGS_OPT_TWO_SUB_TWELVE:
    "A calendar event is edited or deleted",
  NOTIFICATION_SETTINGS_OPT_TWO_SUB_THIRTEEN: "A medication is created",
  NOTIFICATION_SETTINGS_OPT_TWO_SUB_FOURTEEN:
    "A medication is edited or deleted",
  NOTIFICATION_SETTINGS_OPT_TWO_SUB_FIFTEEN: "Someone is invited to a circle",
  NOTIFICATION_SETTINGS_OPT_TWO_SUB_SIXTEEN: "Someone joins a circle",
  NOTIFICATION_SETTINGS_OPT_TWO_SUB_SEVENTEEN: "Someone leaves a circle",
  NOTIFICATION_SETTINGS_OPT_TWO_SUB_EIGHTEEN:
    "Get Jointly email in in notifications",
  NOTIFICATION_SETTINGS_OPT_TWO_SUB_NINETEEN: "Device message received",
  NOTIFICATION_SETTINGS_OPT_TWO_SAVE_BUTTON: "Arbed",
  NOTIFICATION_SETTINGS_OPT_TWO_CANCEL_BUTTON: "Canslo",
  LOGOUT: "Logout",
  NOTIFICATIONS_MESSAGE: "Hysbysiadau",
  NOTIFICATIONS_READ_ALL: "Darllen y cyfan",
  NOTIFICATIONS_ACCEPT_BUTTON: "Derbyn",
  NOTIFICATIONS_REJECT_BUTTON: "Gwrthod",

  CREATE_CIRCLE_DEMO_TITLE: "Creu demo gylch",
  CREATE_CIRCLE_FIRST_NAME: "Enw cyntaf",
  CREATE_CIRCLE_LAST_NAME: "Cyfenw",
  CREATE_CIRCLE_NAME_PLACEHOLDER: "y person rydych chi'n gofalu amdano",
  CREATE_CIRCLE_IMAGE: "Golygu'r llun",
  CREATE_CIRCLE_DOB: "Dyddiad Geni",
  CREATE_CIRCLE_NEEDS: "Anghenion gofalu / Cyflwr / Diagnosis",
  CREATE_CIRCLE_NEEDS_PLACEHOLDER: "e.e. Bregus, Cyflwr y galon, Alzheimer's",
  CREATE_CIRCLE_ALLERGIES: "Alergeddau",
  CREATE_CIRCLE_ALLERGIES_PLACEHOLDER: "e.e. Cnau",
  CREATE_CIRCLE_LIKES: "Hoffterau/Anhoffterau",
  CREATE_CIRCLE_LIKES_PLACEHOLDER:
    "e.e. Hoffterau: Te, Cerddoriaeth, Darllen/Anhoffterau: Coffi, Teledu, Arogleuon Cryf",
  CREATE_CIRCLE_NOTES: "Rhagor o wybodaeth / Nodiadau",
  CREATE_CIRCLE_NOTES_PLACEHOLDER:
    "Defnyddiwch y gofod hwn i gofnodi unrhyw wybodaeth ddefnyddiol arall ynghylch y person rydych chi'n gofalu amdano",
  CREATE_CIRCLE_ALLERGIES_HELP:
    "Defnyddiwch y gofod hwn i gofnodi gwybodaeth arall ynghylch bwyd, meddyginiaeth neu alergedd neu anoeddfiad arall.",
  CREATE_CIRCLE_LIKES_HELP:
    "Defnyddiwch y gofod hwn i gofnodi unrhyw hoffterau neu anhoffterau sy'n gallu eich helpu chi i ofalu'n well.",
  CREATE_CIRCLE_NOTES_HELP:
    "Defnyddiwch y gofod hwn i gofnodi unrhyw wybodaeth ddefnyddiol arall ynghylch y person rydych chi'n gofalu amdano.",
  CREATE_CIRCLE_COMMON_MESSAGE:
    "Dim ond chi ac aelodau eraill o'ch cylch fydd yn gallu cael mynediad at yr wybodaeth ar y dudalen hon neu ar unrhyw dudalen Jointly arall. Dim ond gweinyddwyr cylch sydd yn gallu golygu neu wahodd pobl eraill i ymuno â'r cylch.",
  CREATE_CIRCLE_FIRST_NAME_BLANK_MESSAGE: "Enw cyntaf!",
  CREATE_CIRCLE_LAST_NAME_BLANK_MESSAGE: "Cyfenw!",
  CREATE_CIRCLE_NEEDS_BLANK_MESSAGE:
    "Rhowch wybodaeth ynghylch anghenion gofalu",

  PURCHASE_CIRCLE_TITLE: "Prynu cylch",
  PURCHASE_PAYMENT_TITLE: "Talu",
  PURCHASE_CARD_NUMBER_PLACEHOLDER: "Rhif cerdyn",
  PURCHASE_TEXT_ONE:
    "Stripe sydd yn delio â’ch taliad yn defnyddio'r gofynion PCI mwyaf llym",
  PURCHASE_TEXT_TWO:
    "Nid yw Stripe na Carers UK yn cadw manylion eich cardiau debyd neu gredyd.",
  PURCHASE_TEXT_THREE:
    "Mae eich data'n cael ei drosglwyddo drwy gyswllt wedi'i encryptio i weinydd diogel",
  PURCHASE_PROCEED_BUTTON: "Ewch ymlaen i dalu £#FIELD#",
  PURCHASE_CANCEL_BUTTON: "Canslo",
  PURCHASE_FREE_ACCESSCODE_LINK: "Oes gennych chi god ",
  PURCHASE_FREE_ACCESSCODE_LINK_TWO: "mynediad am ddim?",
  PURCHASE_FREE_ACCESS_CODE_TEXT: "Os oes, gallwch chi gael cylch am ddim.",

  ACCESS_CODE_TITLE: "Cod Mynediad am Ddim",
  ACCESS_CODE_EMAIL: "Eich e-bost",
  ACCESS_CODE_CODE: "Eich Cod Mynediad am Ddim",
  ACCESS_CODE_CODE_BLANK_ERROR: "Rhowch eich cod mynediad!",
  ACCESS_CODE_SUCCESS:
    "Cylch wedi'i uwchraddio yn defnyddio cod mynediad am ddim",

  MY_CIRCLE: "Fy nghylchoedd",
  CIRCLE_MESSAGE_TITLE: "Anfon neges",
  CIRCLE_MESSAGE_PLACEHOLDER: "Ysgrifennu eich meddyliau, postio diweddariadau",
  CIRCLE_MESSAGE_IMAGE: "Atodi lluniau",
  CIRCLE_MESSAGE_FILE: "Atodi ffeil",
  CIRCLE_MESSAGE_POST_BUTTON: "Postio",
  CIRCLE_MESSAGE_NO_MESSAGE_WARNING:
    "Dim neges wedi'i darganfod! Ychwanegwch eich neges gyntaf.",
  CIRCLE_OR: "NEU",
  CIRCLE_MEMBER_NOT_AVAILABLE: "Aelod (au) ddim ar gael yn y dyddiad hwn",

  MEMBERS_TABNAME: "Aelodau",
  PENDING_TABNAME: "Gwahoddiadau yn aros",
  PENDING_NO_PENDING_MESSAGE: "Dim gwahoddiadau yn aros",

  INVITE_MEMBERS_ICON_TEXT: "Gwahodd aelodau",
  INVITE_MEMBERS_TITLE: "Gwahodd pobl i ymuno â'ch cylch Jointly",
  INVITE_EMAIL: "E-bost",
  INVITE_NAME: "Enw llawn",
  SET_ADMIN: "Gweinyddydd cylch",
  SET_EDIT_MEDICATION: "Yn gallu golygu meddyginiaethau",
  SET_VIEW_NOTES: "Yn gallu gweld nodiadau categori preifat",
  INVITE_ADD_ANOTHER_BUTTON: "Ychwanegu un arall",
  INVITE_MANY_BUTTON: "Ychwanegu llawer",
  INVITE_EMAIL_BLANK: "E-bost!",
  INVITE_EMAIL_INVALID: "Cyfeiriad e-bost dilys!",
  INVITE_TEXT_ONE:
    "Drwy glicio ' Gwahodd ', rydych yn rhoi mynediad i'r person hwn at yr wybodaeth a gedwir yn y cylch Jointly hwn. I gael rhagor o wybodaeth, gweler ",
  INVITE_TEXT_TWO: "Gwahodd pobl i ymuno â’ch cylch",
  INVITE_TEXT_THREE: "yn y canllaw defnyddwyr.",
  INVITE_TEXT_FOUR:
    "Gall gweinyddwyr cylch newid gosodiadau ar gyfer unrhyw aelod o'r cylch, gan gynnwys cylchoedd eu hunain, a gwahodd aelodau newydd i ymuno â'r cylch.",
  INVITE_BUTTON: "Gwahodd",
  INVITE_CANCEL: "Canslo",
  INVITE_INVITED_BY: "Gwahoddwyd gan",
  INVITE_RESEND_OPTION_ONE: "Ailanfon gwahoddiad?",
  INVITE_RESEND_BUTTON: "Ailanfon",
  INVITE_CANCEL_BUTTON: "Canslo",
  INVITE_DELETE_INVITATION: "Dileu gwahoddiad?",
  INVITE_DELETE_BUTTON_CONFIRM: "Dileu",
  INVITE_VIEW_MORE: "Gweld mwy",
  INVITE_MULTIPLE: "Rhowch awl cyfeiriad e-bost",
  INVITE_NOTE:
    "Noder: Copïwch a pastiwch restr o e-byst o'ch cysylltiadau. Cofiwch wahanu'r cyfeiriadau e-byst gydag atalnodau. Wedi ei wneud.",
  DONE: "Done",

  CALENDAR_TITLE: "Digwyddiadau",
  CALENDAR_CREATE_EVENT_BUTTON: "Creu digwyddiad newydd",
  CALENDAR_EVENT_DETAILS_TITLE: "MANYLION Y DIGWYDDIAD",
  CALENDAR_EVENT_TITLE: "Teitl y digwyddiad",
  CALENDAR_EVENT_TITLE_PLACEHOLDER: "e.e. Apwyntiadau Meddyg",
  CALENDAR_EVENT_TITLE_BLANK_ERROR: "Teitl y digwyddiad",
  CALENDAR_EVENT_LOCATION: "Lleoliad",
  CALENDAR_EVENT_LOCATION_PLACEHOLDER: "Ble?",
  CALENDAR_EVENT_WHO: "Pwy?",
  CALENDAR_EVENT_WHO_PLACEHOLDER: "Dewiswch aelod neu teipiwch e-bost yr aelod",
  CALENDAR_EVENT_DESCRIPTION: "Disgrifiad",
  CALENDAR_EVENT_IMAGE_BUTTON: "Llwythwch lun i fyny",
  CALENDAR_EVENT_DATE_TITLE: "DYDDIAD Y DIGWYDDIAD",
  CALENDAR_EVENT_START: "Cychwyn",
  CALENDAR_EVENT_START_TIME: "Amser cychwyn",
  CALENDAR_EVENT_ALL_DAY_CHECK_BOX: "Drwy'r dydd",
  CALENDAR_EVENT_END: "Gorffen",
  CALENDAR_EVENT_END_TIME: "Amser gorffen",
  CALENDAR_EVENT_END_DATE_BLANK_ERROR: "Dyddiad gorffen",
  CALENDAR_EVENT_DAY_BLANK_ERROR: "Dewiswch ddiwrnod os gwelwch yn dda",
  CALENDAR_EVENT_WEEKDAY_BLANK_ERROR: "Dewiswch ddiwrnod o'r wythnos",
  CALENDAR_EVENT_REPEAT_UNTIL: "Ailadroddwch nes",
  CALENDAR_EVENT_RECURRING: "Yn digwydd eto ar",
  CALENDAR_EVENT_RECURRING_ONE: "Dim yn digwydd eto",
  CALENDAR_EVENT_RECURRING_TWO: "Bob dydd",
  CALENDAR_EVENT_RECURRING_THREE: "Bob wythnos",
  CALENDAR_EVENT_RECURRING_FOUR: "Bob mis",
  CALENDAR_EVENT_RECURRING_FIVE: "Arall",
  CALENDAR_EVENT_REPEAT_TYPE: "Dewiswch fath",
  CALENDAR_EVENT_REPEAT_TYPE_ONE: "Ailadrodd erbyn diwrnod o'r wythnos",
  CALENDAR_EVENT_REPEAT_TYPE_TWO: "Dewiswch x diwrnod",
  CALENDAR_EVENT_REPEAT_TYPE_THREE: "Dewiswch ddiwrnod o fis",
  CALENDAR_EVENT_REMINDER: "Hysbysiadau atgoffa",
  CALENDAR_EVENT_REMINDER_ONE: "15 munud cyn y digwyddiad",
  CALENDAR_EVENT_REMINDER_TWO: "1 awr cyn y digwyddiad",
  CALENDAR_EVENT_REMINDER_THREE: "6 awr cyn y digwyddiad",
  CALENDAR_EVENT_REMINDER_FOUR: "1 diwrnod cyn y digwyddiad",
  CALENDAR_EVENT_REMINDER_FIVE: "1 wythnos cyn y digwyddiad",
  CALENDAR_EVENT_SAVE_BUTTON: "Arbed",
  CALENDAR_EVENT_CANCEL_BUTTON: "Canslo",
  EVENT_TODAY: "Heddiw",
  EVENT_MONTH: "Mis",
  EVENT_WEEK: "Wythnos",
  EVENT_DAY: "Diwrnod",

  EVENT_DETAIL_TITLE: "Digwyddiadau",
  EVENT_DETAIL_BACK_BUTTON: "Nôl",
  EVENT_DETAIL_EDIT_BUTTON: "Golygu",
  EVENT_DETAIL_COPY_BUTTON: "Copïo",
  EVENT_DETAIL_DELETE_BUTTON: "Dileu",
  EVENT_DETAIL_DELETE_ALL_BUTTON: "Dileu pob un",
  EVENT_DETAIL_WHO: "Pwy?",
  EVENT_DETAILS_COMMENTS: "Sylwadau",
  EVENT_DETAILS_COMMENTS_PLACEHOLDER: "Sylwad",
  EVENT_DETAILS_COMMENTS_TEXT: "Sylwad Cyntaf",
  EVENT_EDIT_TITLE: "Golygu digwyddiad",
  EVENT_COPY_TITLE: "(drafted)",
  EVENT_COPY_RESET: "Ailosod",

  EVENT_DELETE_CONFIRM_MESSAGE:
    "Ydych chi'n siŵr eich bod chi eisiau dileu'r digwyddiad?",
  EVENT_DELETE_CONFIRM_YES: "Ydw",
  EVENT_DELETE_CONFIRM_NO: "Nac ydw",

  UNAVAILABILITY_BUTTON: "Dim ar gael",
  UNAVAILABILITY_TITLE_BUTTON: "Dyddiadau sydd ddim ar gael",
  UNAVAILABILITY_NODATE_MESSAGE:
    "Heb ddod o hyd i unrhyw ddyddiadau sydd ddim ar gael",
  UNAVAILABILITY_ADD_DATE_BUTTON: "Ychwanegu dyddiadau sydd ddim ar gael",
  UNAVAILABILITY_TITLE: "Dyddiadau sydd ddim ar gael",
  UNAVAILABILITY_START_DATE: "Dyddiad cychwyn",
  UNAVAILABILITY_END_DATE: "Dyddiad gorffen",
  UNAVAILABILITY_SELECT_MEMBERS: "Dewis aelodau",
  UNAVAILABILITY_SELECT_MEMBERS_PLACEHOLDER:
    "Chwilio am gyfeiriad e-byst aelodau",
  UNAVAILABILITY_REASON: "Rheswm",
  UNAVAILABILITY_START_DATE_BLANK_ERROR: "Dyddiad cychwyn",
  UNAVAILABILITY_END_DATE_BLANK_ERROR: "Dyddiad gorffen",
  UNAVAILABILITY_MEMBERS_BLANK_ERROR: "Dewis aelodau!",
  UNAVAILABILITY_REASON_BLANK_ERROR: "Rheswm!",
  UNAVAILABILITY_EDIT: "Golygu",
  UNAVAILABILITY_EDIT_TITLE: "Golygu dyddiadau sydd ddim ar gael",
  UNAVAILABILITY_DELETE_CONFIRM: "Ydych chi'n siŵr?",
  UNAVAILABILITY_DELETE_CONFIRM_YES: "Ydw",
  UNAVAILABILITY_DELETE_CONFIRM_NO: "Nac ydw",

  NOTES_TITLE: "Nodiadau",
  NOTES_NOTFOUND_MESSAGE_ONE: "Dim nodiadau wedi'u darganfod",
  NOTES_NOTFOUND_MESSAGE_TWO: "Ychwanegwch eich nodyn cyntaf",
  NOTE_CREATE_BUTTON: "Creu nodyn newydd",
  NOTE_LIST_FILTER_BY_CATEGORY: "Hidlo fesul categori",
  NOTE_LIST_CLEAR_FILTER: "Clirio'r hidlo",

  NOTE_CREATE_TITLE: "Creu nodyn newydd",
  NOTE_CREATE_NAME: "Teitl y nodyn",
  NOTE_CREATE_NAME_PLACEHOLDER: "Am beth mae eich nodyn?",
  NOTE_CREATE_NAME_BLANK_ERROR: "Teitl y nodyn!",
  NOTE_CREATE_NOTES: "Nodiadau",
  NOTE_CREATE_NOTE_BLANK_ERROR: "Disgrifiad o'r nodyn!",
  NOTE_CREATE_CATEGORY: "Categori",
  NOTE_CREATE_CATEGORY_NOTFOUND: "Dim categorïau wedi'u darganfod!",
  NOTE_CREATE_ADD_CATEGORY_BUTTON: "Ychwanegu categori newydd",
  NOTE_CREATE_IMAGE_BUTTON: "Llwytho llun i fyny",
  NOTE_CREATE_SAVE_BUTTON: "Arbed",
  NOTE_CREATE_CANCEL_BUTTON: "Canslo",

  NOTE_CATEGORY_MANAGE_BUTTON: "Categorïau",
  NOTE_CATEGORY_MANAGE_TITLE: "Categorïau",
  NOTE_CATEGORY_MANAGE_NO_CATEGORY_MESSAGE:
    "Dim categorïau wedi'u darganfod! Ychwanegwch eich categori cyntaf",
  NOTE_CATEGORY_MANAGE_CREATE_BUTTON: "CREU CATEGORI",
  NOTE_CATEGORY_MANAGE_CREATE_CATEGORY_BACK_BUTTON: "Nôl",
  NOTE_CATEGORY_MANAGE_CATEGORY_ADD_TITLE: "Ychwanegu categori newydd",
  NOTE_CATEGORY_MANAGE_CATEGORY_NAME: "Enw'r categori",
  NOTE_CATEGORY_MANAGE_CATEGORY_NAME_DUPLICATE_ERROR:
    "Mae categori gyda'r enw 'enw'r categori' yn barod, aseiniwch yr eitem hwn i'r categori hwnnw neu dewiswch enw gwahanol",
  NOTE_CATEGORY_MANAGE_CATEGORY_DESCRIPTION: "Disgrifiad",
  EVENT_CATEGORY_COLOR_LABEL: "Dewiswch liw categori",
  NOTE_CATEGORY_MANAGE_CATEGORY_PRIVATE_CHECKBOX: "Ydy hwn yn breifat",
  NOTE_CATEGORY_MANAGE_CATEGORY_NAME_BLANK_ERROR: "Enw'r categori",
  NOTE_CATEGORY_MANAGE_CATEGORY_SAVE_BUTTON: "Arbed",
  NOTE_CATEGORY_MANAGE_CATEGORY_CANCEL_BUTTON: "Canslo",
  NOTE_CATEGORY_LIST_NAME: "Enw",
  NOTE_CATEGORY_LIST_DESCRIPTION: "Disgrifiad",
  NOTE_CATEGORY_LIST_PRIVATE: "Preifat",
  NOTE_CATEGORY_LIST_ACTION: "Cam",
  NOTE_CATEGORY_LIST_EDIT_ICON: "Golygu",
  NOTE_CATEGORY_LIST_DELETE_ICON: "Dileu",
  CATEGORY_CREATE_SAVE_BUTTON: "Arbed",

  TASK_TITLE: "Tasgau",
  TASK_ACTIVE_TASKS: "Tasgau gweithredol",
  TASK_MY_TASKS: "Fy nhasgau",
  TASK_UNASSIGNED: "Heb eu neilltuo",
  TASK_ASSIGNED_TO_OTHERS: "Wedi’u neilltuo i eraill",
  TASK_TASK_LISTS: "Rhestr dasgau",
  TASK_COMPLETED: "Wedi'u cwblhau",
  TASK_NO_TASK_MESSAGE_ONE: "Dim tasg ",
  TASK_NO_TASK_MESSAGE_TWO: " wedi'i darganfod!",
  TASK_NO_TASK_MESSAGE_FOUR: "list",
  TASK_NO_TASK_MESSAGE_THREE: "Ychwanegwch eich tasg gyntaf",
  TASK_EDIT_TASK_BUTTON: "Golygu tasg",
  TASK_CREATE_TASK_BUTTON: "Creu tasg newydd",
  TASK_CREATE_TITLE: "Teitl tasg",
  TASK_CREATE_TITLE_PLACEHOLDER: "e.e. Nôl presgripsiwn",
  TASK_CREATE_RESPONSIBLE_MEMBER: "Pwy sy'n gyfrifol?",
  TASK_CREATE_RESPONSIBLE_MEMBER_PLACEHOLDER: "Dewis aelod",
  TASK_CREATE_ENDDATE: "Pryd mae hyn yn ddyledus?",
  TASK_CREATE_ENDDATE_PLACEHOLDER: "Dewis dyddiad",
  TASK_CREATE_LIST: "Ychwanegu hwn at restr?",
  TASK_CREATE_LIST_PLACEHOLDER: "Dewis rhestr",
  TASK_CREATE_NEW_TASK_LIST: "neu creu rhestr dasgau newydd",
  TASK_CREATE_REMINDER_TEXT: "A ddylai Jointly eich atgoffa chi o hyn?",
  TASK_CREATE_REMINDER_ONE: "Y dyddiad mae hyn yn ddyledus",
  TASK_CREATE_REMINDER_TWO: "1 diwrnod cyn y dasg",
  TASK_CREATE_REMINDER_THREE: "2 ddiwrnod cyn y dasg",
  TASK_CREATE_REMINDER_FOUR: "3 diwrnod cyn y dasg",
  TASK_CREATE_REMINDER_FIVE: "1 wythnos cyn y dasg",
  TASK_CREATE_REMINDER_SIX: "2 wythnos cyn y dasg",
  TASK_CREATE_TASK_NOTES: "Nodiadau'r dasg",
  TASK_CREATE_SAVE_BUTTON: "Arbed",
  TASK_CREATE_CANCEL_BUTTON: "Canslo",
  TASK_CREATE_TITLE_BLANK_ERROR: "Teitl y dasg!",
  TASK_LIST_COMPLETE: "Cwblhau'r dasg hon",
  TASK_EDIT: "Golygu",
  TASK_DELETE: "Dileu",
  TASK_DELETE_CONFIRMATION:
    "Ydych chi'n siŵr eich bod chi eisiau dileu'r dasg hon?",
  TASK_DELETE_CONFIRMATION_YES: "Ydw",
  TASK_DELETE_CONFIRMATION_CANCEL: "Canslo",
  TASK_SETTINGS_ICON: "Gosodiadau tasg",
  TASK_SETTINGS_CHECKBOX_TEXT:
    "Dangos tasgau heb eu haseinio ar y sgrîn Trosolwg",
  TASK_SETTINGS_CANCEL_BUTTON: "Canslo",
  TASK_SETTINGS_OK: "OK",
  TASK_NEW_LIST_TITLE: "Ychwanegu tasg newydd",
  TASK_NEW_LIST_NAME: "Enw'r rhestr",
  TASK_NEW_LIST_NAME_BLANK_ERROR: "Rhowch enw'r rhestr",
  TASK_NEW_LIST_SAVE: "Arbed",
  TASK_NEW_LIST_CANCEL: "Canslo",

  REMINDER_TABLE_NAME: "ENW", 
  REMINDER_TABLE_START: "DECHRAU YMLAEN",
  REMINDER_TABLE_DESCRIPTION: "DISGRIFIAD",
  REMINDER_TABLE_RESPONSIBLE: "CYFRIFOL",
  REMINDER_TABLE_ACTIONS: "GWEITHREDOEDD",
  REMINDER_TITLE: "rhestr atgoffa",
  REMINDER_CREATE_BUTTON: "ychwanegu nodyn atgoffa",
  REMINDER_ADD_ITEM_BUTTON: "ychwanegu eitem",
  REMINDER_CREATE_TITLE: "Teitl",
  REMINDER_CREATE_TITLE_BLANK_ERROR: "Rhowch deitl nodyn atgoffa",
  REMINDER_CREATE_TITLE_NAME: "e.e. Siopa groser",
  REMINDER_CREATE_ITEM_TITLE_NAME: "e.e. Prynwch lysiau",
  REMINDER_CREATE_RESPONSIBLE_MEMBER: "Pwy sy'n gyfrifol?",
  REMINDER_CREATE_DESCRIPTION: "Disgrifiad",
  REMINDER_PAST_TIME_VALIDATION_ERROR: "Rhaid i'r amser fod yn gyfartal neu'n fwy nag yn awr",
  REMINDER_ITEM_CREATE_CANCEL_BUTTON: "Canslo",
  REMINDER_ITEM_CREATE_TITLE: "Teitl",
  REMINDER_ITEM_REPEAT_LABEL: "Ailadrodd",
  REMINDER_ITEM_NOTIFICATION_LABEL: "Rhybudd",
  REMINDER_ITEM_REPEAT_EXPIRED: "Wedi dod i ben",
  REMINDER_ITEM_TITLE_BLANK_ERROR: "Rhowch deitl eitem atgoffa",
  REMINDER_ITEM_CREATE_DESCRIPTION: "Disgrifiad",
  REMINDER_ITEM_SORT_ONE: "Pob Eitem",
  REMINDER_ITEM_SORT_TWO: "Cwblhawyd",
  REMINDER_ITEM_SORT_THREE: "Parhaus",
  REMINDER_ITEM_STARTS_ON: "Yn Dechrau Ar",
  REMINDER_ITEM_REPEAT_ON: "Ailadrodd Ymlaen",
  REMINDER_EDIT_ICON: "Golygu",
  OTHERS_SURE_DELETE_REMINDER:
    "Ydych chi'n siŵr eich bod am ddileu'r nodyn atgoffa hwn?",
  REMINDER_DELETE_CONFIRM_YES: "Oes",
  REMINDER_DELETE_CONFIRM_NO: "Nac ydw",
  REMINDER_DELETE_ICON: "Dileu",
  REMINDER_TAB_ALL: "I gyd",
  REMINDER_TAB_HISTORY: "Hanes",
  NO_HISTORY_FOUND: "Nid oes hanes ar hyn o bryd",
  UNKNOWN_REMINDER: "Nodyn atgoffa anhysbys",
  TOASTERS_REMINDER_CREATED: "Nodyn atgoffa wedi'i greu",
  TOASTERS_REMINDER_UPDATED: "Nodyn atgoffa wedi'i ddiweddaru",
  TOASTERS_REMINDER_DELETED: "Nodyn atgoffa wedi'i ddileu",
  TOASTERS_REMINDER_ITEM_CREATED: "Nodyn atgoffa wedi'i greu",
  TOASTERS_REMINDER_ITEM_UPDATED: "Nodyn atgoffa wedi'i ddiweddaru",
  TOASTERS_REMINDER_ITEM_DELETED: "Nodyn atgoffa wedi'i ddileu",
  TOASTERS_REMINDER_ITEM_COMPLETED:
    "Eitem atgoffa wedi'i marcio fel wedi'i chwblhau",
  TOASTERS_REMINDER_ITEM_OPENED:
    "Eitem atgoffa wedi'i marcio fel wedi'i hailagor",
  TOASTERS_NO_REMINDER_ERROR:
    "Methu nôl y nodyn atgoffa. Efallai ei fod wedi'i ddileu neu fod y llwybr wedi'i nodi'n anghywir",
  TOASTERS_REMINDER_EXPIRED:
    "Ni allwch olygu nodyn atgoffa cylchol sydd wedi dod i ben",
  REMINDER_CREATE_RESPONSIBLE_MEMBER_PLACEHOLDER: "Dewiswch aelod",
  REMINDER_CREATE_SAVE_BUTTON: "Arbed",
  REMINDER_CREATE_CANCEL_BUTTON: "Canslo",
  REMINDER_POPUP_CREATE_TITLE: "Creu nodyn atgoffa",
  REMINDER_POPUP_EDIT_TITLE: "Golygu nodyn atgoffa",
  REMINDER_POPUP_ITEM_SAVE_TITLE: "Ychwanegu eitem atgoffa",
  REMINDER_POPUP_ITEM_EDIT_TITLE: "Golygu eitem atgoffa",
  REMINDER_NO_PERMISSION: "Nid oes gennych ganiatâd i wneud hyn",
  REMINDER_CREATE_DUE: "Ar y cyd eich atgoffa pryd mae'n ddyledus?",
  REMINDER_CREATE_OVERDUE: "Ar y cyd atgoffa a yw eitemau'n hwyr?",
  REMINDER_CREATE_DUE_PLACEHOLDER: "Dewiswch egwyl atgoffa",
  REMINDER_CREATE_OVERDUE_PLACEHOLDER: "Dewiswch egwyl atgoffa",
  CREATE_REMINDER_OVERDUE_ALLERGIES_HELP: "Ar y cyd yn eich atgoffa os nad yw eitemau wedi'u cwblhau mewn pryd",
  REMINDER_DUE_DOES_NOT_REMIND: "Nid yw'n atgoffa",
  REMINDER_OVERDUE_DOES_NOT_REMIND: "Nid yw'n atgoffa",
  CONTACTS_TITLE: "Cysylltiadau",
  CONTACTS_NO_CONTACTS_MESSAGE:
    "Dim cysylltiadau wedi'u darganfod! Ychwanegwch eich cyswllt cyntaf",
  CONTACTS_ADD_NEW_CONTACT_BUTTON: "Ychwanegu cyswllt newydd",
  CONTACTS_MEMBERS_TITLE: "Aelodau Cylch",
  CONTACTS_NAME: "Enw",
  CONTACTS_EMAIL: "E-bost",
  CONTACTS_PHONE: "Rhif ffôn",
  CONTACTS_SORT: "Didoli",
  CONTACTS_ADD_TITLE: "Ychwanegu cyswllt",
  CONTACTS_ADD_NAME: "Enw llawn",
  CONTACTS_ADD_EMAIL: "E-bost",
  CONTACTS_ADD_IMAGE: "Golygu'r llun",
  CONTACTS_ADD_ADDRESS: "Cyfeiriad",
  CONTACTS_ADD_CITY: "Dinas",
  CONTACTS_ADD_POSTCODE: "Cod post",
  CONTACTS_ADD_PHONE: "Rhif ffôn cyswllt",
  CONTACTS_ADD_ALT_PHONE: "Rhif ffôn arall",
  CONTACTS_ADD_ALT_PHONE2: "Rhif ffôn arall",
  CONTACTS_ADD_RELATIONSHIP2: "Perthynas",
  CONTACTS_ADD_RELATIONSHIP: "Perthynas",
  CONTACTS_ADD_ACTIONS: "Camau",
  CONTACTS_ADD_NOTES: "Nodiadau",
  CONTACTS_ADD_NAME_BLANK_ERROR: "Enw llawn!",
  CONTACTS_ADD_EMAIL_INVALID_ERROR: "Cyfeiriad e-bost dilys!",
  CONTACTS_ADD_SAVE_BUTTON: "Arbed",
  CONTACTS_ADD_CANCEL_BUTTON: "Canslo",
  CONTACTS_ADD_BACK_BUTTON: "Nôl",
  CONTACT_EDIT_ICON: "Golygu",
  CONTACT_EDIT_TITLE: "Golygu cyswllt",
  CONTACT_DELETE_ICON: "Dileu",
  CONTACT_DELETE_CONFIRMATION: "Dileu'r cyswllt hwn?",
  CONTACT_DELETE_CONFIRMATION_YES: "Ydw",
  CONTACT_DELETE_CONFIRMATION_CANCEL: "Canslo",
  CONTACT_UPDATED: "Cyswllt wedi'i ddiweddaru",
  CONTACT_DELETED: "Cyswllt wedi'i ddileu",

  MEDICATIONS_TITLE: "Meddyginiaethau",
  MEDICATIONS_NO_MEDICATION_ONE: "Dim meddyginiaethau wedi'u darganfod! ",
  MEDICATIONS_NO_MEDICATION_TWO: "Ychwanegwch eich meddyginiaeth gyntaf",
  MEDICATION_ADD_BUTTON: "Ychwanegu meddyginiaeth newydd",
  MEDICATIONS_TODAY: "Meddyginiaethau heddiw",
  MEDICATIONS_ALL: "Pob meddyginiaeth",
  MEDICATIONS_PURPOSE: "Pwrpas",
  MEDICATIONS_DOSE: "Dos",
  MEDICATIONS_EDIT: "Golygu",
  MEDICATIONS_DELETE: "Dileu",
  MEDICATIONS_ALL_CURRENT_TITLE: "Presennol",
  MEDICATIONS_ALL_CURRENT_STARTS: "Cychwyn",
  MEDICATIONS_ALL_CURRENT_ENDS: "Gorffen",
  MEDICATIONS_ALL_PAST_TITLE: "Hen",
  MEDICATION_ADD_TITLE: "Creu meddyginiaeth",
  MEDICATION_ADD_NAME: "Enw'r feddyginiaeth",
  MEDICATION_ADD_NAME_PLACEHOLDER: "e.e.Aspirin",
  MEDICATION_ADD_PURPOSE: "Pwrpas",
  MEDICATION_ADD_PURPOSE_PLACEHOLDER: "e.e. Cyflyrau'r galon, asthma ac ati",
  MEDICATION_ADD_IMAGE: "Llwytho llun i fyny",
  MEDICATION_ADD_FREQUENCY: "Pa mor aml?",
  MEDICATION_REMINDER_ENABLE: "Hysbysiad atgoffa",
  MEDICATION_ADD_FREQUENCY_PLACEHOLDER:
    "Pa mor aml y dylid cymryd y feddyginiaeth?",
  MEDICATION_ADD_FREQUENCY_ONE: "Yn ôl yr angen",
  MEDICATION_ADD_FREQUENCY_TWO: "Bob diwrnod",
  MEDICATION_ADD_FREQUENCY_THREE: "Bob dau ddiwrnod",
  MEDICATION_ADD_FREQUENCY_FOUR: "Bob tri diwrnod",
  MEDICATION_ADD_FREQUENCY_FIVE: "Bob pedwar diwrnod",
  MEDICATION_ADD_FREQUENCY_SIX: "Bob pump diwrnod",
  MEDICATION_ADD_FREQUENCY_SEVEN: "Bob chwech diwrnod",
  MEDICATION_ADD_FREQUENCY_EIGHT: "Bob wythnos",
  MEDICATION_ADD_FREQUENCY_NINE: "Bob pythefnos",
  MEDICATION_ADD_FREQUENCY_TEN: "Bob mis",
  MEDICATION_ADD_CURRENT_CHECKBOX:
    "Ydy'r feddyginiaeth hon yn cael ei chymryd ar hyn o bryd?",
  MEDICATION_DOSE_NOTIFICATION_CHECKBOX: "Galluogi hysbysiad ar gyfer dosau sydd i ddod",
  MEDICATION_ADD_DOSE: "Dos",
  MEDICATION_ADD_DOSE_PLACEHOLDER: "e.e.1 tabled neu 10 drop",
  MEDICATION_ADD_TIME: "Amser",
  MEDICATION_ADD_ADD_MORE: "Ychwanegu mwy",
  MEDICATION_ADD_START_DATE: "Dyddiad cychwyn",
  MEDICATION_ADD_END_DATE:
    "Dyddiad gorffen (gadewch yn wag os nad oes dyddiad gorffen)",
  MEDICATION_ADD_ADD_NOTES:
    "Ychwanegu nodiadau am y feddyginiaeth (cymryd cyn prydau, drwy'r geg. ac ati.)",
  MEDICATION_ADD_ADD_NOTES_PLACEHOLDER: "Ychwanegu nodiadau am y feddyginiaeth",
  MEDICATION_ADD_NAME_BLANK_ERROR: "Teitl!",
  MEDICATION_ADD_START_DATE_BLANK_ERROR: "Dyddiad cychwyn",
  MEDICATION_ADD_SAVE: "Arbed",
  MEDICATION_ADD_CANCEL: "Canslo",
  MEDICATION_EDIT: "Golygu meddyginiaeth",
  MEDICATION_DELETE_CONFIRMATION:
    "Ydych chi'n siŵr eich bod chi eisiau dileu'r feddyginiaeth hon?",
  MEDICATION_DELETE_CONFIRMATION_YES: "Ydw",
  MEDICATION_DELETE_CONFIRMATION_NO: "Nac ydw",
  MEDICATION_UPDATED: "Meddyginiaeth wedi'i diweddaru",
  MEDICATION_DELETED: "Meddyginiaeth wedi'i dileu",

  PROMOCODE_TITLE: "Promo Codes",
  PROMOCODE_CREATE_BUTTON: "Create promo-code",
  PROMOCODE_NAMES: "PROMO CODES",
  PROMOCODE_NO_OF_USERS: "No. of users",
  PROMOCODE_USED: "USED",
  PROMOCODE_DESCRIPTION: "DESCRIPTION",
  PROMOCODE_CREATED_DATE: "CREATED DATE",
  PROMOCODE_LAST_USED: "LAST USED",
  PROMOCODE_EXPIRES_ON: "EXPIRES ON",
  PROMOCODE_EDIT: "Golygu",
  PROMOCODE_DELETE: "Dileu",
  PROMOCODE_CREATE_TITLE: "Create Promo Code",
  PROMOCODE_CREATE_NAME: "Promo code",
  PROMOCODE_CREATE_NAME_PLACEHOLDER: "Free Access Code",
  PROMOCODE_CREATE_NO_OF_USERS: "No. of users",
  PROMOCODE_CREATE_DESCRIPTION: "Disgrifiad",
  PROMOCODE_CREATE_EXPIRY_DATE: "Expires on",
  PROMOCODE_CREATE_NAME_BLANK_ERROR: "Please enter a Promo code!",
  PROMOCODE_CREATE_SAVE_BUTTON: "Arbed",
  PROMOCODE_CREATE_CANCEL_BUTTON: "Canslo",
  PROMOCODE_EDIT_TITLE: "Edit Promo Code",
  PROMOCODE_DELETE_CONFIRMATION: "Are you sure to delete this promocode?",
  PROMOCODE_DELETE_CONFIRMATION_YES: "Ia",
  PROMOCODE_DELETE_CONFIRMATION_CANCEL: "Canslo",
  PROMOCODE_MAX_USER_LIMIT_ERROR:
    "Rhowch y terfyn sy'n hafal neu'n fwy na'r defnydd, a ddefnyddir ar hyn o bryd yn",

  RECOVER_ACCOUNT_TITLE: "Recover Account",
  RECOVER_ACCOUNT_EMAIL: "E-bost",
  RECOVER_ACCOUNT_EMAIL_PLACEHOLDER: "Select a user",
  RECOVER_ACCOUNT_BUTTON: "Recover Account",
  RECOVER_ACCOUNT_OPTIONS_TITLE: "Math o adferiad",
  RECOVER_ACCOUNT_OPTIONS_PLACEHOLDER: "Dewiswch fath o adferiad",
  RECOVER_ACCOUNT_COPY: "Copied to clipboard",
  RECOVER_ACCOUNT_OPTION_ONE: "Cyswllt Hud",
  RECOVER_ACCOUNT_OPTION_TWO: "Dolen Ailosod Cyfrinair",

  ANALYTICS_TITLE: "Dadansoddwyr Defnyddwyr",

  ADMIN_HEADER_SEARCH_PLACEHOLDER:
    "Chwilio Meddyginiaethau, Nodiadau, ac ati ...",
  ADMIN_HEADER_SEARCH_CATEGORY_PLACEHOLDER: "Pob adran",
  ADMIN_HEADER_SEARCH_CIRCLE_PLACEHOLDER: "Pob cylch",
  ADMIN_MENU_HOME: "Home",
  ADMIN_MENU_SETTINGS: "Gosodiadau",
  ADMIN_MENU_SETTINGS_ONE: "Site Settings",
  ADMIN_MENU_SETTINGS_TWO: "Services",
  ADMIN_MENU_SETTINGS_THREE: "Promo Code",
  ADMIN_MENU_SETTINGS_FOUR: "Recover Account",
  ADMIN_MENU_SETTINGS_FIVE: "Dadansoddwyr Defnyddwyr",
  ADMIN_MENU_SETTINGS_SIX: "Circles connected",
  ADMIN_MENU_SETTINGS_SEVEN: "Newid Cyfeiriad E-bost Defnyddiwr",
  ADMIN_SITE_SETTINGS_TITLE: "Site Settings",
  ADMIN_SITE_SETTINGS_MAX_MEMBERS: "Maximum members per circle",
  ADMIN_SITE_SETTINGS_MAX_MEMBERS_HELP:
    "Maximum allowed users to join to a Circle. Keep empty to allows infinite members",
  ADMIN_SITE_SETTINGS_LIST_ITEMS: "List items per page",
  ADMIN_SITE_SETTINGS_LIST_ITEMS_HELP: "Default list count per page",
  ADMIN_SITE_SETTINGS_DEFAULT_ROLE: "Default role",
  ADMIN_SITE_SETTINGS_DEFAULT_ROLE_HELP: "Default role of a new user",
  ADMIN_SITE_SETTINGS_DEMO_VALIDITY: "Demo circle validity",
  ADMIN_SITE_SETTINGS_DEMO_VALIDITY_HELP:
    "If you change this, demo circle validy will change",
  ADMIN_SITE_SETTINGS_SENDER_NAME: "Sender name",
  ADMIN_SITE_SETTINGS_SENDER_EMAIL: "Sender email",
  ADMIN_SITE_SETTINGS_MAGIC_LINK_EXPIRY:
    "Ailosod cyfrinair yn dod i ben (Oriau)",
  ADMIN_SITE_SETTINGS_MFA_OTP_VALIDITY: "Amser dod i ben 2FA (Cofnodion)",
  MFA_OTP_EXPIRY_TOGGLE_HELP: "OTP yn dod i ben ar gyfer Dilysu Dau-Ffactor",
  ADMIN_SITE_SETTINGS_CAN_REGISTER_TOGGLE: "Can register",
  ADMIN_SITE_SETTINGS_ACCESS_TOKEN_EXPIRY: "Amser dod i ben tocyn mynediad",
  ADMIN_SITE_SETTINGS_CAN_REGISTER_TOGGLE_HELP: "Enable public Signup",
  ENABLE_TWO_FACTOR_AUTHENTICATION: "Galluogi Dilysu Dau Ffactor",
  RESET_PASSWORD_EXPIRY_TOGGLE_HELP:
    "Ailosod awr terfyn cyswllt cyfrinair ar gyfer adfer cyfrif gan weinyddwr.",
  ACCESS_TOKEN_EXPIRY_TOGGLE_HELP:
    "Amser dod i ben ar gyfer mynediad dilysu mewn apiau symudol",
  ADMIN_SITE_SETTINGS_MAX_MEMBERS_BLANK_ERROR:
    "Please input max users allowed !",
  ADMIN_SITE_SETTINGS_LIST_ITEMS_BLANK_ERROR: "Please input list limit !",
  ADMIN_SITE_SETTINGS_DEMO_VALIDITY_BLANK_ERROR:
    "Please enter your demo circle validity!",
  ADMIN_SITE_SETTINGS_SENDER_NAME_BLANK_ERROR: "Please enter your sender name!",
  ADMIN_SITE_SETTINGS_SENDER_EMAIL_BLANK_ERROR:
    "Please enter your sender email!",
  ADMIN_SITE_SETTINGS_MAGIC_LINK_EXPIRY_BLANK_ERROR: "Rhowch awr dod i ben",
  ADMIN_SITE_SETTINGS_2FA_EXPIRY_BLANK_ERROR: "Rhowch amser dod i ben",
  ADMIN_SITE_SETTINGS_SAVE_BUTTON: "Arbed",
  ADMIN_SITE_SETTINGS_CANCEL_BUTTON: "Canslo",
  RESET_PASSWORD_LINK_USED_MESSAGE: "Mae'r ddolen hon eisoes wedi'i defnyddio!",
  RETUNR_TO_LOGIN_PAGE_LINK: "Dychwelyd i'r sgrin mewngofnodi",
  ADMIN_EMAIL_CHANGE_VALIDATION_ERROR: "Ni all yr e-bost newydd fod yr un peth â'r e-bost presennol!",

  SERVICES_TITLE: "Services",
  SERVICES_CREATE_BUTTON: "Create Services",
  SERVICE_TITLE: "TITLE",
  SERVICES_SERVICE_ID: "SERVICE ID",
  SERVICES_SERVICE_KEY: "SERVICE KEY",
  SERVICES_STATUS: "STATUS",
  SERVICES_RESEND_LINK: "RESEND LINK",
  SERVICES_EDIT: "Golygu",
  SERVICES_DELETE: "Dileu",
  SERVICE_CREATE_TITLE: "Create Service Admin",
  SERVICE_CREATE_FIRST_NAME: "First Name",
  SERVICE_CREATE_FIRST_NAME_BLANK_ERROR: "Please input First Name !",
  SERVICE_CREATE_LAST_NAME: "Last Name",
  SERVICE_CREATE_LAST_NAME_BLANK_ERROR: "Please input Last Name!",
  SERVICE_CREATE_EMAIL: "E-bost",
  SERVICE_CREATE_EMAIL_BLANK_ERROR: "Please enter email!",
  SERVICE_CREATE_EMAIL_DUPLICATE_ERROR: "sorry, email has already been used.",
  SERVICE_CREATE_CATEGORY_NAME: "Category Name",
  SERVICE_CREATE_CATEGORY_NAME_BLANK_ERROR: "Please enter Category Name!",
  SERVICE_CREATE_SERVICE_NAME: "Service Name",
  SERVICE_CREATE_SERVICE_NAME_BLANK_ERROR: "Please input Service Name !",
  SERVICE_CREATE_STATUS: "Status",
  SERVICE_CREATE_STATUS_ACTIVE: "Gweithredol",
  SERVICE_CREATE_STATUS_INACTIVE: "Inactive",
  SERVICE_CREATE_INSTRUCTION_TEXT: "Instruction Text",
  SERVICE_CREATE_INSTRUCTION_TEXT_BLANK_ERROR: "Please enter Instruction Text!",
  SERVICE_CREATE_LIVE_CHECKBOX: "Live",
  SERVICE_CREATE_SAVE_BUTTON: "Arbed",
  SERVICE_CREATE__CANCEL_BUTTON: "Canslo",
  SERVICE_EDIT_TITLE: "Edit Service Admin",
  SERVICE_DELETE_CONFIRMATION: "Delete service admin?",
  SERVICE_DELETE_CONFIRMATION_DELETE: "Dileu",
  SERVICE_DELETE_CONFIRMATION_CANCEL: "Canslo",

  SERVICE_ADMIN_SETTINGS_OPTION: "Gosodiadau",
  SERVICE_ADMIN_SETTINGS_MANAGE_USERS_OPTION: "Manage Users",
  SERVICE_ADMIN_CIRCLE_NOCIRCLE_MESSAGE:
    "No Circles found! Add your first Circle",
  SERVICE_ADMIN_CIRCLE_CREATE_BUTTON: "Create Circles",
  SERVICE_ADMIN_CIRCLE_CREATE_TITLE: "Create Circle",
  SERVICE_ADMIN_CIRCLE_CREATE_ACCESS_CODE: "Free Access Code",
  SERVICE_ADMIN_CIRCLE_CREATE_FIRST_NAME: "Circle firstname (cared for)",
  SERVICE_ADMIN_CIRCLE_CREATE_FIRST_NAME_PLACEHOLDER: "firstname",
  SERVICE_ADMIN_CIRCLE_CREATE_LAST_NAME_CARED: "Circle lastname (cared for)",
  SERVICE_ADMIN_CIRCLE_CREATE_LAST_NAME_PLACEHOLDER: "lastname",
  SERVICE_ADMIN_CIRCLE_CREATE_WELCOME_TEXT: "Welcome text",
  SERVICE_ADMIN_CIRCLE_CREATE_ADMIN: "Jointly administrator (carer)",
  SERVICE_ADMIN_CIRCLE_CREATE_ADMIN_EMAIL: "E-bost",
  SERVICE_ADMIN_CIRCLE_CREATE_ADMIN_FIRST_NAME: "firstname",
  SERVICE_ADMIN_CIRCLE_CREATE_LAST_NAME: "lastname",
  SERVICE_ADMIN_CIRCLE_CREATE_ADD_MORE_BUTTON: "Add More Members",
  SERVICE_ADMIN_CIRCLE_CREATE_SAVE: "Arbed",
  SERVICE_ADMIN_CIRCLE_CREATE_CANCEL: "Canslo",
  SERVICE_ADMIN_RESEND_ICON: "Resend welcome mail",
  SERVICE_ADMIN_COPY: "Copied to clipboard",
  SERVICE_ADMIN_CIRCLE_CREATE_FIRST_NAME_BLANK_ERROR:
    "Please input Circle first name!",
  SERVICE_ADMIN_CIRCLE_CREATE_CIRCLE_LAST_NAME_BLANK_ERROR:
    "Please input Circle last name!",
  SERVICE_ADMIN_CIRCLE_CREATE_ADMIN_EMAIL_BLANK_ERROR: "E-bost!",
  SERVICE_ADMIN_CIRCLE_CREATE_ADMIN_FIRST_NAME_BLANK_ERROR:
    "Please input First name!",
  SERVICE_ADMIN_CIRCLE_CREATE_LAST_NAME_BLANK_ERROR: "Cyfenw!",

  GENERAL_JOINTLY_AT_A_GLANCE: "Jointly - cipolwg",
  GENERAL_FAQS: "Cwestiynau cyffredin",
  GENERAL_USER_GUIDE: "Canllaw defnyddwyr",
  GENERAL_ABOUT: "Amdanom/Cysylltu",
  GENERAL_TERMS: "Telerau gwasanaeth",
  GENERAL_PRIVACY: "Polisi preifatrwydd",
  GENERAL_COPY: "Carers UK",
  ADMIN_LANDING_TITLE: "Polisi preifatrwydd",
  ADMIN_LANDING_CIRCLES: "Eich cylchoedd",
  ADMIN_LANDING_CIRCLES_ADD_BUTTON: "Creu Cylch newydd",
  ADMIN_HELP_TEXT_ONE: "Mae Jointly yn gweithio gyda chylchoedd gofal",
  ADMIN_HELP_TEXT_ONE_SPLIT_1: "Mae Jointly",
  ADMIN_HELP_TEXT_ONE_SPLIT_2: "yn",
  ADMIN_HELP_TEXT_ONE_SPLIT_3: "gweithio gyda chylchoedd gofal",
  ADMIN_HELP_TEXT_TWO:
    "Crëwch gylch gofal o gwmpas y person rydych chi'n gofalu amdano, a gwahoddwch bobl i ymuno â chi a rhannu'r gofalu.",
  ADMIN_HELP_TEXT_THREE:
    "Fel arall, gallwch ddefnyddio Jointly ar eich pen eich hun i drefnu'r gofal ar gyfer rhywun.",

  LANDING_PAGE_HEAD_1: "Ydych chi'n edrych",
  LANDING_PAGE_HEAD_2: "ar ôl rhywun?",
  LANDING_PAGE_HEAD_3: "Gall Jointly helpu i wneud gofalu",
  LANDING_PAGE_HEAD_4: "dipyn bach yn haws ac yn llai o straen",
  LANDING_PAGE_SIGNUP: "Cofrestrwch heddiw",
  LANDING_PAGE_ABOUT: "Ynghylch Jointly",
  LANDING_PAGE_GLANCE: "Cipolwg",
  LANDING_PAGE_WHAT: "Barn gofalwyr",
  LANDING_PAGE_EMP: "Cyflogwyr a darparwyr gwasanaethau",
  LANDING_PAGE_FAQ: "Cwestiynau Cyffredin",
  LANDING_PAGE_TODO_1:
    "Mae Jointly’n cyfuno negeseuon grŵp a rhestri o bethau i’w gwneud gyda nodweddion defnyddiol eraill, sy’n cynnwys rhestri meddyginiaeth, calendr a mwy.",
  LANDING_PAGE_TODO_2:
    "Mae Jointly’n ei gwneud hi'n hawdd cyfathrebu a chydlynu rhwng y rheiny sy'n rhannu'r gofal – drwy neges destun",
  LANDING_PAGE_TOP: "Nôl i’r top",
  LANDING_PAGE_HOME: "Hafan",
  LANDING_PAGE_INVITE:
    "Gwahoddwch bobl, gweld pwy sydd yn eich cylch, ac edrych ar gofnod o weithgarwch diweddar",
  LANDING_PAGE_PROFILE: "Proffil",
  LANDING_PAGE_STORE:
    "Storiwch wybodaeth ddefnyddiol am y person rydych chi'n gofalu amdano, a chael mynediad iddi unrhyw bryd drwy glicio botwm!",
  LANDING_PAGE_MESSAGING: "Negeseuo",
  LANDING_PAGE_COMMUNCATE:
    "Cyfathrebwch gyda phawb yn eich cylch Jointly drwy glicio botwm! Postiwch negese neu uwchlwythwch lun.",
  LANDING_PAGE_NOTES: "Nodiadau",
  LANDING_PAGE_CREATE_NOTES:
    "Crëwch a threfnwch nodiadau a storiwch luniau, eu sortio nhw mewn i gategorïau, neu edrych arnyn nhw fel rhestr syml",
  LANDING_PAGE_TASKS: "Tasgau",
  LANDING_PAGE_TASK_DETAILS:
    "Byddwch yn drefnus drwy ddefnyddio tasgau a rhestri tasgau.  Yn syml, crëwch dasg a'i haseinio i unrhyw aelod newydd o'ch cylch Jointly, gan gynnwys chi eich hun, a monitro ei statws",
  LANDING_PAGE_CALENDER: "Calendr",
  LANDING_PAGE_CALENDER_DETAILS:
    "Crëwch ddyddiad/amser penodol ar gyfer digwyddiadau, a gwahodd unrhyw un yn eich cylch.",
  LANDING_PAGE_MEDICATION: "Meddyginiaethau",
  LANDING_PAGE_MEDICATION_DETAILS:
    "Cadwch drac o feddyginiaeth presennol a hen y person rydych chi'n gofalu amdano.  Gallwch uwchlwytho llun hefyd i adnabod meddyginiaeth yn gyflym.",
  LANDING_PAGE_DEVICES: "Dyfeisiau",
  LANDING_PAGE_DEVICE_DETAILS:
    "Nawr, gall Jointly gysylltu gyda dyfeisiau iechyd a gofal i rannu gwybodaeth gyda'ch cylch.  Gwiriwch yr adran hwn i weld pa ddyfeisiau sy'n gallu cael eu cysylltu.",
  LANDING_PAGE_CONTACTS: "Cysylltiadau",
  LANDING_PAGE_CONTACT_DETAILS:
    "Storiwch gyylltidau defnyddiol a chael mynediad iddynt unrhyw bryd, yn unrhyw le!",
  LANDING_PAGE_LOOK:
    "Mae edrych ar ôl rhywun yn gallu bod yn gymhleth. Mae Jointly'n gadael i mi gadw ar ben pethau a rhannu gwybodaeth yn hawdd gyda phawb sy’n gyfrifol am edrych ar ôl fy mab.",
  LANDING_PAGE_IDEA:
    "Dwi'n meddwl ei fod yn syniad gwych - ffordd wych o rannu gwybodaeth gyda'r bobl rwy'n eu dewis.  Mae'r ap hwn yn fy helpu i ddiweddaru pawb.  Diolch!",
  LANDING_PAGE_TOOL:
    "Dwi'n feddyg iau sydd newydd ddechrau defnyddio eich ap gwych er mwyn gofalu am fy nhad-cu, ac mae wedi bod yn adnodd defnyddiol iawn.",
  LANDING_PAGE_PROFESSIONALS: "Jointly ar gyfer pobl broffesiynol",
  LANDING_PAGE_FREE1:
    "Mae cyflogwyr a darparwyr gwasanaethau blaengar a modern yn cynnig Jointly am ddim.",
  LANDING_PAGE_FREE2: "i ofalwyr yn eu gweithlu neu eu hardal.",
  LANDING_PAGE_FREE3: "I gael rhagor o wybodaeth, anfonwch e-bost at",
  LANDING_PAGE_FREE4: " client.services@carersuk.org",
  LANDING_PAGE_MATTERS: "Pam bod cefnogi gofalwyr yn bwysig?",
  LANDING_PAGE_DELIVERY1: "Cyflawni newid polisi yn effeithiol",
  LANDING_PAGE_DELIVERY2:
    "Mae tirlun polisi newydd yn golygu bod newidiadau go iawn yn y ffordd y bydd angen i weithwyr proffesiynol a sefydliadau gefnogi gofalwyr. Mae Jointly yn ateb cost isel, sy'n gallu eich helpu i gyflawni'r newid hwn, a chyrraedd teuluoedd a gofalwyr gydag adnodd sy'n gwneud synnwyr i'w bywydau bob dydd.",
  LANDING_PAGE_RETENTION1: "Cadw gweithwyr medrus",
  LANDING_PAGE_RETENTION2:
    "Mae 1 mewn 9 o bobl mewn unrhyw weithlu yn gofalu am rywun sy'n sâl, yn eiddil neu ag anabledd. Bydd y mwyafrif rhwng 45-64 oed, sef yr oedran brig ar gyfer gofalu, pan fydd llawer o weithwyr wedi ennill sgiliau a phrofiad gwerthfawr. Gyda llai o bobl ifanc yn ymuno â'r farchnad swyddi, ni fu erioed adeg mwy pwysig i ganolbwyntio ar y manteision o gadw gweithwyr medrus, yn hytrach nag ysgwyddo costau recriwtio ac ailhyfforddi staff newydd.",
  LANDING_PAGE_PROF: "Barn pobl broffesiynol",
  LANDING_PAGE_PROF2:
    "Roeddem yn falch iawn o gynnig yr ap Jointly i'n cydweithwyr. Mae Jointly yn wasanaeth arloesol, sy'n cefnogi gofalwyr i reoli'r gofal ar gyfer rhywun agos, sy'n gymorth mawr i ofalwyr sy'n cydbwyso eu cyfrifoldebau gofalu â gwaith.",
  LANDING_PAGE_OFFER:
    "Rydym yn teimlo bod Jointly yn andodd arloesol, y gallwn ei gynnig i ofalwyr er mwyn eu cefnogi yn eu rôl ofalu. Mae'n arbennig o ddefnyddiol i'r rheiny sy'n ceisio cydbwyso gwaith gyda gofal, ac ar gyfer y gofalwyr hynny sy'n rhannu'r gofal gydag aelodau eraill o'r teulu, ffrindiau neu gymdogion.",
  LANDING_PAGE_QUESTIONS: "Cwestiynau cyffredin",
  LANDING_PAGE_Q1: "Beth ydy Jointly?",
  LANDING_PAGE_A1:
    "Ap ffôn symudol ac ar-lein ydy Jointly, a grëwyd gan ofalwyr i ofalwyr. Mae wedi cael ei ddylunio i wneud gofalu ychydig yn haws, yn llai o straen ac yn fwy trefnus. Mae'n cyfuno negeseuon grŵp gyda nodweddion defnyddiol eraill, gan gynnwys rhestri o bethau i'w gwneud a meddyginiaethau, calendr a mwy! Gallwch ei ddefnyddio gydag eraill neu ar eich pen eich hun.",
  LANDING_PAGE_Q2: "Ar ba ddyfeisiau y gallaf i ddefnyddio Jointly?",
  LANDING_PAGE_A2A:
    "Gallwch ddefnyddio Jointly ar y dyfeisiau canlynol (sylwch y bydd angen i chi fod ar-lein i wneud newidiadau i Jointly):",
  LANDING_PAGE_A2B: "iPhone ac iPad (iOS fersiwn 9.0 ac uwch)",
  LANDING_PAGE_A2C:
    "Dyfeisiau Android (fersiwn 5 ac uwch). Os ydych chi'n rhedeg fersiwn hŷn o Android, rydym yn argymell gosod y porwr Firefox ar eich dyfais (ei lawrlwytho o Google Play) a defnyddio ",
  LANDING_PAGE_A2D: "jointlyapp.com",
  LANDING_PAGE_A2E: ", sydd â'r un swyddogaeth â'r fersiwn Android.",
  LANDING_PAGE_A2F:
    "Mae'r rhan fwyaf o borwyr gwe modern (cyfrifiaduron cartref, gliniaduron, ffonau clyfar, tabledi, ac ati) ar ",
  LANDING_PAGE_A2G: "jointlyapp.com",
  LANDING_PAGE_A2H:
    "Ar ôl i chi greu cyfrif Jointly, gallwch ei ddefnyddio i fewngofnodi i Jointly ar unrhyw ddyfais o'ch dewis. Lawrlwythwch yr ap Jointly ar eich dyfais iPhone, iPad neu Android, neu ewch i ",
  LANDING_PAGE_A2I: "jointlyapp.com",
  LANDING_PAGE_A2J:
    "o unrhyw dabled, ffôn symudol, gliniadur neu gyfrifiadur cartref arall.",
  LANDING_PAGE_Q3: "Ydy Jointly am ddim?",
  LANDING_PAGE_A3: "Gallwch fynd i ",
  LANDING_PAGE_A3A: "jointlyapp.com",
  LANDING_PAGE_A3B:
    "neu lawrlwytho'r ap am ddim a chael mynediad i fideo Jointly, canllaw defnyddwyr Jointly, a chwestiynau cyffredin Jointly er mwyn cael gwybod mwy. Mae Jointly'n gweithio gyda chylchoedd gofal. Gallwch brynu cylch Jointly gyda thaliad untro o £2.99, naill ai drwy'r storfeydd Apple neu Google Play,  neu drwy fynd i ",
  LANDING_PAGE_MORE: "Rhagor o Gwestiynau Cyffredin",
  LANDING_PAGE_BROUGHT: "Mae Jointly wedi cael ei greu gan Carers UK",
  LANDING_PAGE_: "",

  DEVICE_FILTER: "Hidlo fesul gwasanaethau",

  COOKIE_TITLE: "POLISI CWCIS",
  COOKIE_TITLE_ANS1:
    "Mae Carers UK yn parchu preifatrwydd ymwelwyr i'n gwefan Jointly, ac mae eisiau diogelu unrhyw wybodaeth bersonol a roddwch i ni. Mae’r datganiad hwn yn cael ei wneud er mwyn cydymffurfio â darpariaethau'r Rheoliad Cyffredinol ar Ddiogelu Data (GDPR), ac ynghyd â'n",
  COOKIE_TITLE_ANS2: "polisi preifatrwydd",
  COOKIE_TITLE_ANS3:
    " bydd yn dweud wrthych sut y bydd Carers UK yn defnyddio unrhyw wybodaeth bersonol a roddwch i ni.",
  COOKIE_Q1: "Sut rydym yn casglu gwybodaeth",
  COOKIE_A1:
    "Rydyn ni'n derbyn gwybodaeth bersonol amdanoch chi pan fyddwch chi'n cofrestru ar gyfer cyfrif defnyddiwr newydd Jointly, pan fyddwch chi'n cofnodi data ar wefan Jointly, yn cysylltu â ni i dderbyn cymorth technegol, yn rhoi adborth i ni neu'n gwneud cwyn neu'n rhoi gwybodaeth bersonol i ni mewn unrhyw ffordd arall.",
  COOKIE_Q2: "Pa wybodaeth rydym yn ei chasglu",
  COOKIE_A2:
    "I roi profiad da i chi wrth i chi bori ac i helpu i wella Jointly, rydym yn defnyddio cwcis.",
  COOKIE_A2A: `Marcwyr testun bach ydy cwcis sydd wedi'u storio ar eich cyfrifiadur, sy'n ein galluogi i ddeall sut mae pobl yn defnyddio ein gwefan. Nid oes unrhyw wybodaeth bersonol y gellir eich adnabod chi ohono yn cael ei storio ar y cwcis. Yn debyg i lawer o wefannau tebyg, rydym yn eu defnyddio i helpu i gofio hoffterau ac ar gyfer mesuriadau ystadegol-er enghraifft, er mwyn i ni wybod faint o "ymweliadau "mae tudalen wedi'i chael. Nid yw cwcis yn gallu niweidio eich cyfrifiadur.`,
  COOKIE_Q3: "Rydym yn defnyddio cwcis i:",
  COOKIE_A3A:
    "gofio gwybodaeth benodol am ddefnyddwyr fel nad oes rhaid iddynt roi'r un wybodaeth dro ar ôl tro.",
  COOKIE_A3B:
    "gydnabod os yw defnyddwyr eisoes wedi mewngofnodi i rai rhannau o'r wefan.",
  COOKIE_A3C:
    "fesur sut mae pobl yn defnyddio ein gwefan, fel y gallwn wella sut y darperir gwybodaeth drwy’r amser.",
  COOKIE_A3D:
    "Gallwch gael rhagor o wybodaeth am sut i reoli cwcis ar wefan Swyddfa'r Comisiynydd Gwybodaeth.",
  COOKIE_Q4: "Eich caniatâd",
  COOKIE_A4:
    "Mae cwcis yn gallu cael eu gosod gennym ni neu drydydd parti. Drwy barhau i bori ein gwefan, rydych chi'n cytuno i ni ddefnyddio cwcis.",
  COOKIE_Q5: "Pam ydym ni’n defnyddio cwcis?",
  COOKIE_A5:
    "Mae cwcis yn bwysig i wneud i’n gwefan weithio, ac yn ein helpu i'w gwella,",
  COOKIE_A5A: "Dyma'r prif resymau pam rydym yn defnyddio cwcis:",
  COOKIE_Q6: "1. Google Analytics a Google Tag Manager",
  COOKIE_A6A:
    "Mae hwn yn wasanaeth rydym yn ei ddefnyddio gan Google sy'n casglu gwybodaeth am sut mae pobl yn defnyddio ein gwefan. Rydym yn defnyddio’r gwasanaeth hwn i sicrhau ein bod ni’n darparu'r gwasanaeth gorau posibl i'n hymwelwyr ar y we.",
  COOKIE_A6B: "Defnyddio cwcis Mae Google Analytics yn storio gwybodaeth am ",
  COOKIE_A6C: "y tudalennau rydych chi’n ymweld â nhw",
  COOKIE_A6D: "pa mor hir rydych chi'n aros ar y dudalen",
  COOKIE_A6E: "sut wnaethoch chi gyrraedd y dudalen",
  COOKIE_A6F: "pa bethau rydych chi'n clicio arnyn nhw",
  COOKIE_A6G:
    "Er ein bod ni’n casglu'r wybodaeth hon, nid ydym yn casglu unrhyw wybodaeth bersonol - felly nid ydym yn gwybod beth yw eich enw na ble rydych chi’n byw. Ac ni ellir defnyddio'r wybodaeth a gasglwn i'ch adnabod chi.",
  COOKIE_Q7: "2. Cwcis parti cyntaf",
  COOKIE_A7:
    "Rydych yn defnyddio cwcis i sicrhau parhad ac i gynnal eich mewngofnodi o dudalen i dudalen",
  COOKIE_Q8: "3. Cwcis trydydd parti",
  COOKIE_A8:
    "Efallai y byddwch yn defnyddio cwcis fel y bydd y wefan yn gweithredu’n gywir pan gewch eich cyfeirio at ein darparwr taliadau ",
  COOKIE_A8A: "www.stripe.com ",
  COOKIE_A8B: "os ydych chi’n prynu cylch Jointly.",
  COOKIE_Q9: "Sut i reoli cwcis",
  COOKIE_A9:
    "Gallwch reoli a/neu ddileu cwcis yn ôl eich dymuniad, neu ddileu cwcis sydd wedi'u gosod gan y safle-am fwy o fanylion, ewch i ",
  COOKIE_A9A: "aboutcookies.org. ",
  COOKIE_A9B:
    "Gallwch ddileu pob cwci sydd eisoes ar eich cyfrifiadur, a gallwch osod y rhan fwyaf o borwyr i'w hatal rhag cael eu gosod. I wneud hynny, dylech addasu gosodiadau eich porwr, clicio ar adran gymorth eich porwr, a dilyn y cyfarwyddiadau. Ond os byddwch yn gwneud hyn, efallai y bydd yn rhaid i chi addasu rhai dewisiadau bob tro y byddwch yn ymweld â'r wefan, ac efallai na fydd rhai gwasanaethau a swyddogaethau yn gweithio.",
  COOKIE_: "",

  PRIVACY_TITLE: "Hysbysiad Preifatrwydd",
  PRIVACY_DESC1:
    "Mae Jointly yn eiddo i Carers UK ac yn cael ei redeg ganddo, ac mae ar gael i'w brynu oddi ar storfeydd yr apiau Apple a Google, drwy https://jointlyapp.com ac mae hefyd yn cael ei ddarparu mewn partneriaeth â chyflogwyr neu ddarparwyr gwasanaethau lleol.",
  PRIVACY_DESC2:
    "Mae Carers UK wedi ymrwymo i ddiogelu eich gwybodaeth bersonol a bod yn dryloyw am beth rydym yn ei wneud gyda’r wybodaeth honno, ni waeth sut rydych yn rhyngweithio â ni. Mae hynny p'un a ydych yn ymuno fel aelod, yn creu cyfrif ar un o'r llwyfannau ar-lein rydym yn berchen arnynt a’n eu rhedeg, yn prynu ein cynnyrch a'n gwasanaethau, yn tanysgrifio i'n cylchlythyr neu eisiau dysgu mwy am ein gwaith.",
  PRIVACY_DESC3:
    "Mae’r hysbysiad preifatrwydd hwn yn dweud wrthych beth i'w ddisgwyl pan fydd Carers UK yn casglu gwybodaeth bersonol am Jointly (",
  PRIVACY_DESC4: "neu o’r  storfa apiau: ",
  PRIVACY_POINT:
    "Rydym wedi ymrwymo i ddefnyddio eich gwybodaeth bersonol yn unol â'n cyfrifoldebau. Mae'n ofynnol i ni roi'r wybodaeth yn yr hysbysiad preifatrwydd hwn i chi yn unol â chyfraith berthnasol sy'n cynnwys:",
  PRIVACY_P1: "Y Rheoliad Cyffredinol ar Ddiogelu Data (EU) 2016/679",
  PRIVACY_P2:
    "Deddf Diogelu Data 2018 (' DPA ') y cyfeirir ati fel y ' ddeddfwriaeth diogelu data",
  PRIVACY_P3:
    "Rheoliadau Preifatrwydd a Chyfathrebu Electronig (Cyfarwyddeb y GE) 2003.",
  PRIVACY_EXPECT:
    "Ni fyddwn yn gwneud unrhyw beth gyda'ch gwybodaeth na fyddech yn rhesymol yn ei ddisgwyl.",
  PRIVACY_PROCESS:
    "Mae'r gwaith o brosesu eich gwybodaeth bersonol yn cael ei wneud gan, neu ar ran, Carers UK, sydd wedi'i gofrestru fel elusen yng Nghymru a Lloegr (246329), yr Alban (SC039307); a Carers UK Trading Ltd, sydd wedi cofrestru fel cwmni yng Nghymru a Lloegr (06034910) (gyda’i gilydd – Carers UK Group).",
  PRIVACY_PERSONAL:
    "Mae'r hysbysiad hwn, ynghyd â thelerau ac amodau Jointly, yn dweud wrthych sut rydym yn casglu, defnyddio a diogelu eich gwybodaeth bersonol.",
  PRIVACY_QUERIES:
    "Os oes gennych chi unrhyw ymholiadau am ein hysbysiad preifatrwydd, cysylltwch â'n tîm Llywodraethu Gwybodaeth:",
  PRIVACY_EMAIL: "E-bost",
  PRIVACY_PHONE: "Rhif ffôn",
  PRIVACY_POST: "Post",
  PRIVACY_POST1: "Cynghorydd Diogelu Data",
  PRIVACY_QUESTION: "Sut a pryd rydym yn casglu gwybodaeth amdanoch chi",
  PRIVACY_Q1: "Pan rydych chi’n rhoi gwybodaeth uniongyrchol i ni",
  PRIVACY_A1:
    "Gan mai ap ydy Jointly y gallwch ei ddefnyddio i storio gwybodaeth amdanoch chi eich hun a rhywun rydych chi’n gofalu amdano, mae'n bosibl i chi storio amrywiaeth eang o wybodaeth ar yr ap; gall rhywfaint o’r wybodaeth hon fod yn sensitif ac yn gyfrinachol. Mae’n bosibl i chi ddewis rhannu’r wybodaeth hon gyda pherson arall hefyd, drwy eu gwahodd i ymuno â'ch cylch Jointly.",
  PRIVACY_A1A:
    "Mae'r holl wybodaeth rydych chi'n ei storio yn Jointly yn cael ei storio yn ein storfa ddata ddiogel, a gallai copi o'r wybodaeth hon gael ei storio hefyd yn cache eich ap neu borwr gwe, er mwyn i chi allu ei weld pan nad ydych chi ar-lein.",
  PRIVACY_A1B:
    "Byddwn yn casglu a storio gwybodaeth amdanoch chi hefyd pan fyddwch chi’n rhyngweithio gyda ni. Er enghraifft, gallai hyn fod pan fyddwch chi’n cysylltu â ni i dderbyn cymorth technegol, i roi adborth neu i wneud cwyn",
  PRIVACY_Q2: "Pan rydych chi’n rhoi gwybodaeth anuniongyrchol i ni",
  PRIVACY_A2:
    "Efallai y byddwn yn cael gwybodaeth am eich defnydd o Jointly, er enghraifft y tudalennau rydych chi’n ymweld â nhw a sut rydych chi’n llywio'r safle, drwy ddefnyddio cwcis. Ewch i'n ",
  PRIVACY_A2A: "polisi cwcis ",
  PRIVACY_A2B: "i gael rhagor o wybodaeth am hyn.",
  PRIVACY_Q3: "Pa wybodaeth y byddwn yn ei chasglu",
  PRIVACY_A3:
    "Os ydych chi’n cofrestru ar gyfer cyfrif defnyddiwr newydd Jointly, byddwn yn gofyn i chi ddarparu eich cyfeiriad e-bost - mae angen hyn arnoch chi er mwyn i chi fedru mewngofnodi i'r gwasanaeth Jointly. Mae yna hefyd fannau i storio eich rhif ffôn a'ch cyfeiriad, ond mae'r rhain yn ddewisol.",
  PRIVACY_A3A:
    "Os byddwch yn cysylltu â ni dros y ffôn, drwy'r post, mewn person neu ar-lein er mwyn cael mwy o wybodaeth am y gwasanaeth, cael gafael ar gymorth technegol, rhoi adborth neu i wneud cwyn, gallai gwybodaeth rydym yn ei chasglu gynnwys eich enw, cyfeiriad e-bost, rhif ffôn, a gwybodaeth arall sy'n ymwneud â chi'n bersonol y gallwch ddewis ei ddarparu i ni.",
  PRIVACY_A3B:
    "Mae cyfraith diogelu data yn cydnabod bod rhai mathau o wybodaeth bersonol yn fwy sensitif. Mae hyn yn cael ei alw’n wybodaeth bersonol ' sensitif ' neu’n 'categori arbennig ', ac mae'n cynnwys gwybodaeth sy'n datgelu tarddiad hiliol neu ethnig, credoau crefyddol neu athronyddol a barn wleidyddol, aelodaeth o undeb llafur, data genetig neu fiometrig, gwybodaeth yn ymwneud ag iechyd, neu ddata sy'n ymwneud â bywyd rhywiol neu gyfeiriadedd rhywiol person.",
  PRIVACY_A3C:
    "Yr unig wybodaeth sensitif rydym yn ei storio ydy’r wybodaeth y byddwch yn ei rhoi'n wirfoddol ar ap neu wefan Jointly, neu yn anfon atom drwy e-bost. Os byddwn yn storio unrhyw wybodaeth sensitif arall, dim ond pan fydd angen, a dim ond gyda'ch caniatâd penodol chi y bydd hyn yn cael ei gasglu. Bydd hysbysiadau clir yn cael eu darparu ar yr adeg y byddwn yn casglu'r wybodaeth hon, yn nodi pa wybodaeth sydd ei hangen, a pham.",
  PRIVACY_Q4: "Os ydych chi’n 16 oed neu’n iau",
  PRIVACY_A4:
    "Os ydych chi’n 16 oed neu'n iau, rhaid i chi gael caniatâd eich rhiant/gwarcheidwad cyn i chi roi unrhyw wybodaeth bersonol i ni.",
  PRIVACY_Q5: "Sut a pryd rydym yn defnyddio eich gwybodaeth",
  PRIVACY_A5:
    "Byddwn yn defnyddio eich gwybodaeth bersonol ar gyfer y dibenion canlynol:",
  PRIVACY_A5A: "I ddarparu mynediad i ap a gwefan Jointly:",
  PRIVACY_A5B:
    "Byddwn yn defnyddio eich gwybodaeth bersonol i'ch galluogi i gael mynediad i’n ap a'n gwefan, i bersonoli eich profiad, ac i’w wella a'i ddatblygu ymhellach. Yn benodol, byddwn yn defnyddio eich gwybodaeth bersonol (e-bost) i hwyluso creu cyfrif defnyddiwr-bydd hyn yn caniatáu i chi fewngofnodi i'r platfform i storio gwybodaeth ychwanegol eich hun ac, os byddwch yn dewis, i rannu'r wybodaeth hon ag eraill drwy eu gwahodd i ymuno â'ch cylch. Efallai y byddwn yn defnyddio eich cyfeiriad e-bost a'ch enw i'ch diweddaru gyda diweddariadau pwysig am wasanaethau (nid negeseuon e-byst marchnata yw'r rhain).",
  PRIVACY_A5C: "Ymateb i gais:",
  PRIVACY_A5D:
    "Os ydych yn cysylltu â ni gydag ymholiad, efallai y byddwn yn defnyddio eich gwybodaeth bersonol i anfon ymateb i chi.",
  PRIVACY_A5E: "Monitro a gwerthuso:",
  PRIVACY_A5F:
    "Efallai y byddwn yn defnyddio eich gwybodaeth er mwyn gwella'r modd y darperir ein gwasanaeth ar hyn o bryd ac yn y dyfodol.",
  PRIVACY_A5G: "Gweinyddu:",
  PRIVACY_A5H:
    "Efallai y byddwn yn defnyddio eich gwybodaeth bersonol i gofnodi a delio â chwyn, i gofnodi cais i beidio â derbyn rhagor o wybodaeth farchnata, i gofnodi'r hyn mae ein gwirfoddolwyr wedi'i wneud drosom ni, ac ar gyfer dibenion cadw cofnodion mewnol hanfodol eraill.",
  PRIVACY_Q6: "Gyda phwy rydym yn rhannu eich gwybodaeth?",
  PRIVACY_A6A:
    "Byddwn ond yn defnyddio eich gwybodaeth at y dibenion y'i cafwyd. Ni fyddwn, o dan unrhyw amgylchiadau, yn gwerthu nac yn rhannu eich gwybodaeth bersonol gydag unrhyw drydydd parti at eu dibenion eu hunain, ac ni fyddwch yn derbyn deunyddiau marchnata gan unrhyw gwmnïau, elusennau na sefydliadau eraill o ganlyniad i roi eich manylion i ni.",
  PRIVACY_A6B: "Byddwn ond yn rhannu eich data at y dibenion canlynol:",
  PRIVACY_A6C:
    "Cyflenwyr trydydd parti: Efallai y bydd angen i ni rannu eich gwybodaeth gyda darparwyr cynnal data neu ddarparwyr gwasanaeth sy'n ein helpu i gyflenwi ein cynnyrch, ein gwasanaethau neu'n prosiectau. Bydd y darparwyr hyn yn gweithredu yn unol â’n cyfarwyddyd yn unig, ac maen nhw’n destun craffu cyn-contract.",
  PRIVACY_A6D:
    "Rydym yn ceisio sicrhau bob amser, mai dim ond at ddibenion cyfreithlon y caiff gwybodaeth bersonol ei defnyddio gan y trydydd partïon hynny, yn unol â'r Hysbysiad Preifatrwydd hwn. Weithiau, mae Jointly yn cael ei ddarparu mewn partneriaeth â darparwyr gwasanaeth neu gyflogwyr sy'n cynnig y gwasanaeth i ddefnyddwyr gwasanaeth neu gyflogwyr sydd â chyfrifoldebau gofalu. Ni fyddwn byth yn rhannu eich gwybodaeth bersonol gyda'n partneriaid cyflenwi, a bydd unrhyw adrodd yn cael ei wneud ar sail data dienw.",
  PRIVACY_Q7: "Sut rydym yn diogelu eich gwybodaeth",
  PRIVACY_A7A:
    "Rydym yn defnyddio dulliau diogelu sefydliadol technegol a chorfforaethol i sicrhau bod eich gwybodaeth bersonol yn ddiogel.  Rydym yn cyfyngu ar fynediad i wybodaeth ar sail angen gwybod, ac yn cymryd camau priodol i sicrhau bod ein pobl yn ymwybodol bod gwybodaeth o'r fath yn cael ei defnyddio yn unol â'r Hysbysiad Preifatrwydd hwn yn unig.",
  PRIVACY_A7B:
    "Rydym yn cynnal adolygiadau rheolaidd o bwy sydd â mynediad at yr wybodaeth sydd gennym, i sicrhau mai dim ond staff, gwirfoddolwyr a chontractwyr sydd wedi'u hyfforddi'n briodol sy'n gallu cael gafael ar eich gwybodaeth.",
  PRIVACY_A7C:
    "Mae ein ffurflenni ar-lein wedi'u hamgryptio bob amser, ac mae ein rhwydwaith yn cael ei ddiogelu a'i fonitro'n rheolaidd. Mae data Jointly yn cael ei storio mewn seilwaith cynnal diogel a saff gyda AWS o fewn y DU. Mae'r system yn cydymffurfio â'r ardystiadau diogelwch a sicrwydd canlynol:",
  PRIVACY_POI2: "PCI-DSS Lefel 1",
  PRIVACY_A7D:
    "Mae copïau wrth gefn o'r system hon yn cael eu storio gydag Amazon S3 yn eu Canolfannau Data yn Lloegr. Mae'r ddwy ganolfan yn cydymffurfio'n llawn â’r Rheoliad Cyffredinol ar Ddiogelu Data.",
  PRIVACY_Q8: "Creu copïau wrth gefn o’r systemau",
  PRIVACY_A8:
    "Rydym yn creu copïau wrth gefn o’r system Jointly gyfan, y gellir eu cadw am gyfnod o hyd at 3 mis.  Dim ond ar gyfer atgyweirio neu adfer y system y defnyddir y rhain, yn achos problem dechnegol, ac ar ôl yr amser hwn, caiff eich gwybodaeth ei dileu'n barhaol.",
  PRIVACY_Q9:
    "Sut rydym yn delio gyda’ch gwybodaeth debyd uniongyrchol a cherdyn credyd",
  PRIVACY_A9A:
    "Os ydych chi’n defnyddio eich cerdyn credyd neu ddebyd i roi arian i ni, neu i brynu neu fwcio rhywbeth ar-lein, rydym yn pasio manylion eich cerdyn yn ddiogel i'n partneriaid prosesu taliadau. Rydym yn gwneud hyn yn unol â safonau'r diwydiant, ac nid ydym yn storio'r manylion ar ein gwefan.",
  PRIVACY_A9B:
    "Mae Carers UK yn cydymffurfio’n llawn â holl ofynion y Diwydiant Cardiau Talu (PCI), ac mae'n defnyddio darparwyr sy'n cydymffurfio â'r PCI fel Stripe i gasglu'r data hwn ar ein rhan. Nid ydym yn storio data PCI ar ein systemau ein hunain.",
  PRIVACY_A9C:
    "Ond dylech fod yn ymwybodol bod risgiau yn bodoli bob amser wrth anfon gwybodaeth gan rwydweithiau cyhoeddus, neu drwy ddefnyddio cyfrifiaduron cyhoeddus, ac ni allwn warantu diogelwch y data 100% (gan gynnwys gwybodaeth bersonol) sydd yn cael ei datgelu neu ei throsglwyddo dros rwydweithiau cyhoeddus.",
  PRIVACY_Q10: "Am ba mor hir y byddwn yn cadw eich gwybodaeth?",
  PRIVACY_A10A:
    "Byddwn yn cadw eich gwybodaeth bersonol cyhyd ag y bo'n angenrheidiol at y dibenion y'i casglwyd, gan ystyried y canllawiau a gyhoeddwyd gan Swyddfa'r Comisiynydd Gwybodaeth.",
  PRIVACY_A10B:
    "Gallwch ddileu'r holl wybodaeth rydych chi'n ei storio yn eich ‘cylch ' Jointly (sy'n cynnwys yr holl wybodaeth am y person rydych chi'n gofalu amdano) a'ch cyfrif personol ar unrhyw adeg ar yr ap/gwefan. I wneud hyn, ewch i ‘My account > Delete your account and information’ ' (Sylwch mai chi yw'r unig aelod o'r cylch sydd ar ôl, a rhaid i chi fod yn ' weinyddwr cylch ' cyn i chi weld yr opsiwn hwn).",
  PRIVACY_A10C:
    "Os byddwch yn gofyn i ni roi'r gorau i brosesu eich gwybodaeth bersonol at ddiben marchnata, efallai y byddwn, mewn rhai achosion, yn gorfod ychwanegu eich manylion i ffeil er mwyn i ni allu cydymffurfio â'ch cais.",

  PRIVACY_Qa11: "Data retention and deletion:",
  PRIVACY_Aa11:
    "Jointly user account and Jointly circle data is stored for the purposes of providing access to the Service and to provide access to information about the person being cared for to members of a Jointly circle.",
  PRIVACY_Aa12:
    "You can delete all of the information that you store within your Jointly ‘circle’ (containing all of the information about the person you are caring for) and your personal account at any time yourself within the app/website. To do this please go to ‘My account > Delete your account and information’ (please note that you must be the only remaining member of the circle and must be a ‘Circle administrator’ before you will see this option).",
  PRIVACY_Aa13: "Jointly user account data:",
  PRIVACY_Aa14:
    "Jointly user account data is retained until you delete your own user account. When a Jointly user account is deleted we follow a deletion policy to make sure that all data related to your user account is safely and completely removed from our servers.",
  PRIVACY_Aa15:
    "If a Jointly user account is inactive for five (5) consecutive years then the account is considered inactive. We will notify the account holder that the account and all related data will be deleted within two (2) calendar months unless activity is recorded in the account during the specified time period (we send three reminder emails: two months, one month and one week before deletion). If no further account activity is recorded then all data related to the user account is safely and completely removed from our servers",
  PRIVACY_Aa16: "Jointly circle data:",
  PRIVACY_Aa17:
    "Is retained until a Jointly user who has circle administrator rights deletes the Jointly circle within the Jointly app. When a Jointly user deletes a Jointly circle we follow a deletion policy to make sure that all data related to that circle is safely and completely removed from our servers.",
  PRIVACY_Aa18:
    "If a Jointly circle is inactive for five (5) consecutive years (this is defined as no activity in that specific circle by any of the Jointly circle members registered with that circle) we will notify the circle administrators that all data related to that Jointly circle will be deleted within two (2) calendar months unless activity is recorded in the circle during the specified time period. If no further circle activity is recorded then all data related to the Jointly circle is safely and completely removed from our servers.",

  PRIVACY_Q11: "Amgylchiadau bregus",
  PRIVACY_A11:
    "Rydym yn deall y gallai fod angen gofal ychwanegol arnoch pan fyddwn yn casglu ac yn prosesu gwybodaeth bersonol aelodau, cefnogwyr a gwirfoddolwyr bregus. I gydnabod hyn, rydym yn dilyn canllaw arfer da wrth ryngweithio â phobl fregus.",
  PRIVACY_Q12: "Trosglwyddo gwybodaeth yn rhyngwladol",
  PRIVACY_A12A:
    "Efallai y byddwn, ar brydiau, yn penderfynu defnyddio gwasanaethau cyflenwr y tu allan i'r Ardal Economaidd Ewropeaidd (AEE), sy'n golygu bod eich gwybodaeth bersonol yn cael ei throsglwyddo, ei phrosesu a'i storio y tu allan i'r AEE. Dylech fod yn ymwybodol, yn gyffredinol, nad yw diogelwch cyfreithiol ar gyfer gwybodaeth bersonol mewn gwledydd y tu allan i'r AEE o bosibl yn cyfateb i lefel y diogelwch a ddarperir yn yr AEE.",
  PRIVACY_A12B:
    "Fodd bynnag, rydym yn cymryd camau i roi mesurau diogelwch priodol ar waith i ddiogelu eich gwybodaeth bersonol pan gaiff ei phrosesu gan y cyflenwr, fel ymrwymo i gymalau cytundebol safonol wedi’u cymeradwyo gan y Comisiwn Ewropeaidd. Drwy gyflwyno eich gwybodaeth bersonol i ni, rydych yn cytuno i'ch gwybodaeth gael ei throsglwyddo, ei storio neu ei phrosesu hwn mewn lleoliad y tu allan i'r AEE.",
  PRIVACY_Q13: "Eich hawliau i wybodaeth bersonol",
  PRIVACY_A13A:
    "Yn unol â’r ddeddfwriaeth diogelu data, mae gennych yr hawl i ofyn am fynediad at wybodaeth bersonol amdanoch chi a brosesir gan Carers UK, ac i gywiro unrhyw gamgymeriadau.",
  PRIVACY_A13B:
    "Mae gennych chi’r hawl hefyd i ofyn i ni ddileu eich gwybodaeth bersonol, gofyn i ni gyfyngu ein prosesu o'ch gwybodaeth bersonol, neu wrthwynebu i ni brosesu eich gwybodaeth bersonol.",
  PRIVACY_A13C:
    "Os ydych chi’n dymuno arfer yr hawliau hyn, ysgrifennwch atom a'i hanfon, ynghyd â chopïau o ddwy ddogfen adnabod ar wahân sy'n cynnwys llun adnabod ac sy’n cadarnhau eich cyfeiriad, fel pasbort, trwydded yrru, neu fil cyfleustodau.",
  PRIVACY_A13D:
    "Dylech ddarparu unrhyw wybodaeth ychwanegol sy'n berthnasol i natur eich cyswllt â ni hefyd, gan y bydd hyn yn ein helpu i ddod o hyd i'ch cofnodion.",
  PRIVACY_A13E: "Gallwch anfon y dogfennau atom ni drwy’r post i: ",
  PRIVACY_A13F: "Data Protection Advisor",
  PRIVACY_A13G:
    "Fel arall, gallwch anfon copi o’r ffurflen, ynghyd a sganiau neu luniau o’ch dwy ffurflen adnabod i: governance@carersuk.org",
  PRIVACY_A13H:
    "Byddwn yn ymateb o fewn 30 diwrnod ar ôl derbyn eich cais ysgrifenedig a chopïau o’ch dogfennau adnabod.",
  PRIVACY_Q14: "Sut i wneud cwyn neu godi pryder",
  PRIVACY_A14:
    "Os hoffech ragor o wybodaeth, neu os oes gennych unrhyw gwestiynau am yr hysbysiad hwn, i wneud cwyn ffurfiol am ein hymagwedd at ddiogelu data, neu i godi pryderon ynghylch preifatrwydd, cysylltwch drwy’r dulliau canlynol:",
  PRIVACY_A14A:
    "Os hoffech wneud cwyn mewn perthynas â sut rydym wedi delio â'ch gwybodaeth bersonol, dilynwch ein gweithdrefn gwyno. Os nad ydych yn hapus gyda'r ymateb a dderbyniwch, gallwch godi eich pryder gyda'r corff statudol perthnasol:",
  PRIVACY_A14B: "Swyddfa’r Comisiynydd Gwybodaeth",
  PRIVACY_A14C:
    "Fel arall, gallwch fynd i’w gwefan. Rydym wedi cofrestru gyda Swyddfa’r Comisiynydd Gwybodaeth fel Rheolydd Data o dan y rhif Z7307775.",
  PRIVACY_Q15: "Newidiadau i’n Hysbysiad Preifatrwydd",
  PRIVACY_A15:
    "Efallai y bydd ein Hysbysiad Preifatrwydd yn newid o bryd i’w gilydd, felly edrychwch ar y dudalen hon bob hyn a hyn i weld os ydym wedi cynnwys unrhyw ddiweddariadau neu newidiadau, a’ch bod chi’n hapus gyda nhw. (Diweddarwyd ddiwethaf: 11 Mai 2020)",
  PRIVACY_: "",

  GLANCE_TITLE: "Jointly – cipolwg",
  GLANCE_NEXT: "Nesaf",
  GLANCE_PREVIOUS: "Blaenorol",
  GLANCE_EXIT: "Gadael",
  GLANCE_Q1: "Beth ydy Jointly?",
  GLANCE_A1A: "Mae Jointly wedi cael ei ddatblygu gan ",
  GLANCE_A1B: "Carers UK",
  GLANCE_A1C:
    ", ac mae’n cyfuno negeseuon grŵp gyda nodweddion defnyddiol eraill, sy’n cynnwys rhestrau o bethau i’w gwneud a meddyginiaethau, calendr a mwy.",
  GLANCE_A1D: "Gallwch ei ddefnyddio i fod yn drefnus wrth ofalu am rywun.",
  GLANCE_PROFILE: "Proffil",
  GLANCE_PROFILE_DETAILS:
    "Gallwch ddefnyddio’r lle hwn i gadw gwybodaeth ddefnyddiol am y person rydych chi’n gofalu amdano, a gallwch gael mynediad iddo ar unrhyw amser drwy glicio botwm! Gwnewch nodyn o'u dyddiad geni, eu cyflwr a'u hanghenion gofalu, eu hoffterau a'u cas bethau neu unrhyw beth arall sy'n berthnasol yn eich barn chi.",
  GLANCE_MESS: "Negeseuon",
  GLANCE_MESS_DETAIL:
    "Gallwch gyfathrebu gyda phawb yn eich cylch Jointly drwy glicio botwm! Yn syml, postiwch neges neu uwchlwythwch lun. Bydd aelodau o’ch cylch yn derbyn hysbysiad ac yn gallu ymateb.",
  GLANCE_CALENDAR: "Calendr",
  GLANCE_CALENDAR_1:
    "Defnyddiwch y calendr i greu digwyddiadau dyddiad/amser penodol, a gwahoddwch unrhyw un yn eich cylch. Gallwch hefyd ddefnyddio'r gofod a ddarperir i wahodd pobl y tu allan i'ch cylch Jointly.",
  GLANCE_CALENDAR_2:
    "Gallwch gadw tabiau o'r holl ddigwyddiadau sydd wedi’u cynllunio yng nghalendr Jointly ac ar yr un pryd, anfon a derbyn gwahoddiadau yn eich calendr personol.",
  GLANCE_NOTES: "Nodiadau",
  GLANCE_NOTES_DETAIL:
    "Gallwch ddefnyddio Nodiadau i storio ystod eang o wybodaeth ac i ychwanegu lluniau. Gall hyn gynnwys dogfennau, biliau a gwybodaeth am yr aelwyd neu am sut mae rhywun yn teimlo.",
  GLANCE_TASKS: "Tasgau",
  GLANCE_TASKS_DETAIL_1:
    "Ceisiwch fod yn drefnus a chadw ar ben pethau drwy ddefnyddio tasgau a rhestrau tasgau. Yn syml, crëwch dasg a'i aseinio i unrhyw aelod o'ch cylch Jointly, gan gynnwys chi eich hun, a monitro ei statws.",
  GLANCE_TASKS_DETAIL_2:
    "Mae'r dudalen Tasgau yn rhoi crynodeb i chi o'r holl dasgau. Maen nhw'n cael eu rhannu'n dasgau (ar gyfer tasgau annibynnol, fel:",
  GLANCE_TASKS_DETAIL_3: "Nôl meddyginiaeth mam",
  GLANCE_TASKS_DETAIL_4:
    ") a rhestrau Tasgau (ar gyfer tasgau sy'n perthyn i restr, fel:",
  GLANCE_TASKS_DETAIL_5: "Rhestr bwydydd wythnosol mam –",
  GLANCE_TASKS_DETAIL_6: "gallwch storio mwy o eitemau fel ",
  GLANCE_TASKS_DETAIL_6B: "Prynu llaeth, bwyd ci ac ati",
  GLANCE_TASKS_DETAIL_7: "Nodyn",
  GLANCE_TASKS_DETAIL_8: "Os yw eich tasg yn benodol i amser (fel Gyrru ",
  GLANCE_TASKS_DETAIL_9:
    "mam i’w hapwyntiad doctor ar ddydd Mawrth 5 Tachwedd am 10 am",
  GLANCE_TASKS_DETAIL_10:
    "), rydym yn argymell eich bod chi’n creu cofnod calendr yn hytrach na thasg.",
  GLANCE_MED: "Meddyginiaethau",
  GLANCE_MED_DETAILS:
    "Defnyddiwch nodwedd Meddyginiaethau Jointly i gadw golwg ar feddyginiaeth bresennol a blaenorol y person rydych chi’n gofalu amdano. Gallwch uwchlwytho llun hefyd i adnabod meddyginiaeth yn gyflym.",
  GLANCE_MED_DETAILS_1: "Drwy ddefnyddio'r nodwedd Meddyginiaeth, gallwch:",
  GLANCE_MED_DETAILS_2: "Greu cofnod ar gyfer meddyginiaeth",
  GLANCE_MED_DETAILS_3:
    "Weld meddyginiaeth heddiw a chael syniad o'r amser y dylid ei gymryd yn ogystal â'r dos (",
  GLANCE_MED_DETAILS_4: "Heddiw",
  GLANCE_MED_DETAILS_5: ").",
  GLANCE_MED_DETAILS_6:
    "Weld yr holl feddyginiaethau presennol a blaenorol mewn rhestr (",
  GLANCE_MED_DETAILS_7: "Pob meddyginiaeth",
  GLANCE_MED_DETAILS_8: ")",
  GLANCE_CONT: "Cysylltiadau",
  GLANCE_CONT_1:
    "Does dim yn helpu mwy na cael y manylion cyswllt cywir ar yr adeg cywir! Mae Jointly'n storio manylion cyswllt aelodau o'ch cylch, ac yn eich galluogi chi i storio cysylltiadau mwy defnyddiol er mwyn i chi allu cael mynediad i'w manylion unrhyw bryd, yn unrhyw le!",
  GLANCE_CONT_2:
    "Cliciwch ar rif rhywun i wneud galwad neu ar eu cyfeiriad e-bost i anfon e-bost.",
  GLANCE_: "",
  GLANCE_REMINDER: "Atgof",
  GLANCE_REMINDER_1:
    "Gyda'r swyddogaeth Atgoffa, gallwch chi sefydlu lluosog yn hawdd - naill ai unwaith, neu 'restrau' atgoffa ailadroddus.",
  GLANCE_REMINDER_2:
    "Gan ddefnyddio'r nodwedd Atgoffa gallwch:",
  GLANCE_REMINDER_3:
    "Creu rhestrau atgoffa ar gyfer pethau fel glanhau o gwmpas y tŷ, siopa groser, neu godi meddyginiaethau yn hawdd.",
  GLANCE_REMINDER_4:
    "Gellir ychwanegu nifer o eitemau llinell at bob rhestr, gan wneud Nodyn Atgoffa yn eich llyfr nodiadau hawdd ei ddefnyddio yn eich poced.",
  GLANCE_REMINDER_5:
    "Mae'n hawdd ticio eitemau ar restrau fel rhai sydd wedi'u cwblhau neu (er enghraifft yn achos rhestr siopa) eu hychwanegu at eich siop wythnosol.",
  GLANCE_REMINDER_6:
    "Mae'r holl eitemau a weithredir ar restrau Atgoffa yn cael eu cofnodi, felly mae gennych dawelwch meddwl, a gallwch wirio yn ôl i weld beth a weithredwyd a phryd.",
  GLANCE_REMINDER_7:
    "Mae'r swyddogaeth ar y Cyd Atgoffa yn gwneud eich holl restrau “i'w gwneud” yn syml i'w creu, yn hawdd i'w gweithredu ac yn galonogol o syml i gadw golwg arnynt.",

  ABOUT_TITLE: "Cysylltu â thîm Jointly",
  ABOUT_D1:
    "Os ydych chi'n profi problem dechnegol neu os oes gennych ymholiad technegol, cysylltwch â:",
  ABOUT_D2: "Mae",
  ABOUT_D3: "canllaw defnyddwyr Jointly",
  ABOUT_D4: " a'r ",
  ABOUT_D5:
    " yn trafod nifer o bynciau. Cymerwch olwg - efallai y gwnewch chi ddod o hyd i beth ydych chi’n chwilio amdano",
  ABOUT_D6:
    "Os oes gennych chi ymholiadau/awgrymiadau cyffredinol, cysylltwch â",
  ABOUT_D7: "Mae Jointly wedi cael ei ddatblygu gan Carers UK.",
  ABOUT_D8:
    "Yn Carers UK, rydym yn gweithio'n galed i wneud bywyd yn well i'r miliynau o bobl sy'n gofalu am deulu a ffrindiau hŷn, sâl neu anabl.",
  ABOUT_D9: "Rydym yn darparu cymorth a chefnogaeth emosiynol arbenigol",
  ABOUT_D10:
    "Rydym yn dod â phobl at ei gilydd, fel bod neb yn gorfod gofalu ar eu pen eu hunain",
  ABOUT_D11:
    "Rydym yn ymgyrchu gyda gofalwyr i greu gwasanaethau gwell, dealltwriaeth well a bywydau gwell.",
  ABOUT_D12:
    "Mae gofalu'n gallu bod yn anodd, ac rydyn ni yma i'ch cefnogi chi ar bob cam o'r ffordd",
  ABOUT_D13: "Ewch i ",
  ABOUT_D14:
    "i gysylltu gyda gofalwyr eraill ac i gael yr wybodaeth sydd ei hangen arnoch chi i'ch helpu i edrych ar ôl rhywun ac i fyw bywyd eich hun.",
  ABOUT_D15:
    "Os ydych chi eisiau siarad efo rhywun sy'n deall go iawn am ofalu, ffoniwch ni ar ",
  ABOUT_: "",

  OTHERS_DELETE_CIRCLE_POPUP:
    "Bydd y weithred hon yn dileu'r holl wybodaeth sydd wedi'i storio yn y cylch hwn! Ni ellir dad-wneud hyn, ydych chi'n siŵr eich bod chi eisiau dileu'r cylch hwn?",
  OTHERS_DELETE_CIRCLE: "Dileu'r cylch",
  OTHERS_DELETE_CIRCLE_ACTION:
    "Bydd y weithred hon yn dileu'r cylch hwn. Ydych chi'n siŵr?",
  OTHERS_REMOVE_PERSON:
    "Ydych chi’n siŵr eich bod chi eisiau dileu’r person hwn o’r cylch?",
  OTHERS_EDIT_LIST: "Golygu rhestr",
  OTHERS_DELETE_LIST: "Dileu rhestr",
  OTHERS_EDIT_TASK_LIST: "Golygu rhestr dasgau",
  OTHERS_UPDATE: "Diweddaru",

  OTHERS_RIGHTS: "Cedwir pob hawl",
  OTHERS_INFO: "Lawrlwytho eich gwybodaeth",
  OTHERS_DELETE: "Dileu eich cyfrif a'ch gwybodaeth",
  OTHERS_ACTIVITY: "Tasgau heb eu neilltuo",
  OTHERS_UNASSIGNES_TASK: "Tasgau heb eu neilltuo",
  OTHERS_CREATE: "Crëwyd gan",
  OTHERS_CRE: "Crëwyd",
  OTHERS_INV: "Gwahoddwyd",
  OTHERS_EDIT_CATEGORY: "Golygu'r categori",
  OTHERS_CREATE_CATEGORY: "Creu Categori Newydd",
  OTHERS_CON_INFO: "Gwybodaeth Gyswllt",
  OTHERS_ING: "Llun",
  OTHERS_LOAD_COMMENTS: "Llwytho Sylwadau",
  OTHERS_POST_COMMENT: "Postiwch Eich Sylwad Yma",
  OTHERS_INVITE: "Gwahodd",
  OTHERS_ACTIVE: "GWEITHREDOL",
  OTHERS_CIR_PROFILE: "Golygu proffil y cylch",

  OTHERS_CIR_CREATED: "Cylch wedi ei greu gan",
  OTHERS_EMAIL: "Cyfeiriad e-bost",
  OTHERS_CIR_TRIAL:
    "Mae eich treial wedi dod i ben. Dylech uwchraddio eich cylch i barhau i ddefnyddio Jointly",
  OTHERS_YOU: "Chi",
  OTHERS_YOUR: "eich",
  OTHERS_SEARCH_MESG: "Chwilio negeseuon",
  OTHERS_SEARCH_MED: "Chwilio meddyginiaeth",
  OTHERS_SEARCH_TASK: "Chwilio tasgau",
  OTHERS_SEARCH_REMINDER: "Chwilio nodiadau atgoffa",
  OTHERS_SEARCH_EVT: "Chwilio digwyddiadau",
  OTHERS_SEARCH_NOTES: "Chwilio nodiadau",
  OTHERS_SEARCH_DEVS: "Chwilio dyfeisiau",
  OTHERS_SEARCH_CONT: "Chwilio Cysylltiadau",
  OTHERS_SEARCH_CIRCLE: "Fy nghylchoedd",
  OTHERS_INVIT: "wedi cael gwahoddiad i ymuno â'r cylch hwn",
  OTHERS_DUE_TODAY: "Dyledus heddiw",
  OTHERS_DUE: "Dyledus: ",
  OTHERS_CIR_SETTINGS: "Gosodiadau cylch",
  OTHERS_ACTIONS: "Camau gweithredu",
  OTHERS_CONNECT:
    "Mae Jointly yn gallu cysylltu i ddyfeisiau allanol, a byddwn yn ychwanegu mwy cyn bo hir.  Cliciwch ar 'Ymuno' i gysylltu eich cylch Jointly gyda'r ddyfais hon.  Bydd hyn yn awdurdodi'r ddyfais i anfon gwybodaeth i'ch cylch Jointly.  Os ydych chi eisiau datgysylltu eich cylch, dewiswch 'Gadael'",
  OTHERS_TITLE: "Teitl ",
  OTHERS_REMINDERS: "Dewis nodiadau atgoffa",

  OTHERS_SET_1: "Ar y sgrîn hon gall gweinyddwyr:",
  OTHERS_SET_2: "Wahodd aelodau newydd a chael gwared ar aelodau o'r cylch.",
  OTHERS_SET_3:
    "Gwneud aelodau o'r cylch hwn yn weithredol neu’n anweithredol. Os ydych yn gwneud aelod yn anweithredol, mae'n golygu na fyddant bellach yn rhan o'r cylch hwn. Ni fyddwch yn gallu aseinio tasgau iddynt o hyn ymlaen, ac ni fyddant yn cael eu hysbysu am weithgareddau cylch.",
  OTHERS_SET_4:
    "Roi caniatâd i aelodau eraill y cylch hwn fod yn weinyddwyr, golygu meddyginiaethau neu edrych ar nodiadau mewn categorïau sydd wedi'u marcio'n breifat.",
  OTHERS_SET_4A:
    "Set a member as an Emergency Contact (ICE), this will be displayed in the contacts list and if you export data from Jointly.",
  OTHERS_SET_5:
    "Dileu'r cylch. Rhaid i'r holl aelodau eraill fod wedi gadael neu wedi cael eu symud cyn y gellir dileu cylch. Mae dileu cylch yn barhaol, a does dim modd newid hyn.",
  OTHERS_DATE: "Dyddiad",
  OTHERS_LEAVE: "Gadael",
  OTHERS_DETAILS: "Manylion",
  OTHERS_SERVICEID: "ID y Gwasanaeth",
  OTHERS_SERVICE_KEY: "Allwedd y gwasanaeth",
  OTHERS_CIRCLEID: "Id Cylch",
  OTHERS_JOIN: "Ymuno",
  OTHERS_REASON: "Rheswm",
  OTHERS_MEM_INFO: "Gwybodaeth yr aelod",
  OTHERS_MEM: "Gwybodaeth",
  OTHERS_NEW: "Mwyaf newydd i ddechrau",
  OTHERS_OLD: "Hynaf i ddechrau",
  OTHERS_CIR: "cylch",
  OTHERS_ADMIN: "Gweinyddydd",
  OTHERS_CAN: "Yn gallu golygu",
  OTHERS_MED: "meddyginiaethau",
  OTHERS_PRIVATE: "Yn gallu gweld nodiadau",
  OTHERS_NOTES: "categori preifat",

  OTHERS_DOWNLOAD_1:
    "Gallwch lwytho copi o'ch gwybodaeth Jointly i lawr ar unrhyw adeg.",
  OTHERS_DOWNLOAD_2:
    "Cliciwch ar y botwm isod, ac fe wnawn ni anfon dolen atoch chi, fel eich bod chi'n gallu llwytho holl ddata eich cylchoedd Jointly i lawr fel ffeil Excel.",
  OTHERS_DOWNLOAD_3: "Er diogelwch, bydd y ddolen yn dod i ben ar ol 24 awr.",
  OTHERS_DOWNLOAD_4: "Llwytho i lawr",
  OTHERS_DOWNLOAD_5:
    "Nid ydym yn gallu diogelu data sydd yn cel eu hallgludo gennych o Jointly.  Gwnewch yn siŵr bod unrhyw wybodaeth sensitif rydych chi'n ei llwytho i lawr yn cael ei storio'n ddiogel.",
  OTHERS_DOWNLOAD_6:
    "You can manually select what information you'd like to export from Jointly or download a 'contingency plan', essential information that would be useful to share in the event of an emergency.",
  OTHERS_ABOUT: "Ynghylch",
  OTHERS_VIDEO: "Fideo Jointly",
  OTHERS_COOKIES: "Polisi Cwcis",
  OTHERS_EVERYTHING: "Popeth",
  OTHERS_CATEGORY: "Dewis Categori",
  OTHERS_REPLY: "Ateb",
  OTHERS_FULLNAME: "ENW LLAWN",
  OTHERS_INVITE_SENT: "GWAHOODIAD WEDI'I ANFON",
  OTHERS_INVITED_BY: "GWAHOODWYD GAN",
  OTHERS_ATTACH:
    "Dim ond un atodiad y gallwch chi ei atodi i Nodyn, ydych chi eisiau atodi atodiad newydd?",
  OTHERS_SURE: "Ydych chi'n siŵr?",
  OTHERS_SURE_DELETE_REMINDER:
    "Ydych chi'n siŵr eich bod am ddileu'r nodyn atgoffa hwn?",
  OTHERS_SURE_DELETE_REMINDER_ITEM:
    "Ydych chi'n siŵr eich bod am ddileu'r eitem hon?",
  OTHERS_NO_EVTS: "Dim digwyddiadau",
  OTHERS_EDIT_NOTE: "Golygu nodyn",
  OTHERS_UNASSIGN: "Dadneilltuo",
  OTHERS_MARK_ALL:
    "Ydych chi'n siŵr eich bod chi eisiau marcio pob neges fel wedi'u darllen?  Nid yw'n bosibl dad-wneud hyn",
  OTHERS_UNREAD_ALL: "Ydych chi eisiau darllen pob hysbysiad?",
  OTHERS_READ_ALL: "Marcio pob un fel wedi darllen",
  OTHERS_ALL_DAY: "Drwy'r dydd",
  OTHERS_: "",

  TOASTERS_NO_DOWNLOAD: "Methu lawrlwytho!",
  TOASTERS_PAYMENT_SUCCESS: "Taliad yn llwyddiannus!",
  TOASTERS_CREATE_CIRCLE: "Cylch wedi'i greu",
  TOASTERS_CIRCLE_UPDATE: "Cylch wedi'i ddiweddaru'n llwyddiannus",
  TOASTERS_NOTE_UPDATE: "Nodyn wedi'i ddiweddaru",
  TOASTERS_NOTE_CREATE: "Nodyn wedi'i greu",
  TOASTERS_NOTE_DELETE: "Nodyn wedi'i ddileu",
  TOASTERS_TASK_CREATE: "Tasg wedi'i chreu",
  TOASTERS_TASK_UPDATE: "Tasg wedi'i diweddaru",
  TOASTERS_INVI_SENT: "Gwahoddiadau wedi'u hanfon",
  TOASTERS_EVT_CREATE: "Digwyddiad wedi'i greu",
  TOASTERS_EVT_UPDATE: "Digwyddiad wedi'i ddiweddaru",
  TOASTERS_EVT_DELETE: "Digwyddiad wedi'i ddileu",
  TOASTERS_CONTACT_ADD: "Cyswllt wedi'i ychwanegu",
  TOASTERS_CATEGORY_UPDATE: "Categori wedi'i ddiweddaru'n llwyddiannus",
  TOASTERS_CATEGORY_DELETE: "Categori wedi'i ddileu'n llwyddiannus",
  TOASTERS_TASK_DELETE: "Tasg wedi'i dileu",
  TOASTERS_PAY_COMPLETED: "Taliad wedi'i gwblhau'n llwyddiannus",
  TOASTERS_UNAVAILABLE: "Dim ar gael wedi'i osod yn llwyddiannus",
  TOASTERS_UNAVAILABLE_UPDATE: "Diim ar gael wedi'i ddiweddaru",
  TOASTERS_UNAVAILABLE_DELETE: "Dim ar gael wedi'i ddileu'n llwyddiannus",
  TOASTERS_TASK_REMOVE: "Wedi dileu'r rhestr dasgau'n llwyddiannus",
  TOASTERS_INVI_DELETE: "Gwahoddiad wedi'i ddileu",
  TOASTERS_LEFT: "Rydych chi wedi gadael y cylch",
  TOASTERS_MEM_DELETE: "Aelod o'r cylch wedi'i ddileu",
  TOASTERS_CIR_DELETE: "Cylch wedi'i ddileu",
  TOASTERS_DEVICE_DELETE: "Dyfais wedi'i dileu'n llwyddiannus",
  TOASTERS_MED_ADDED: "Meddyginiaeth wedi'i hychwanegu",
  TOASTERS_NO_EDIT:
    "Does gennych chi ddim caniatad i olygu meddyginiaethau.  Cysylltwch gyda gweinyddydd eich cylch",
  TOASTERS_NO_DELETE:
    "Does gennych chi ddim caniatad i ddileu meddyginiaethau.  Cysylltwch gyda gweinyddwr eich cylch",
  TOASTERS_NO_ACCESS: "Does gennych chi ddim mynediad i'r eitem hwn",
  TOASTERS_SER_CONN: "Gwasanaeth wedi'i gysylltu'n llwyddiannus",
  TOASTERS_SER_REMOVED: "Gwasanaeth wedi'i ddileu'n llwyddiannus",
  TOASTERS_PROMO_CREATE: "Cod promo wedi'i greu'n llwyddiannus",
  TOASTERS_PROMO_UPDATE: "Cod promo wedi'i ddiweddaru'n llwyddiannus",
  TOASTERS_PROMO_DELETE: "Cod promo wedi'i ddileu'n llwyddiannus",
  TOASTERS_INVITATION_SEND: "Cod gwahoddiad wedi'i anfon yn llwyddiannus",
  TOASTERS_SER_ADMIN_CREATE: "Cyfrif y Gweinyddwr wedi'i greu'n llwyddiannus",
  TOASTERS_RESEND_CODE: "Ailanfon y cod gwahoddiad yn llwyddiannus",
  TOASTERS_CODE_DELETE: "Cod gwahoddiad wedi'i ddileu",
  TOASTERS_SER_ADMIN_DELETE: "Cyfrif y Gweinyddwr wedi'i ddileu'n Llwyddiannus",
  TOASTERS_LINK_SUCESS: "Dolen hud wedi'i hanfon yn Llwyddiannus",
  TOASTERS_INVI_RESEND: "Gwahoddiad wedi'i ailanfon",
  TOASTERS_NOTI_CHANGED: "Gosodiadau hysbysebu wedi'u newid",
  TOASTERS_SITE_UPDATE: "Gosodiadau'r Wefan wedi'u diweddaru",
  TOASTERS_PASSWORD: "Cyfrinair wedi'i ailosod yn llwyddiannus",
  TOASTERS_INVI_ACCEPT: "Gwahoddiad wedi'i dderbyn yn llwyddiannus",
  TOASTERS_INVI_REJECT: "Gwahoddiad wedi'i wrthod",
  TOASTERS_ACC_DELETE: "Cyfrif wedi'i ddileu",
  TOASTERS_NOT_MATCH: "Cyfrinair Newydd a Cadarnhau Cyfrinair ddim yr un fath",
  TOASTERS_: "",

  TERMS_HEADING: "Telerau Defnyddio Gwasanaeth Jointly",
  TERMS_DATE: "Mae’r Telerau Defnyddio hyn yn effeithiol ar 29 Tachwedd 2013.",
  TERMS_DESC_1:
    "Mae'r Telerau Defnyddio hyn (“Telerau Defnyddio”, “Cytundeb”) yn gyfystyr â chytundeb cyfreithiol rhyngddoch chi (“chi“, “eich”) a “Carers UK Trading Ltd”,  (cwmni cofrestredig 6034910, swyddfa gofrestredig 20 Great Dover Street, Llundain SE1 4LX) ar gyfer y canlynol, y cyfeirir atynt gyda'i gilydd yn y Telerau Defnyddio hyn fel y Gwasanaeth Jointly”:",
  TERMS_DESC_2: "Telerau Defnyddio",
  TERMS_DESC_3: "Cytundeb",
  TERMS_DESC_4:
    "”) yn gyfystyr â chytundeb cyfreithiol rhyngddoch chi (“chi“, “eich”) a “",
  TERMS_DESC_5: "Carers UK Trading Ltd”",
  TERMS_DESC_6: "we”",
  TERMS_DESC_7: "us”",
  TERMS_DESC_8: "our”",
  TERMS_DESC_9:
    ") (cwmni cofrestredig 6034910, swyddfa gofrestredig 20 Great Dover Street, Llundain SE1 4LX) ar gyfer y canlynol, y cyfeirir atynt gyda'i gilydd yn y Telerau Defnyddio hyn fel y ",
  TERMS_DESC_10: "Gwasanaeth Jointly",
  TERMS_P1: "gwefan Jointly (",
  TERMS_P2: "Gwefan",
  TERMS_P3: "meddalwedd ap ffôn symudol Jointly (“Ap”)",
  TERMS_P4: "unrhyw wasanaethau sydd yn cael eu darparu drwy’r Wefan neu’r Ap",
  TERMS_P5:
    "Mae'r Ap yn cynnwys unrhyw ddiweddariadau neu ychwanegiadau i'r Ap (oni bai eu bod yn dod gyda thelerau ar wahân, ac os felly, bydd y telerau hynny yn drech na'r telerau defnyddio hyn os digwydd gwrthdaro).",
  TERMS_P6:
    "Mae cyfeiriadau at y ”Gwasanaeth Jointly” yn y Telerau Defnyddio hyn yn cyfeirio at y gwasanaeth cyfan neu at unrhyw ran o'r Gwasanaeth Jointly.",
  TERMS_P7:
    "Trwy gyrchu neu ddefnyddio'r Gwasanaeth Jointly, sut bynnag y'u cyrchir neu y’u defnyddir, rydych yn cytuno i gael eich rhwymo gan y Telerau Defnyddio hyn. Mae'r Gwasanaeth Jointly yn eiddo i Carers UK Trading Ltd ac yn cael ei reoli ganddo. Mae'r Telerau Defnyddio hyn yn effeithio ar eich hawliau a'ch rhwymedigaethau cyfreithiol. Os ydych chi’n dewis peidio â chytuno ag unrhyw rai o'r Telerau Defnyddio hyn, ni chewch chi ddefnyddio'r Gwasanaeth Jointly.",
  TERMS_P8:
    "Ar yr amod eich bod chi’n cydymffurfio â'r Telerau Defnyddio hyn, ac unrhyw reolau neu bolisïau a gymhwysir gan unrhyw ddarparwr neu weithredwr y bu i chi lwytho'r Ap i lawr (“",
  TERMS_P9: "Appstore",
  TERMS_P10:
    "”) o'u gwefan, mae trwyddedau Carers UK Trading Ltd yn trwyddedu'r defnydd o'r Gwasanaeth Jointly i chi, ar yr amod eich bod yn defnyddio'r Gwasanaeth Jointly ar gyfer eich defnydd personol yn unig.",
  TERMS_PARA_2: "Gofynion y system weithredu",
  TERMS_PARA_2_1: "iPhone ac iPad (fersiwn iOS 9.0 ac uwch)",
  TERMS_PARA_2A: "Dyfeisiau Android (fersiwn 5 ac uwch)",
  TERMS_PARA_2B:
    "Y rhan fwyaf o borwyr gwe modern (cyfrifiaduron cartref, gliniaduron, ffonau clyfar, tabledi, ac ati.) yn ",
  TERMS_PARA_3: "Rhybudd pwysig:",
  TERMS_PARA_3_P1:
    "Drwy lwytho’r Ap i lawr o'r wefan neu’r AppStore, rydych chi’n cytuno i'r Telerau Defnyddio hyn, a fydd yn eich rhwymo chi. Mae'r telerau hyn yn cynnwys, yn benodol:",
  TERMS_PARA_3_P2:
    "y drwydded a’r cyfyngiadau defnyddio derbyniol (amodau 3 a 4).",
  TERMS_PARA_3_P3: "yr amodau Cynnwys Defnyddwyr (amod 5).",
  TERMS_PARA_3_P4: "ymwadiadau gwarantau (amod 12).",
  TERMS_PARA_3_P5: "cyfyngiadau ar atebolrwydd (amod 13).",
  TERMS_PARA_3_P6:
    "y polisi preifatrwydd (ar ddiwedd y Telerau Defnyddio hyn).",
  TERMS_PARA_3_P7:
    "Os nad ydych chi’n cytuno i'r Telerau Defnyddio hyn, ni chaniateir i chi eu llwytho i lawr, na defnyddio'r Ap nac unrhyw ran arall o'r Gwasanaeth Jointly.",
  TERMS_PARA_3_P8:
    "Fel defnyddiwr, mae gennych yr hawl i dynnu'n ôl o'ch trafodiad yn ddi-dâl, a heb unrhyw reswm, cyn llwytho hwn i lawr. Fodd bynnag, byddwch yn colli'r hawl i ganslo'r trafodiad ar ôl i chi ddechrau llwytho i lawr. Nid yw hyn yn effeithio ar eich hawliau defnyddwyr ar gyfer unrhyw gynnyrch wedi'I lwytho i lawr sy'n ddiffygiol.",
  TERMS_PARA_3_P9:
    "Gallwch lwytho’r Ap i lawr am ddim. Fodd bynnag, efallai y byddwch yn gallu prynu cynhyrchion drwy'r Ap neu'r Gwasanaeth Jointly, fel cylchoedd Jointly. Bydd defnyddio cynhyrchion o'r fath yn cael ei lywodraethu gan, ac yn amodol ar gydymffurfio â'r Telerau Defnyddio hyn, ond gallant fod yn destun telerau gwerthu ychwanegol ar y pwynt prynu, y bydd angen i chi gytuno iddynt cyn prynu. Os bydd unrhyw wrthdaro rhwng y Telerau Defnyddio hyn ac unrhyw delerau gwerthu o'r fath, y telerau defnyddio hyn fydd drechaf.",
  TERMS_PARA_3_P10:
    "Dylech argraffu copi o'r Telerau Defnyddio hyn er mwyn cyfeirio atynt yn y dyfodol.",
  TERMS_ACK_1: "1. Cydnabyddiaethau",
  TERMS_ACK_2:
    "1.1 O bryd i'w gilydd, gellir cyhoeddi diweddariadau i'r Ap drwy'r Gwasanaeth Jointly neu’r AppStore. Yn dibynnu ar y diweddariad, efallai na fyddwch yn gallu defnyddio'r Ap nes eich bod chi wedi llwytho’r fersiwn diweddaraf o'r Ap i lawr, a derbyn unrhyw delerau newydd.",
  TERMS_ACK_3:
    "1.2 Cymerir yn ganiataol eich bod chi wedi cael caniatâd perchnogion y ffôn symudol neu ddyfeisiau llaw sy'n cael eu rheoli, ond sydd ddim yn eiddo i chi, ac a ddisgrifir yn amod 2.2.1 ('Dyfeisiau') ac i lwytho copi o'r Ap ar y Dyfeisiau i lawr. Efallai y codir tâl arnoch chi a'’r darparwyr gwasanaeth am fynediad i'r rhyngrwyd ar y Dyfeisiau. Rydych yn derbyn cyfrifoldeb yn unol â'r Telerau Defnyddio hyn ar gyfer defnyddio'r Ap neu unrhyw ran arall o'r Gwasanaeth Jointly ar neu mewn perthynas ag unrhyw ddyfais, p'un a yw'n eiddo i chi ai peidio.",
  TERMS_ACK_4:
    "1.3 Mae telerau ein polisi preifatrwydd o bryd i'w gilydd, sydd ar gael isod ('Polisi Preifatrwydd') yn gymwys i'r Gwasanaeth Jointly. Yn ogystal, drwy ddefnyddio'r Gwasanaeth Jointly, rydych chi’n cydnabod ac yn cytuno nad yw darllediadau ar y rhyngrwyd byth yn gwbl breifat na diogel. Rydych yn deall y gall unrhyw neges neu wybodaeth y byddwch yn ei hanfon yn defnyddio'r Gwasanaeth Jointly gael ei darllen neu ei chyfatal gan eraill, hyd yn oed os oes hysbysiad arbennig bod trosglwyddiad penodol wedi'i amgryptio. ",
  TERMS_ACK_5:
    "1.4 Drwy ddefnyddio'r Gwasanaeth Jointly, rydych chi’n rhoi caniatâd i ni gasglu a defnyddio gwybodaeth dechnegol am y Dyfeisiau a'r meddalwedd cysylltiedig, y caledwedd a'r perifferolion ar gyfer y Gwasanaeth Jointly sy'n seiliedig ar y rhyngrwyd neu dechnoleg ddiwifr i wella'r Gwasanaeth Jointly.",
  TERMS_GRAND_1: "2. Caniatáu a chwmpas y drwydded",
  TERMS_GRAND_2:
    "2.1 O ystyried eich bod chi’n cytuno i gadw at y Telerau Defnyddio hyn, rydym yn rhoi trwydded anhrosglwyddadwy, anghyfyngedig i chi i ddefnyddio'r Gwasanaeth Jointly a'r Ap ar y Dyfeisiau, yn amodol ar y Telerau Defnyddio hyn, y Polisi Preifatrwydd a rheolau unrhyw Appstore, sy'n cael eu cynnwys yn y Telerau Defnyddio hyn drwy gyfeirnod. Rydym yn cadw pob hawl arall.",
  TERMS_GRAND_3: "2.2 Gallwch, at eich dibenion personol yn unig:",
  TERMS_GRAND_4:
    "2.2.1 lwytho copi o’r Ap i lawr a gweld, defnyddio a dangos yr Ap ar unrhyw un o’r dyfeisiau canlynol",
  TERMS_GRAND_5: "(a) iPhone",
  TERMS_GRAND_6: "(b) Dyfeisiau Android",
  TERMS_GRAND_7: "(c) iPad",
  TERMS_GRAND_8:
    "2.2.2 rydym yn defnyddio’r Gwasanaeth Jointly drwy’r Wefan (cyhyd â bod eich porwr gwe yn caniatáu mynediad); a",
  TERMS_GRAND_9:
    "2.3 Rydych yn gwarantu bod gennych yr hawl a'r gallu cyfreithiol i ymrwymo i'r telerau defnyddio hyn yn eich awdurdodaeth, a chydymffurfio â nhw.",
  TERMS_LICENCE_1: "3. Cyfyngiadau trwydded ar gyfer yr Ap",
  TERMS_LICENCE_2:
    "3.1 Ac eithrio fel y nodir yn benodol yn y Telerau Defnyddio hyn neu fel y caniateir gan unrhyw gyfraith leol, rydych yn cytuno:",
  TERMS_LICENCE_3:
    "3.1.1 peidio â rhentu, prydlesu, is-drwyddedu, benthyca, cyfieithu, uno, addasu, amrywio neu addasu'r Ap neu'r Dogfennau;",
  TERMS_LICENCE_4:
    "3.1.2 peidio â gwneud addasiadau i, neu addasiadau o, y cyfan neu unrhyw ran o'r Ap, neu ganiatáu i'r Ap neu unrhyw ran ohono gael ei gyfuno â rhaglenni eraill, neu eu hymgorffori ynddynt;",
  TERMS_LICENCE_5:
    "3.1.3 peidio â chopïo'r Ap, ac eithrio pan fo copïo o'r fath yn eilbeth i ddefnydd arferol yr Ap, neu pan fo'n angenrheidiol at ddibenion cymorth wrth gefn neu ar gyfer diogelwch gweithredol;",
  TERMS_LICENCE_6:
    "3.1.4 peidio â datgymalu, dadgrynhoi, gwrthbeiriannu neu greu gweithiau deilliadol yn seiliedig ar yr Ap cyfan neu unrhyw ran ohono, neu geisio gwneud unrhyw beth o'r fath ac eithrio i'r graddau (yn rhinwedd adran 296A o Ddeddf Hawlfraint, Dyluniadau a Phatentau 1988) na ellir gwahardd gweithredoedd o'r fath am eu bod yn hanfodol at ddiben sicrhau bod yr Ap yn gallu rhyngweithredu â rhaglen feddalwedd arall, ac ar yr amod bod yr wybodaeth a gafwyd gennych yn ystod gweithgareddau o'r fath:",
  TERMS_LICENCE_7:
    "(a) ond yn cael ei ddefnyddio at ddiben cyflawni rhyngweithredoedd o'r Ap â rhaglen feddalwedd arall;",
  TERMS_LICENCE_8:
    "(b) dim yn cael ei datgelu na'i chyfathrebu'n ddiangen heb ein caniatâd ysgrifenedig ymlaen llaw i unrhyw drydydd parti;",
  TERMS_LICENCE_9:
    "(c) dim yn cael ei defnyddio i greu unrhyw feddalwedd sy'n debyg iawn i'r Ap;",
  TERMS_LICENCE_10:
    "3.1.5 cynnwys ein hysbysiad hawlfraint ar yr holl gopïau cyfan a rhannol rydych chi’n eu gwneud o'r Ap ar unrhyw gyfrwng;",
  TERMS_LICENCE_11:
    "3.1.6 peidio â darparu’r Ap neu ganiatáu iddo fod ar gael fel arall yn llwyr neu'n rhannol (gan gynnwys y codau gwrthrych a ffynhonnell), ar unrhyw ffurf i unrhyw berson heb gael caniatâd ysgrifenedig gennym ymlaen llaw; a",
  TERMS_LICENCE_12:
    "3.1.7 cydymffurfio â'r holl ddeddfau a rheoliadau allforio neu reoli technoleg sy'n berthnasol i'r dechnoleg a ddefnyddir neu a gefnogir gan yr Ap neu unrhyw wasanaeth a ddarperir drwy'r Ap ('Technoleg').",
  TERMS_ACCEPT_1: "4. Cyfyngiadau defnyddio derbyniol",
  TERMS_ACCEPT_2: "4.1 Ni ddylech:",
  TERMS_ACCEPT_3:
    "4.1.1 ddefnyddio'r Gwasanaeth Jointly mewn unrhyw ffordd anghyfreithlon, at unrhyw ddiben anghyfreithlon, neu mewn unrhyw fodd sy’n anghyson â'r Telerau Defnyddio hyn, neu weithredu'n dwyllodrus neu'n faleisus, er enghraifft, drwy hacio mewn neu fewnosod cod maleisus, gan gynnwys firysau, neu ddata niweidiol, i'r Gwasanaeth Jointly neu unrhyw system weithredu, neu drwy ymyrryd neu darfu ar y Gwasanaeth Jointly neu weinyddion neu rwydweithiau sy'n gysylltiedig â'r Gwasanaeth Jointly, gan gynnwys drwy drosglwyddo unrhyw fwydod, feirysau, ysbïwedd, maleiswedd neu unrhyw god arall sy’n ddinistriol neu aflonyddgar ei natur;",
  TERMS_ACCEPT_4:
    "4.1.2 greu cyfrifon gyda'r Gwasanaeth Jointly drwy ddefnyddio dyfais awtomataidd, sgript, bot, corryn, ymlusgwr neu grafwr;",
  TERMS_ACCEPT_5:
    "4.1.3 newid, addasu, neu ddiwygio’r Gwasanaeth Jointly, na diwygio, addasu neu newid gwefan arall er mwyn awgrymu drwy dwyll ei bod hi’n gysylltiedig â'r Gwasanaeth Jointly, Carers UK neu Carers UK Trading Ltd;",
  TERMS_ACCEPT_6:
    "4.1.4 dresmasu ar ein hawliau eiddo deallusol nac ar hawliau unrhyw drydydd parti mewn perthynas â'ch defnydd o'r Gwasanaeth Jointly;",
  TERMS_ACCEPT_7:
    "4.1.5 drosglwyddo, na chaniatáu i drosglwyddo, unrhyw ddeunydd sy'n ddifenwol, yn dramgwyddus neu'n annymunol mewn modd arall o ran eich defnydd o'r Gwasanaeth Jointly;",
  TERMS_ACCEPT_8:
    "4.1.6 drosglwyddo, na chaniatáu i gael eu trosglwyddo, lluniau neu gynnwys arall sy’n tramgwyddo, yn dangos casineb, yn bornograffig neu’n rhywiol-awgrymog drwy'r Gwasanaeth Jointly;",
  TERMS_ACCEPT_9:
    "4.1.7 fwlio, gam-drin, aflonyddu, fygwth neu ddychryn pobl neu endidau drwy'r Gwasanaeth Jointly;",
  TERMS_ACCEPT_10:
    "4.1.8 ddefnyddio'r Gwasanaeth Jointly mewn ffordd a allai ddifrodi, analluogi, orlwytho, amharu ar neu gyfaddawdu ein systemau neu ddiogelwch, neu ymyrryd â defnyddwyr eraill; a",
  TERMS_ACCEPT_11:
    "4.1.9 gasglu na chynaeafu unrhyw wybodaeth neu ddata o'r Gwasanaeth Jointly neu ein systemau, neu geisio dehongli unrhyw drosglwyddiadau i, neu o’r gweinyddion sy'n rhedeg y Gwasanaeth Jointly.",
  TERMS_ACCEPT_12:
    "4.2 Rydych chi’n cydnabod nad ydy’r Gwasanaeth Jointly wedi'i ddatblygu i ateb eich gofynion unigol, ac felly, eich cyfrifoldeb chi yw sicrhau bod cyfleusterau a swyddogaethau'r Gwasanaeth Jointly (yn enwedig yr Ap) yn ateb eich gofynion.",
  TERMS_ACCEPT_13:
    "4.3 Rydym yn darparu’r Ap a rhannau eraill o'r Gwasanaeth Jointly at ddefnydd domestig a phreifat yn unig. Rydych yn cytuno i beidio â defnyddio'r Gwasanaeth Jointly at unrhyw ddibenion masnachol, busnes neu ailwerthu, ac nid ydym yn atebol i chi am unrhyw golled o ran elw, colli busnes, tarfu ar fusnes, neu golli cyfle busnes.",
  TERMS_ACCEPT_14:
    "4.4 Trwy gyrchu neu ddefnyddio'r Gwasanaeth Jointly, rydych yn ei gynrychioli ac yn gwarantu bod eich gweithgareddau yn gyfreithlon ym mhob awdurdodaeth lle rydych yn ei gyrchu neu'n ei defnyddio.",
  TERMS_CONTENT_1: "5. Amodau’r Cynnwys Defnyddwyr",
  TERMS_CONTENT_2:
    "5.1 Chi yn unig sy'n gyfrifol am unrhyw ddata, testun, ffeiliau, gwybodaeth, enwau defnyddwyr, delweddau, graffeg, lluniau, proffiliau, cymwysiadau, dolenni a chynnwys neu ddeunyddiau eraill (gyda’i gilydd, '",
  TERMS_CONTENT_3: "Cynnwys Defnyddwyr",
  TERMS_CONTENT_4:
    "') rydych yn eu cyflwyno, eu postio neu’n eu harddangos ar, neu drwy'r Gwasanaeth Jointly. Rhaid i chi gydymffurfio â'r holl ddeddfau, rheolau a rheoliadau sy'n berthnasol i'ch defnydd o'r Gwasanaeth Jointly a'ch Cynnwys Defnyddwyr.",
  TERMS_CONTENT_5:
    "5.2 Ni all Carers UK Trading Ltd fod yn gyfrifol am unrhyw Gynnwys Defnyddwyr sydd yn cael ei bostio ar, neu drwy'r Gwasanaeth Jointly gennych chi neu ddefnyddwyr Gwasanaeth Jointly eraill.",
  TERMS_CONTENT_6:
    "5.3 Rydych yn cynrychioli ac yn gwarantu: (i) eich bod chi’n berchen ar y Cynnwys Defnyddwyr sydd yn cael ei bostio gennych chi ar, neu drwy'r Gwasanaeth Jointly, neu fod gennych yr hawl i bostio cynnwys o'r fath fel arall; (ii) nad ydy postio a defnyddio eich Cynnwys Defnyddwyr ar neu drwy'r Gwasanaeth Jointly yn torri, yn camddefnyddio nac yn tresmasu ar hawliau unrhyw drydydd parti, gan gynnwys, heb gyfyngiad, hawliau preifatrwydd, hawliau cyhoeddusrwydd, hawlfreintiau, nod masnach a/neu hawliau eiddo deallusol eraill; (iii) eich bod chi’n cytuno i dalu am yr holl freindaliadau, ffioedd, ac unrhyw arian arall sy'n ddyledus oherwydd y cynnwys defnyddwyr rydych chi’n ei bostio ar, neu drwy'r Gwasanaeth Jointly.",
  TERMS_CONTENT_7:
    "5.4 Rydych chi’n cytuno nad yw Carers UK Trading Ltd yn gyfrifol am y Cynnwys Defnyddwyr sydd yn cael ei bostio o fewn y Gwasanaeth Jointly, ac nad yw'n cymeradwyo hynny. Nid yw Carers UK yn rhag-sgrinio, yn monitro, yn golygu nac yn dileu unrhyw Gynnwys Defnyddwyr. Os yw eich Cynnwys Defnyddwyr yn torri'r Telerau Defnyddio hyn, mae'n bosibl y byddwch yn dwyn cyfrifoldeb cyfreithiol am y Cynnwys Defnyddwyr hwnnw.",
  TERMS_CONTENT_8:
    "5.5 Efallai y gallem, ond nid ydym dan unrhyw orfodaeth i, ddileu, olygu, blocio, ac/neu fonitro Cynnwys Defnyddwyr neu gyfrifon sy'n cynnwys Cynnwys Defnyddwyr sy'n dod i'n sylw ac rydym yn penderfynu, yn ôl ein disgresiwn llwyr, sy’n torri'r Telerau Defnyddio hyn.",
  TERMS_CONTENT_9:
    "Os byddwch yn dod yn ymwybodol o unrhyw Gynnwys Defnyddwyr sy'n cynnwys deunyddiau sy'n torri'r Telerau Defnyddio hyn neu sy'n annerbyniol mewn unrhyw ffordd, rhowch wybod i ni yn ",
  TERMS_CONTENT_10:
    "5.6 Nid ydy’r Gwasanaeth Jointly yn cynnwys gwasanaeth wrth gefn, ac rydych yn cytuno na fyddwch yn dibynnu ar y Gwasanaeth Jointly at ddibenion storio Cynnwys Defnyddwyr neu wneud copïau wrth gefn.",
  TERMS_CONTENT_11:
    "Ni fydd Carers UK Trading Ltd yn atebol i chi am golli unrhyw Gynnwys Defnyddwyr, boed o ganlyniad i derfynu mynediad i'ch cyfrif neu fel arall.",
  TERMS_ACCOUNTS_1: "6. Cyfrifon",
  TERMS_ACCOUNTS_2:
    "6.1 Ni ddylech greu cyfrifon gyda'r Gwasanaeth Jointly drwy ddulliau heb eu hawdurdodi, gan gynnwys drwy gyfnewid taleb neu unrhyw god arall sydd wedi’i neilltuo'n bersonol i chi gan Carers UK Trading Ltd neu sefydliad a awdurdodwyd gan Carers UK Trading Ltd.",
  TERMS_ACCOUNTS_3:
    "6.2 Rydych chi'n gyfrifol am unrhyw weithgaredd sy'n digwydd drwy eich cyfrif, ac rydych chi'n cytuno na fyddwch yn gwerthu, trosglwyddo, trwyddedu neu’n aseinio enw defnyddiwr eich cyfrif, nac unrhyw hawliau cyfrif. Rydych chi’n cytuno na fyddwch yn creu cyfrif ar gyfer unrhyw un heblaw amdanoch chi eich hun. Rydych hefyd yn cynrychioli y bydd yr holl wybodaeth y byddwch yn ei darparu neu wedi ei darparu i Carers UK Trading Ltd ar yr adeg cofrestru ac ar bob adeg arall yn wir, yn gywir, yn gyfredol ac yn gyflawn, ac rydych yn cytuno i ddiweddaru eich gwybodaeth yn ôl yr angen i gynnal ei eirwiredd a’i gywirdeb.",
  TERMS_ACCOUNTS_4:
    "6.3 Rydych chi'n cytuno na fyddwch yn cymell, yn casglu nac yn defnyddio manylion mewngofnodi defnyddwyr eraill y Gwasanaeth Jointly.",
  TERMS_ACCOUNTS_5:
    "6.4 Chi sy’n gyfrifol am gadw eich cyfrinair yn gyfrinachol ac yn ddiogel.",
  TERMS_ACCOUNTS_6:
    "6.5 Rydym yn cadw'r hawl i fforffedu unrhyw enw defnyddiwr am unrhyw reswm.",
  TERMS_TERM_1: "7. Terfynu’r Gwasanaeth Jointly",
  TERMS_TERM_2:
    "7.1 Gallwn derfynu’r Cytundeb hwn ar unwaith drwy anfon hysbysiad ysgrifenedig atoch chi:",
  TERMS_TERM_3:
    "7.1.1 os byddwch yn torri'r Cytundeb hwn yn ei hanfod neu'n gyson, a'ch bod chi’n methu unioni hyn (os y gellir ei unioni) o fewn 14 diwrnod ar ôl cyflwyno hysbysiad ysgrifenedig yn gofyn i chi wneud hynny;",
  TERMS_TERM_4:
    "7.1.2 os ydych chi’n torri unrhyw rai o gyfyngiadau'r Drwydded ar gyfer yr Ap (amod 3), y cyfyngiadau defnydd Derbyniol (amod 4) neu'r amodau Cynnwys Defnyddwyr (amod 5); neu",
  TERMS_TERM_5:
    "7.1.3 os byddwn yn terfynu'r Ap neu ran arall o'r Gwasanaeth Jointly, neu yn colli unrhyw hawliau perthnasol sydd eu hangen i ddarparu'r Gwasanaeth Jointly, neu os yw darparu'r Ap neu ran arall o'r Gwasanaeth Jointly yn dod yn anghyfreithlon.",
  TERMS_TERM_6:
    "7.2 Rydym yn cadw'r hawl i atal neu roi'r gorau i ddarparu'r cyfan neu ran o'r Gwasanaeth Jointly i chi ar unrhyw adeg os, yn ein barn chi, rydych yn torri llythyr neu ysbryd y Telerau Defnyddio hyn, neu fel arall, yn creu risg neu amlygiad cyfreithiol posibl i Carers UK Trading Ltd neu Carers UK neu eu henwau priodol neu enw da.",
  TERMS_TERM_7: "7.3 Ar derfynu’r Cytundeb hwn:",
  TERMS_TERM_8:
    "7.3.1 bydd yr holl drwyddedau a hawliau a roddir i chi o dan y Telerau Defnyddio hyn yn dod i ben (gan gynnwys eich hawl i ddefnyddio'r Ap);",
  TERMS_TERM_9:
    "7.3.2 rhaid i chi roi'r gorau i'r holl weithgareddau a awdurdodwyd gan y Telerau Defnyddio hyn, gan gynnwys eich defnydd o'r Gwasanaeth Jointly; a",
  TERMS_TERM_10:
    "7.3.3 rhaid i chi ddileu neu dynnu'r Ap o'r holl ddyfeisiau ar unwaith, a dinistrio pob copi o'r Ap a'r dogfennau wedyn yn eich meddiant, eich gwarchodaeth neu'ch rheolaeth, ac ardystio i ni eich bod chi wedi gwneud hynny.",
  TERMS_TERM_11:
    "7.4 Ni fydd Carers UK Trading Ltd yn atebol i chi am unrhyw achos o addasu, atal dros dro neu derfynu’r Gwasanaeth Jointly.",
  TERMS_LINK_1: "8. Gwefannau cysylltiedig",
  TERMS_LINK_2:
    "Efallai y bydd dolenni o'r Gwasanaeth Jointly, neu o'r ohebiaeth rydych yn ei chael o'r Gwasanaeth Jointly i wefannau neu nodweddion trydydd parti. Gall nodweddion y Gwasanaeth Jointly hefyd ganiatáu rhyngweithio rhwng y Gwasanaeth Jointly a gwefan neu nodwedd trydydd parti.  Nid yw Carers UK Trading Ltd yn rheoli unrhyw rai o'r gwefannau trydydd parti hyn, unrhyw wasanaethau na chynhyrchion a gynigir drwyddynt, nac unrhyw rai o'u cynnwys. Rydych yn cydnabod yn benodol nad yw Carers UK Trading Ltd mewn unrhyw ffordd yn gyfrifol nac yn atebol am unrhyw wefannau, gwasanaethau na nodweddion trydydd parti o'r fath.",
  TERMS_INT_1: "9. Hawliau eiddo deallusol",
  TERMS_INT_2:
    "9.1 Rydych yn cydnabod bod yr holl hawliau eiddo deallusol yn y Gwasanaeth Jointly (gan gynnwys yr Ap, y Dogfennau a'r Dechnoleg) unrhyw le yn y byd yn perthyn i ni neu ein trwyddedwyr, bod hawliau yn yr Ap wedi'u trwyddedu (nid eu gwerthu) i chi, ac nad oes gennych hawliau yn yr Ap, y dogfennau, y dechnoleg nac yn unrhyw ran arall o'r Gwasanaeth Jointly, ac eithrio'r hawl i ddefnyddio'r Gwasanaeth Jointly yn unol â'r Telerau Defnyddio.",
  TERMS_INT_3:
    "9.2.Rydych chi’n cydnabod nad oes gennych unrhyw hawl i gael mynediad i’r Ap ",
  TERMS_INT_4:
    "9.3 Mae enw a logo Jointly yn nodau masnach Carers UK, sydd wedi'u trwyddedu i Carers UK Trading Ltd, ac ni ellir eu copïo, eu dynwared na'u defnyddio, yn gyfan gwbl neu'n rhannol, heb ganiatâd ysgrifenedig Carers UK ymlaen llaw. Yn ogystal, mae'r holl benawdau tudalen, graffeg addasu, eiconau botwm, a sgriptiau yn nodau gwasanaeth, nodau masnach ac/neu’n wisg masnach sy’n perthyn i Carers UK, ac ni ellir eu copïo, eu dynwared na'u defnyddio, yn gyfan gwbl neu'n rhannol, heb ganiatâd ysgrifenedig ymlaen llaw gan Carers UK.",
  TERMS_SER_1: "10. Argaeledd y Gwasanaeth Jointly",
  TERMS_SER_2:
    "Er mai bwriad Carers UK Trading Ltd ydy i’r Gwasanaeth Jointly fod ar gael cymaint â phosibl, efallai y bydd adegau pan fydd rhaid tarfu ar y Gwasanaeth Jointly, gan gynnwys, heb gyfyngiad, ar gyfer gwaith cynnal a chadw neu uwchraddio rheolaidd, ar gyfer atgyweiriadau brys, neu oherwydd methiant o ran dolennau ac/neu offer telathrebu. Ni allwn warantu y bydd y Gwasanaeth Jointly yn gweithredu'n ddi-dor neu heb ymyriadau, neu na fydd unrhyw wallau, ac ni allwn dderbyn unrhyw atebolrwydd os nad yw ar gael.",
  TERMS_USERS_1: "11. Defnyddwyr eraill",
  TERMS_USERS_2:
    "Chi yn unig sy'n gyfrifol am eich rhyngweithio â defnyddwyr eraill y Gwasanaeth Jointly. Rydych yn cytuno nad yw Carers UK Trading Ltd yn gyfrifol nac yn atebol am ymddygiad unrhyw ddefnyddiwr. Dylech ymarfer synnwyr cyffredin a'ch barn orau wrth ryngweithio ag eraill, gan gynnwys pan fyddwch yn cyflwyno neu'n postio Cynnwys Defnyddwyr neu unrhyw wybodaeth bersonol neu wybodaeth arall.",
  TERMS_WARR_1: "12. Ymwadiadau Gwarantau",
  TERMS_WARR_1A: "TYNNIR EICH SYLW ARBENNIG AT Y CYMAL HWN.",
  TERMS_WARR_2:
    "12.1 Darperir y Gwasanaeth Jointly ar sail 'fel ag y mae', 'fel ag y mae ar gael' a 'gyda’i holl namau'. I'r graddau eithaf a ganiateir gan y gyfraith, nid yw Carers UK Trading Ltd nac unrhyw un o'i weithwyr, rheolwyr, swyddogion neu asiantau (gyda'i gilydd, “",
  TERMS_WARR_3: "Carers UK Trading Ltd Parties",
  TERMS_WARR_4:
    "“) yn gwneud unrhyw sylwadau neu warantau neu ardystiadau o unrhyw fath, yn ddiamwys neu ymhlyg, ynghylch: (a) y Gwasanaeth Jointly (gan gynnwys y Wefan, yr Ap a’r Dogfennau; (b) y Cynnwys Defnyddwyr; neu (c) y diogelwch sy'n gysylltiedig â throsglwyddo gwybodaeth i'r Gwasanaeth Jointly neu drwy'r Gwasanaeth Jointly.",
  TERMS_WARR_5:
    "12.2 I'r graddau llawnaf a ganiateir gan y gyfraith, mae Carers UK Trading Ltd Parties drwy hyn yn gwadu'r holl warantau, yn ddiamwys neu ymhlyg, gan gynnwys, ond heb fod yn gyfyngedig i, warantau marchnadwyedd, addasrwydd at ddiben penodol, diffyg tor-cyfraith, teitl, arfer, masnach, mwynhad didramgwydd, integreiddio system a rhyddid rhag feirws cyfrifiadurol, mewn perthynas â'r Gwasanaeth Jointly.",
  TERMS_WARR_6:
    "12.3 Nid yw Carers UK Trading Ltd Parties yn cynrychioli nac yn gwarantu y bydd y Gwasanaeth Jointly yn ddiwall neu’n ddi-dor; y caiff diffygion eu cywiro; neu fod y Gwasanaeth Jointly neu'r gweinydd sy'n gwneud y Gwasanaeth Jointly ar gael heb unrhyw gydrannau niweidiol, gan gynnwys, heb gyfyngu ar, firysau. Nid yw Carers UK Trading Ltd Parties yn gwneud unrhyw gynrychioliadau na gwarantau bod yr wybodaeth (gan gynnwys unrhyw gyfarwyddiadau) ar y Gwasanaeth Jointly yn gywir, yn gyflawn, neu'n ddefnyddiol.",
  TERMS_WARR_7:
    "12.4 Rydych yn cydnabod eich bod chi’n defnyddio’r Gwasanaeth Jointly ar eich risg eich hun.  Nid yw Carers UK Trading Parties Ltd yn gwarantu bod eich defnydd o'r Gwasanaeth Jointly yn gyfreithlon mewn unrhyw awdurdodaeth benodol, ac yn benodol yn gwadu gwarantau o'r fath. Mae rhai awdurdodaethau yn cyfyngu ar, neu’n peidio â chaniatáu gwadu gwarantau ymhlyg neu eraill, felly efallai na fydd yr ymwadiad uchod yn berthnasol i chi i'r graddau y mae cyfraith awdurdodaeth o'r fath yn berthnasol i chi ac i'r Telerau Defnyddio hyn.",
  TERMS_WARR_8:
    "12.5 Nid yw Carers UK Trading Ltd Parties yn cymeradwyo Cynnwys Defnyddwyr ac, i'r graddau eithaf a ganiateir gan y gyfraith, yn benodol yn datgymhwyso unrhyw gyfrifoldeb neu atebolrwydd i unrhyw berson neu endid am unrhyw golled, difrod (boed yn wirioneddol, yn ganlyniadol, yn gosbol neu fel arall), anaf, hawliad, atebolrwydd neu achos arall o unrhyw fath neu gymeriad yn seiliedig ar, neu'n deillio o unrhyw Gynnwys Defnyddwyr.",
  TERMS_LIMIT_1: "13. Cyfyngiadau ar Atebolrwydd",
  TERMS_LIMIT_2:
    "13.1  Ni fydd dim yn y Telerau Defnyddio hyn yn cyfyngu nac yn eithrio ein hatebolrwydd am:",
  TERMS_LIMIT_3:
    "13.1.1 marwolaeth neu anaf personol o ganlyniad i'n hesgeulustod;",
  TERMS_LIMIT_4: "13.1.2 dwyll neu gamliwio twyllodrus; ac",
  TERMS_LIMIT_5:
    "13.1.3 unrhyw rwymedigaeth arall na ellir ei heithrio neu ei chyfyngu gan gyfraith Lloegr.",
  TERMS_LIMIT_6:
    "I'r graddau eithaf a ganiateir gan y gyfraith, nid ydym yn derbyn unrhyw atebolrwydd am:",
  TERMS_LIMIT_7:
    "13.2.1 unrhyw golled neu ddifrod anuniongyrchol neu ganlyniadol sy'n codi mewn cysylltiad â'ch defnydd o'r Gwasanaeth Jointly; neu",
  TERMS_LIMIT_8:
    "13.2.2 unrhyw golled neu ddifrod i ddata (gan gynnwys Data Cleifion), colli elw, colli refeniw neu golli busnes, colli cyfle, boed yn uniongyrchol neu’n anuniongyrchol ym mhob achos, sut bynnag y’u hachoswyd, hyd yn oed os yw'n rhagweladwy.",
  TERMS_LIMIT_9:
    "Mae'r golled neu'r difrod y cyfeirir atynt uchod yn cynnwys unrhyw gysylltiad â'r Gwaanaeth Jointly",
  TERMS_LIMIT_10: "(a) y Cynnwys Defnyddwyr",
  TERMS_LIMIT_11:
    "(b) eich defnydd o, eich anallu i ddefnyddio, neu berfformiad y Gwasanaeth Jointly",
  TERMS_LIMIT_12: "(c) y Data Cleifion",
  TERMS_LIMIT_13:
    "(d) unrhyw gamau a gymerwyd mewn cysylltiad ag ymchwiliad gan y partïon neu awdurdodau gorfodi'r gyfraith ynglŷn â'ch defnydd chi neu unrhyw barti arall o'r Gwasanaeth Jointly",
  TERMS_LIMIT_14:
    "(e) unrhyw gamau a gymerwyd mewn cysylltiad â hawlfraint neu berchenogion eiddo deallusol eraill",
  TERMS_LIMIT_15:
    "(f) unrhyw wallau neu hepgoriadau wrth weithredu'r Gwasanaeth Jointly",
  TERMS_LIMIT_16:
    "(g) unrhyw weithred gan AppStore i ddileu neu wrthod prosesu gwybodaeth neu gynnwys penodol",
  TERMS_LIMIT_17:
    "(h) unrhyw ddifrod i gyfrifiadur, dyfais, neu offer neu dechnoleg unrhyw ddefnyddiwr, gan gynnwys, heb gyfyngiad, difrod oherwydd unrhyw doriad diogelwch neu oherwydd unrhyw feirws, bygiau, ymyrraeth, twyll, camgymeriad, anwaith, amhariad, diffyg, oedi wrth weithredu neu drosglwyddo, methiant lein neu rwydwaith cyfrifiadurol, neu unrhyw gamweithio technegol neu arall.",
  TERMS_LIMIT_18:
    "13.3 Os byddwn yn atebol i chi am unrhyw reswm, bydd ein hatebolrwydd yn cael ei gyfyngu i £100. Chi sy'n gyfrifol am sicrhau bod eich dyfais yn bodloni'r holl fanylebau technegol perthnasol sy'n angenrheidiol i ddefnyddio'r Ap. Rydych chi hefyd yn deall na allwn warantu, ac nad ydym yn gwarantu y bydd unrhyw ddeunydd sydd ar gael i'w llwytho i lawr o'r Wefan neu o wefannau AppStore yn rhydd o heintiad, feirysau ac/neu god arall sydd â phriodweddau heintiol neu ddinistriol. Chi sy'n gyfrifol am roi digon o weithdrefnau a gwiriadau feirysau yn eu lle (gan gynnwys gwiriadau gwrth-feirws ac archwiliadau diogelwch eraill) i fodloni eich gofynion penodol o ran cywirdeb mewnbynnu ac allbynnu data.",
  TERMS_LIMIT_19:
    "13.4 Nid ydym yn gyfrifol am weithredoedd, cynnwys, gwybodaeth, na data am drydydd partïon, ac rydych chi'n ein rhyddhau ni, ein cyfarwyddwyr, ein swyddogion, ein staff, a'n hasiantau rhag unrhyw hawliadau a difrod, hysbys ac anhysbys, sy'n codi o, neu mewn unrhyw ffordd, sy'n gysylltiedig ag unrhyw hawliad sydd gennych yn erbyn unrhyw drydydd parti o'r fath.",
  TERMS_LIMIT_20:
    "13.5 Nid yw'r cyfyngiadau a'r gwaharddiadau yn y cymal hwn yn effeithio ar eich hawliau statudol anwaharddadwy, ac maen nhw’n berthnasol i'r graddau a ganiateir gan y gyfraith berthnasol yn unig.",
  TERMS_CHANGES_1: "14. Newidiadau i’r Telerau Defnyddio hyn",
  TERMS_CHANGES_2:
    "14.1 Rydym yn cadw’r hawl, yn ôl ein disgresiwn llwyr, i newid y Telerau Defnyddio hyn o bryd i'w gilydd. Oni bai ein bod ni’n newid rhywbeth am resymau cyfreithiol neu weinyddol, byddwn yn ymdrechu i roi rhybudd rhesymol ymlaen llaw cyn y daw'r Telerau Defnyddio diweddaraf i rym. Rydych yn cytuno y gallwn roi gwybod i chi am y Telerau Wedi’u Diweddaru drwy eu postio ar y Gwasanaeth Jointly, a bod eich defnydd o'r Gwasanaeth Jointly ar ôl dyddiad dod i rym y Telerau Defnyddio wedi'u Diweddaru (neu gymryd rhan mewn unrhyw ymddygiad arall y gallwn ei bennu'n rhesymol) yn gyfystyr â'ch cytundeb i'r Telerau Defnyddio wedi'u Diweddaru.",
  TERMS_CHANGES_3:
    "14.2 Gellir arddangos y Telerau Defnyddio Wedi’u Diweddaru ar y sgrin, ac efallai y bydd gofyn i chi eu darllen a'u derbyn er mwyn parhau i ddefnyddio'r Gwasanaeth Jointly. Bydd y Telerau Defnyddio wedi'u Diweddaru yn dod i rym ar yr amser cyhoeddi, neu ar unrhyw ddyddiad diweddarach a bennir ynddynt, a bydd yn berthnasol i'ch defnydd o'r Gwasanaeth Jointly o'r pwynt hwnnw ymlaen. Os nad ydych chi’n cytuno â'r Telerau Defnyddio wedi'u Diweddaru, ni chaniateir i chi barhau i ddefnyddio'r Ap nac unrhyw ran arall o'r Gwasanaeth Jointly.",
  TERMS_CHANGES_4:
    "14.3 Dylech adolygu'r Telerau Defnyddio hyn yn rheolaidd (fel y gellir eu diweddaru o bryd i'w gilydd) i sicrhau bod eich defnydd parhaus o'r Gwasanaeth Jointly yn cydymffurfio.",

  TERMS_GEN_1: "15. Cyffredinol",
  TERMS_GEN_2:
    "15.1 Mae’r Telerau Defnyddio hyn, eu cynnwys a'u ffurfiant, yn cael eu llywodraethu gan gyfraith Lloegr. Bydd gan lysoedd Cymru a Lloegr awdurdodaeth nad yw'n unigryw.",
  TERMS_GEN_3:
    "15.2 Os gwelir bod unrhyw ddarpariaeth yn y Telerau Defnyddio hyn yn anghyfreithlon neu'n annilys, neu am unrhyw reswm yn anorfodadwy, yna tybir y gellir torri’r ddarpariaeth honno o’r Telerau Defnyddio hyn, ac ni fydd yn effeithio ar ddilysrwydd a gorfodadwyedd unrhyw ddarpariaethau sy'n weddill.",
  TERMS_GEN_4:
    "15.3 Os nad ydym yn gallu mynnu eich bod chi’n cyflawni unrhyw rai o'ch rhwymedigaethau o dan y Telerau Defnyddio hyn, neu os na fyddwn yn gorfodi ein hawliau yn eich erbyn, neu os byddwn yn oedi cyn gwneud hynny, ni fydd hynny'n golygu ein bod ni wedi hepgor ein hawliau yn eich erbyn, ac ni fydd yn golygu nad oes rhaid i chi gydymffurfio â'r rhwymedigaethau hynny. Os byddwn yn hepgor methiant gennych chi, dim ond yn ysgrifenedig y byddwn yn gwneud hynny, ac ni fydd hynny'n golygu y byddwn yn hepgor unrhyw ddiffyg yn awtomatig yn nes ymlaen.",
  TERMS_GEN_5:
    "15.4 Ysgrifennwyd y Telerau Defnyddio hyn yn Saesneg (DU). I'r graddau y mae unrhyw fersiwn a gyfieithwyd o'r Telerau Defnyddio hyn yn gwrthdaro â'r fersiwn Saesneg, mae'r fersiwn Saesneg yn drechaf.",
  TERMS_GEN_6:
    "15.5 Efallai y byddwn yn trosglwyddo ein hawliau a'n rhwymedigaethau o dan y Telerau Defnyddio hyn i sefydliad arall, ond ni fydd hyn yn effeithio ar eich hawliau na'n rhwymedigaethau o dan y Telerau Defnyddio hyn. Dim ond os byddwn yn cytuno yn ysgrifenedig y cewch chi drosglwyddo eich hawliau neu eich rhwymedigaethau o dan y Telerau Defnyddio hyn i berson arall.",

  TERMS_COMM_1: "16. Cyfathrebu rhyngddom ni",
  TERMS_COMM_2:
    "16.1 Os hoffech gysylltu â ni yn ysgrifenedig, neu os oes unrhyw amod yn y Telerau Defnyddio hyn yn ei gwneud yn ofynnol i chi roi rhybudd ysgrifenedig i ni, gallwch ei anfon atom drwy e-bost neu drwy'r post rhagdaledig i Carers UK Trading Limited yn 20 Great Dover Street, Llundain SE1 4LX, neu drwy e-bost i jointlyappsupport@carersuk.org. Byddwn yn cadarnhau ein bod ni wedi derbyn hwn drwy gysylltu â chi yn ysgrifenedig, fel arfer drwy e-bost.",
  TERMS_COMM_3:
    "16.2 Os oes rhaid i ni gysylltu â chi neu roi rhybudd i chi yn ysgrifenedig, byddwn yn gwneud hyn drwy e-bost neu drwy’r post rhagdaledig i’r cyfeiriad rydych yn ei roi i ni.",
  TERMS_PRIVACY_1: "Polisi Preifatrwydd",
  TERMS_PRIVACY_2:
    "Mae Carers UK Trading Ltd yn cydnabod bod ei gwsmeriaid, ei ymwelwyr a'i ddefnyddwyr eisiau gwybod beth yw’r sefyllfa o ran preifatrwydd. Mae’r Polisi Preifatrwydd hwn wedi’i gynnwys yn, ac yn amodol ar, Delerau Defnyddio’r Gwasanaeth Jointly. Mae eich defnydd o'r Gwasanaeth Jointly (gan gynnwys yr Ap a'r Wefan) ac unrhyw wybodaeth bersonol rydych yn ei ddarparu ar y Gwasanaeth Jointly, yn parhau i fod yn ddarostyngedig i delerau'r Polisi Preifatrwydd hwn a'n Telerau Defnyddio.",
  TERMS_PRIVACY_3: "Gwybodaeth a Ddarperir gan y Defnyddiwr:",
  TERMS_PRIVACY_4:
    "Gwybodaeth Bersonol Adnabyddadwy a gwybodaeth arall (gan gynnwys Data Cleifion) sydd yn cael ei darparu neu ei llwytho i fyny gennych chi, fel cyfeiriadau e-bost, rhifau ffôn symudol, enwau hysbysiadau gwthio (os yn berthnasol), a gwybodaeth am ddyfais symudol, mewn cysylltiad â'ch defnydd o'r Gwasanaeth Jointly.",
  TERMS_PRIVACY_5: "Data Cleifion:",
  TERMS_PRIVACY_6:
    "Gwybodaeth Bersonol Adnabyddadwy am gleifion o dan eich gofal sydd yn cael ei darparu neu ei llwytho i fyny gennych i'w defnyddio gyda'r Gwasanaeth Jointly a'r Ap.",
  TERMS_PRIVACY_7: "Gwybodaeth Bersonol Adnabyddadwy:",
  TERMS_PRIVACY_8:
    " unrhyw wybodaeth sy'n ymwneud â pherson, ac yn ei adnabod gan gynnwys ond heb fod yn gyfyngedig i enw a chyfeiriad, cyfeiriad e-bost, oedran, rhyw, buddiannau personol, dyddiad geni a galwedigaeth.",
  TERMS_PRIVACY_9: "Gwybodaeth Cwcis:",
  TERMS_PRIVACY_10:
    " Pan fyddwch yn mynd i’r Wefan, efallai y byddwn yn anfon un neu fwy o gwcis-ffeil testun bach sy'n cynnwys llinyn o gymeriadau alffaniwmerig - i'ch cyfrifiadur chi sy'n adnabod eich porwr chi yn unig.",
  TERMS_PRIVACY_11: "Gwybodaeth ffeiliau cofnodi:",
  TERMS_PRIVACY_12:
    " pan fyddwch chi'n defnyddio'r Wefan, mae ein gweinyddion yn cofnodi gwybodaeth benodol mae eich porwr gwe yn ei anfon pryd bynnag y byddwch yn ymweld ag unrhyw wefan. Efallai y bydd y cofnodion hyn yn cynnwys gwybodaeth fel eich cais ar y we, cyfeiriad Protocol Rhyngrwyd ( '",
  TERMS_PRIVACY_13: "IP",
  TERMS_PRIVACY_14:
    "'), math o borwr, iaith y porwr, tudalennau cyfeirio/gadael ac URLs, math o lwyfan, nifer y cliciau, enwau parth, tudalennau glanio, tudalennau yr edrychwyd arnynt a threfn y tudalennau hynny neu faint o amser sy'n cael ei dreulio ar dudalennau penodol.",
  TERMS_PRIVACY_15: "Diogelwch",
  TERMS_PRIVACY_16:
    "Rydym yn ceisio sicrhau bod yr holl wybodaeth a ddarperir gan y defnyddiwr yn cael ei chadw'n gyfrinachol, ac yn cymryd camau rhesymol i ddiogelu gwybodaeth o'r fath.",
  TERMS_PRIVACY_17:
    "Fodd bynnag, ni allwn warantu diogelwch y Wybodaeth a Ddarperir gan y Defnyddiwr oherwydd gallai gael ei chyfaddawdu gan gyrchu anawdurdodedig, methiant caledwedd neu feddalwedd neu ffactorau perthnasol eraill.",
  TERMS_PRIVACY_18:
    "Ni allwn warantu cyfrinachedd unrhyw Gynnwys Defnyddwyr neu Wybodaeth a Ddarperir gan y Defnyddiwr a drosglwyddir rhyngoch chi, ni a defnyddwyr eraill y Gwasanaeth Jointly neu aelodau o'r cyhoedd, yn ôl yr achos. Ni fyddwn yn atebol i chi nac i unrhyw un arall am unrhyw golled mewn cysylltiad ag unrhyw Gynnwys Defnyddwyr neu wybodaeth a ddarperir gan y defnyddiwr a drosglwyddir drwy ddefnyddio'r Gwasanaeth Jointly neu mewn cysylltiad ag ef.",
  TERMS_PRIVACY_19: "Defnyddio gwybodaeth",
  TERMS_PRIVACY_20:
    "Os byddwch yn cyflwyno Gwybodaeth Bersonol Adnabyddadwy i ni drwy'r Gwasanaeth Jointly, yna rydym yn defnyddio eich gwybodaeth bersonol i weithredu, cynnal a darparu nodweddion a swyddogaethau'r Gwasanaeth Jointly i chi. Yn benodol, mae eich cyfeiriad e-bost yn hanfodol i'ch defnydd o'r Gwasanaeth Jointly, a bydd yn cael ei gadw. Efallai y byddwn yn defnyddio eich cyfeiriad e-bost (a/neu rif ffôn symudol os caiff ei ddarparu) heb ganiatâd pellach at ddibenion nad ydynt yn rhai marchnata neu weinyddol (fel rhoi gwybod i chi am newidiadau mawr i’r Gwasanaeth Jointly neu at ddibenion cwsmeriaid y Gwasanaeth Jointly.",
  TERMS_PRIVACY_21:
    "Efallai y byddwn yn defnyddio cwcis a gwybodaeth am ffeiliau cofnodi i: (a) gofio gwybodaeth fel na fydd yn rhaid i chi ei haildeipio yn ystod eich ymweliad neu'r tro nesaf y byddwch yn defnyddio'r Gwasanaeth Jointly a (b) i fonitro metrigau unigol a chyfanredol megis cyfanswm nifer yr ymwelwyr, tudalennau a welwyd, ac ati.",
  TERMS_PRIVACY_22:
    "Nid ydym yn gwerthu nac yn rhannu eich Gwybodaeth Bersonol Adnabyddadwy (fel cyfeiriad e-bost neu rif ffôn symudol) gyda chwmnïau trydydd parti eraill ar gyfer eu defnydd masnachol neu farchnata. Efallai y byddwn yn rhannu eich Gwybodaeth Bersonol Adnabyddadwy gyda darparwyr gwasanaethau trydydd parti i'r graddau ei bod yn rhesymol angenrheidiol i berfformio, gwella neu gynnal y Gwasanaeth Jointly.",
  TERMS_PRIVACY_23:
    "Os nad ydych yn cytuno â'n Polisi Preifatrwydd neu Delerau Defnyddio, rhowch y gorau i ddefnyddio'r Gwasanaeth Jointly; bydd eich defnydd parhaus o'r Gwasanaeth Jointly yn arwydd eich bod chi’n cydsynio i, ac yn derbyn ein Polisi Preifatrwydd a'n Telerau Defnyddio.",
  TERMS_PRIVACY_24:
    "Gellir diwygio'r Polisi Preifatrwydd hwn o bryd i'w gilydd, a chaiff hyn ei adlewyrchu gan y dyddiad effeithiol sydd ar dop y Telerau Defnyddio hyn. Edrychwch eto ar y dudalen hon i aros yn ymwybodol o unrhyw newidiadau. Mae eich defnydd parhaus o Wefan Jointly a'r Gwasanaeth Jointly yn dango eich bod yn cytuno â’r Polisi Preifatrwydd hwn ac unrhyw ddiwygiadau.",
  TERMS_PRIVACY_25: "Carers UK Trading Limited",
  TERMS_PRIVACY_26: "Cwmni wedi’i gofrestru yn Lloegr o dan y rhif 6034910",
  TERMS_PRIVACY_27: "Cwmni cofrestredig: 20 Great Dover Street, London SE1 4LX",

  USER_GUIDE_H1: "Croeso i Jointly!",
  USER_GUIDE_Q1: "Sut mae Jointly’n gweithio?",
  USER_GUIDE_1A1:
    "Gyda Jointly, gallwch greu cylch gofal ar gyfer y person rydych chi’n gofalu amdano. Ar ôl i chi greu eich cylch gofal, gallwch ddechrau defnyddio Jointly i drefnu gofal. Gallwch ei ddefnyddio ar eich pen eich hun, neu gallwch wahodd pobl eraill i ymuno â chi a rhannu'r gofal.",
  USER_GUIDE_1A2:
    "Gallwch greu proffil gyda gwybodaeth ddefnyddiol am y person rydych chi’n gofalu amdano; gallwch bostio negeseuon, creu rhestrau tasgau a meddyginiaethau, a storio cysylltiadau defnyddiol.",
  USER_GUIDE_1A3:
    "Gall pob aelod o'ch cylch Jointly weld y cynnwys sydd yn cael ei bostio ar Jointly, felly gwnewch yn siŵr eich bod chi’n ystyried hyn wrth bostio. Os oes gennych chi wybodaeth a dim ond eisiau i rai aelodau o'r cylch ei gweld, gallwch ei rhoi mewn ",
  USER_GUIDE_1A4: "categori preifat.",
  USER_GUIDE_1A5:
    "Er bod y rhan fwyaf o'r cynnwys ar Jointly yn gallu cael ei olygu gan yr holl aelodau, sylwch mai gweinyddwr ",
  USER_GUIDE_1A6: "cylch yn unig ",
  USER_GUIDE_1A7: " sydd yn gallu golygu'r ",
  USER_GUIDE_1A8: "dudalenMeddyginiaethau",
  USER_GUIDE_1A9:
    ". Gall gweinyddwr y cylch roi (neu wrthod) caniatâd i aelodau eraill olygu’r dudalen hon, neu weld nodiadau yn y categori preifat. Darllenwch fwy am ",
  USER_GUIDE_1A10: "Caniatâd",
  USER_GUIDE_1A11:
    "Aelodau o'r cylch ydy pobl sydd wedi derbyn gwahoddiad i ymuno â'ch cylch Jointly (wedi’i anfon gennych chi neu aelod arall o'ch cylch).",
  USER_GUIDE_Q2: "Sut ydw i’n cofrestru i Jointly?",
  USER_GUIDE_2A1: "Ewch i ",
  USER_GUIDE_2A2:
    " neu llwythwch yr ap Jointly i lawr ar eich iPhone, iPad, neu ddyfais Android. Cliciwch ar y ddolen ",
  USER_GUIDE_2A3: "Cofrestru",
  USER_GUIDE_2A4: "ar y sgrîn hafan.",
  USER_GUIDE_2A5:
    "Llenwch y ffurflen drwy ychwanegu eich manylion a dewis cyfrinair, a chliciwch ar y botwm ",
  USER_GUIDE_2A6: "Cofrestru.",
  USER_GUIDE_2A7:
    "Bydd y manylion rydych chi'n eu darparu (ac eithrio eich cyfrinair) yn cael eu storio ar dudalen ",
  USER_GUIDE_2A8: "Cysylltiadau",
  USER_GUIDE_2A9:
    " eich cylch, a bydd aelodau eraill o'ch cylch yn gallu eu gweld. Gallwch olygu eich manylion cyswllt unrhyw bryd ( ",
  USER_GUIDE_2A10: "Amdanoch chi ",
  USER_GUIDE_2A11: "Golygu Cyfrif",
  USER_GUIDE_Q3: "Mewngofnodi a Chreu eich cylch Jointly",
  USER_GUIDE_3A1:
    "Ar ôl i chi greu cyfrif, gallwch fewngofnodi i Jointly. Nawr, gallwch gael mynediad at ddewislen gyfyngedig, lle gallwch ddysgu mwy am Jointly neu newid manylion eich cyfrif.",
  USER_GUIDE_3A2:
    "Mae Jointly yn gweithio gyda chylchoedd gofal. Pan fyddwch yn prynu ac yn creu eich cylch Jointly, gallwch wahodd cymaint o bobl ag y dymunwch i rannu'r gofal gyda chi. Fel arall, gallwch ddefnyddio Jointly ar eich pen eich hun i ofalu am rywun. Os byddwch yn gwneud hyn, byddwch yn dal i orfod prynu cylch Jointly.",
  USER_GUIDE_3A3: "Cliciwch  ",
  USER_GUIDE_3A4: "+ Crëwch gylch newydd ",
  USER_GUIDE_3A5:
    "ar y ddewislen i brynu cylch Jointly (taliad unwaith yn unig o £2.99). Llenwch eich manylion ar y ffurflen sy’n dilyn a chlicio ar ",
  USER_GUIDE_3A6: "Prynu",
  USER_GUIDE_3A7:
    ". Ar ôl i chi brynu eich cylch Jointly, gallwch ddechrau defnyddio Jointly drwy lenwi rhywfaint o wybodaeth ddefnyddiol am y person rydych chi’n gofalu amdano. Gallwch bob amser olygu'r wybodaeth hon yn nes ymlaen o'r ",
  USER_GUIDE_3A8: "Proffil",
  USER_GUIDE_3A9: "Fel arall, gallwch glicio ar ",
  USER_GUIDE_3A10: "Creu demo gylch;",
  USER_GUIDE_3A11:
    ", bydd hyn yn caniatáu i chi roi cynnig ar Jointly am 14 diwrnod, a'i brynu’n ddiweddarach am £2.99. Bydd eich gwybodaeth yn hygyrch, ond bydd angen i chi brynu trwydded er mwyn mynd i mewn i unrhyw wybodaeth newydd ar ôl y cyfnod demo.",
  USER_GUIDE_Q4: "Cyfnewid y Cod Mynediad am Ddim",
  USER_GUIDE_4A1:
    "Os ydych chi wedi derbyn Cod Mynediad am Ddim gan eich cyflogwr neu ddarparwr gwasanaeth, gallwch ei ddefnyddio i greu eich cylch Jointly am ddim. Bydd angen i chi ei gyfnewid ar-lein, a gallwch wneud hyn drwy ddefnyddio’r ap Jointly ar eich iPhone, iPad neu ddyfais Android, neu drwy fynd i ",
  USER_GUIDE_4A2:
    "Mewngofnodwch i Jointly (os nad oes gennych chi gyfrif, bydd angen i chi Gofrestru i ddechrau). Ar ôl mewngofnodi, cliciwch ar ‘Creu cylch newydd’ ar y ddewislen.",
  USER_GUIDE_4A3:
    "Dewiswch ‘Defnyddio’r Cod Mynediad am Ddim’ o’r opsiynau prynu",
  USER_GUIDE_4A4:
    "Rhowch rywfaint o wybodaeth ddefnyddiol am y person rydych chi’n gofalu amdano",
  USER_GUIDE_4A5:
    "Ar y dudalen nesaf, rhowch eich cod yn y bocs sy’n ymddangos. ",
  USER_GUIDE_Q5: "Hafan",
  USER_GUIDE_5A1:
    "Dyma eich tudalen Hafan.  Ar y dudalen hon, gallwch wneud y canlynol:",
  USER_GUIDE_5A2: "Gweld y tasgau a’r digwyddiadau sydd i ddod",
  USER_GUIDE_5A3: "Gweld cofnod o weithgarwch diweddar ",
  USER_GUIDE_5A4: "Gwahodd pobl i mewn i’ch cylch",
  USER_GUIDE_5A5: "Gweld pwy arall sydd yn eich cylch",
  USER_GUIDE_5A6: "Gweld eich gwahoddiadau sydd i ddod ",
  USER_GUIDE_5A7:
    "(a dileu unrhyw wahoddiadau sydd i ddod dydych chi ddim eisiau os ydych chi’n weinyddwr) ",
  USER_GUIDE_Q6: "Tasgau a digwyddiadau sydd i ddod ",
  USER_GUIDE_6A1:
    "Yma, gallwch weld y pum digwyddiad neu dasg nesaf sydd â dyddiad dyledus wedi’i neilltuo iddyn nhw.  Cliciwch ar eitem i’w weld neu ei olygu, neu ewch i’r adrannau ",
  USER_GUIDE_6A2: "Calendr",
  USER_GUIDE_6A3: "/",
  USER_GUIDE_6A4: "Tasgau",
  USER_GUIDE_6A5: " i weld rhagor o eitemau.",
  USER_GUIDE_Q7: "Gweld cofnod o weithgarwch diweddar",
  USER_GUIDE_7A1: "O’r ddewislen, ewch i’r dudalen ",
  USER_GUIDE_7A2: "Hafan",
  USER_GUIDE_7A3:
    " Ar ddiwedd y dudalen, mae rhestr o ddigwyddiadau diweddar yn eich cylch Jointly.",
  USER_GUIDE_Q8: "Gwahodd pobl i ymuno â’ch cylch",
  USER_GUIDE_8A1:
    "Os ydych chi’n weinyddwr cylch, gallwch wahodd eraill i ymuno â'ch cylch. Mae hyn yn golygu y byddant yn gallu gweld, ychwanegu at, a golygu unrhyw wybodaeth sydd wedi'i storio yn eich cylch Jointly.",
  USER_GUIDE_8A2:
    "Oherwydd y gellir defnyddio Jointly i storio gwybodaeth sensitif am y person rydych chi’n gofalu amdano, rydym yn argymell eich bod chi’n diogelu gwybodaeth breifat a sensitif, ac yn ystyried yn ofalus p’un a ydych chi’n mynd i roi mynediad i rywun i’ch cylch. Mae holl ddefnyddwyr Jointly yn gyfrifol am fonitro'r wybodaeth sy'n cael ei storio yn y cylch, p’un a ydy hynny'n cael ei gofnodi neu ei olygu gennych chi neu gan drydydd partïon eraill.",
  USER_GUIDE_8A3:
    "I wahodd rhywun i ymuno â'r cylch, o’r ddewislen, ewch i’r dudalen ",
  USER_GUIDE_8A4: "Hafan",
  USER_GUIDE_8A5: " Cliciwch ar y ",
  USER_GUIDE_8A6:
    "botwm Gwahodd pobl yn y cylch hwn ar dop y dudalen. Cwblhewch fanylion y person rydych chi eisiau ei wahodd ar y dudalen sy'n dilyn (bydd angen i chi wybod cyfeiriad e-bost y person hwnnw). Byddant yn derbyn e-bost yn eu gwahodd i ymuno â chi ar Jointly. Gallwch bersonoli neges yr e-bost yn y bocs perthnasol, neu gallwch ddefnyddio'r testun a ddarperir. Pan fyddwch chi’n hapus gyda’r manylion a ddarparwyd gennych, cliciwch ar y botwm",
  USER_GUIDE_8A7: "Gwahodd",
  USER_GUIDE_8A8:
    " (dylech dicio ddwywaith eich bod chi wedi teipio'r cyfeiriad e-bost yn gywir). Nes y bydd y person yn derbyn eich gwahoddiad, byddwch yn gallu gweld ei statws yn eich ",
  USER_GUIDE_8A9: "gwahoddiadau i ddod",
  USER_GUIDE_8A10: "Ni fydd y person rydych yn ei wahodd yn gallu gwahodd ",
  USER_GUIDE_8A11: "aelodau ychwanegol",
  USER_GUIDE_8A12:
    "oni bai eich bod chi’n eu dewis fel gweinyddwr cylch, naill ai wrth eu gwahodd neu mewn ",
  USER_GUIDE_8A13: "gosodiadau cylch",
  USER_GUIDE_Q9: "Dileu gwahoddiad sydd i ddod",
  USER_GUIDE_9A1: "Bydd angen i chi fod yn weinyddwr ",
  USER_GUIDE_9A2: "cylch i ddileu gwahoddiad",
  USER_GUIDE_9A3:
    " sydd i ddod. Os nad ydych chi’n weinyddwr cylch, gofynnwch i'ch gweinyddwr (wyr) i ddileu'r gwahoddiad.",
  USER_GUIDE_Q10:
    "Rwyf wedi gwahodd rhywun, ond nid ydynt wedi derbyn fy ngwahoddiad",
  USER_GUIDE_10A1:
    "Gwnewch yn siŵr eich bod chi wedi rhoi’r wybodaeth gywir ar y ffurflen wahoddiad.  Gallwch weld hyn yn eich ",
  USER_GUIDE_10A2: "gwahoddiadau sydd i ddod",
  USER_GUIDE_10A3:
    "Os na fyddant yn derbyn y gwahoddiad hwn, gofynnwch iddynt wirio eu ffolder sbam neu swmp neu eu gosodiadau. Gallwch ail-anfon neu ddileu gwahoddiadau drwy ddewis yr eiconau ar ochr dde pob gwahoddiad.",
  USER_GUIDE_10A4: "Os ydy’r person yn cofrestru cyfrif newydd yn ",
  USER_GUIDE_10A5:
    " yn defnyddio'r cyfeiriad e-bost y gwnaethoch ddanfon y gwahoddiad iddo, yna byddant yn gallu derbyn y gwahoddiad o’u ffenestr hysbysiadau sydd ar dop ochr dde'r sgrin y tro cyntaf y byddant yn mewngofnodi (does dim angen iddynt ei wneud drwy'r neges wahoddiad)",
  USER_GUIDE_10A6: "Os ydych chi’n cael rhagor o broblemau, cysylltwch â ",
  USER_GUIDE_Q11: "Gweld pwy sydd yn eich cylch",
  USER_GUIDE_11A1: "Gallwch weld aelodau o’ch cylch yn ",
  USER_GUIDE_11A2: "Cysylltiadau",
  USER_GUIDE_11A3: " neu drwy fynd i ",
  USER_GUIDE_11A4: "Gosodiadau cylch",
  USER_GUIDE_11A5: "Gosodiadau cylch neu dileu eich cylch",
  USER_GUIDE_11A5A: "categories that have been marked private)",
  USER_GUIDE_11A6:
    "Rhowch ganiatâd i aelodau eraill o’r cylch i weld eitemau preifat - command? (Categorïau Nodyn / Dyfais sydd wedi cael eu nodi fel preifat).",
  USER_GUIDE_Q12: "Proffil",
  USER_GUIDE_Q12A:
    "Nodwch y gall pob aelod o'ch cylch weld yr wybodaeth hon, ond dim ond gweinyddwyr cylch sydd yn gallu ei diweddaru. Defnyddiwch eich synnwyr cyffredin ynglŷn â pha ddata y gallwch ei rannu gydag aelodau o’ch cylch.",
  USER_GUIDE_Q13: "Negeseuon",
  USER_GUIDE_13A1: "O’r ddewislen, ewch i ",
  USER_GUIDE_13A2: "Negeseuon",
  USER_GUIDE_13A3:
    " Yn debyg i neges destun, ysgrifennwch eich neges yn y bocs ar waelod y dudalen a gwasgwch ",
  USER_GUIDE_13A4: "Anfon",
  USER_GUIDE_13A5:
    " i'w phostio. Bydd pob aelod arall o'ch cylch Jointly yn gallu gweld y negeseuon rydych chi’n eu postio. Gallwch bostio lluniau hefyd drwy glicio ",
  USER_GUIDE_13A6: "Uwchlwytho llun",
  USER_GUIDE_13A7:
    ". Tynnwch lun neu atodwch un o'ch lluniau presennol. Unwaith rydych chi’n dewis llun, pwyswch ",
  USER_GUIDE_13A8: "Anfon",
  USER_GUIDE_13A9:
    "Gallwch weld yr holl negeseuon sydd wedi cael eu postio gan aelodau’ch cylch Jointly drwy sgrolio fyny (mwyaf newydd) neu lawr (hynaf). Gallwch weld pwy sydd wedi postio beth a phryd drwy edrych ar y tag enw ar dop y negeseuon.",
  USER_GUIDE_13A10:
    "Ni ellir golygu negeseuon ar ôl iddynt gael eu hanfon, ond gellir eu dileu o fan hyn trwy glicio ar yr eicon bin. Pan fydd negeseuon yn cael eu dileu, mae dalfan yn aros yn y ffrwd negeseuon i ddangos bod rhywbeth wedi cael ei ddileu.",
  USER_GUIDE_Q14: "Nodiadau",
  USER_GUIDE_14A1:
    "O’r ddewislen, ewch i Nodiadau. Defnyddiwch yr adran hon i greu a threfnu nodiadau neu i storio lluniau.  Mae'r adran Nodiadau yn ddefnyddiol ar gyfer creu nodiadau testun hirach neu wybodaeth yr hoffech ei chategoreiddio, fel ei bod yn hawdd dod o hyd iddynt yn ddiweddarach (e.e. i greu cofnod iechyd neu i storio biliau i'w talu).",
  USER_GUIDE_14A2:
    "Pan fyddwch yn creu nodyn newydd, gallwch glicio ar y gwymplen Categori i'w neilltuo i gategori presennol neu i greu categori newydd. Gallwch greu categorïau newydd ymlaen llaw hefyd trwy ddewis Golygu Categorïau.",
  USER_GUIDE_14A3:
    "Yn yr adran Nodiadau, gallwch glicio ar y botwm hidlo ar y brig ar y dde (eicon hidlydd) i weld dim ond y nodiadau sydd wedi cael eu neilltuo i gategori penodol, neu gallwch ddewis eiconau amryfal gategorïau, a gall hyn fod yn ddefnyddiol i gymharu gwahanol fathau o wybodaeth. I weld y rhestr lawn o nodiadau eto, cliciwch ar yr eicon dileu categori (hidlydd gydag x arno)",
  USER_GUIDE_14A4: "Nodiadau yn y categori preifat",
  USER_GUIDE_14A5:
    "I guddio'r nodiadau hyn rhag rhai aelodau o'r cylch, ewch i Gosodiadau Cylch a dad-dicio’r blwch gallu gweld nodiadau preifat.",
  USER_GUIDE_14A6:
    "Cofiwch y gall gweinyddwyr cylch weld pob nodyn preifat a dewis pa aelodau eraill o'r cylch sydd yn gallu eu gweld.",
  USER_GUIDE_Q15: "Tasgau a rhestrau tasgau",
  USER_GUIDE_15A1:
    "Gallwch greu tasgau a rhestrau tasgau, a monitro eu statws.",
  USER_GUIDE_15A2: "O’r ddewislen, ewch i ",
  USER_GUIDE_15A3: "Tasgau",
  USER_GUIDE_15A4:
    ". Ar y dudalen hon, gallwch weld crynodeb o'r holl dasgau. Maen nhw'n cael eu rhannu mewn i Tasgau (ar gyfer tasgau annibynnol, er enghraifft: ",
  USER_GUIDE_15A5: "Nôl meddyginiaeth mam",
  USER_GUIDE_15A6:
    ") a Rhestrau tasgau (ar gyfer tasgau sy'n perthyn i restr, er enghraifft: ",
  USER_GUIDE_15A7: "Rhestr fwyd wythnosol mam –",
  USER_GUIDE_15A8: "lle gallwch storio mwy o eitemau ynddo fel ",
  USER_GUIDE_15A9: "Prynu llaeth, bwyd ci ac ati.",
  USER_GUIDE_15A10: "Creu tasg neu rhestr dasgau",
  USER_GUIDE_15A11: "O’r ddewislen, ewch i ",
  USER_GUIDE_15A12: "Tasgau",
  USER_GUIDE_15A13: ". Cliciwch ar y botwm ",
  USER_GUIDE_15A14: "Creu tasg newydd",
  USER_GUIDE_15A15: ". Rhowch deitl i’ch tasg (fel Nôl ",
  USER_GUIDE_15A16: "meddyginiaeth Mam",
  USER_GUIDE_15A17: "Gallwch neilltuo’r dasg i aelod o'ch cylch Jointly, gan ",
  USER_GUIDE_15A18: "gynnwys",
  USER_GUIDE_15A19:
    " chi eich hun, neu ei gadael fel Heb ei neilltuo i rywun arall ei hagor.",
  USER_GUIDE_15A20:
    "Gosodwch ddyddiad os oes angen, bydd tasgau gyda dyddiadau dyledus yn ymddangos ar y calendr ar y diwrnod hwnnw",
  USER_GUIDE_15A21:
    "Os ydy eich tasg yn rhan o restr (er enghraifft Rhestr fwyd wythnosol Mam) gallwch",
  USER_GUIDE_15A22:
    "Ei hychwanegu at restr bresennol drwy ddewis yr un berthnasol",
  USER_GUIDE_15A23:
    "Ei hychwanegu i restr newydd drwy deipio teitl eich rhestr yn y bocs a ddarperir.",
  USER_GUIDE_15A24: "Cliciwch ar ",
  USER_GUIDE_15A25: "Arbed",
  USER_GUIDE_15A26: "*Nodyn:",
  USER_GUIDE_15A27: " Os yw eich tasg yn amser benodol (fel Gyrru ",
  USER_GUIDE_15A28: "mam i’r meddyg ar ddydd Mawrth 5 Tachwedd am 10 am",
  USER_GUIDE_15A29: "), rydym yn argymell eich bod chi’n creu cofnod ",
  USER_GUIDE_15A30: "calendr",
  USER_GUIDE_15A31: "yn hytrach na thasg.",
  USER_GUIDE_15A32: "Golygu tasgau",
  USER_GUIDE_15A33: "Ewch i’r tab ",
  USER_GUIDE_15A34: "Tasgau",
  USER_GUIDE_15A35: " ar y ddewislen.",
  USER_GUIDE_15A36:
    "Mae gan bob tasg eicon tic (ar yr ochr chwith) i ddangos a yw’r dasg wedi cael ei chwblhau neu beidio.",
  USER_GUIDE_15A37:
    "Ar ochr dde teitl y dasg, mae’r enw’n dangos i ba aelod o’r cylch mae’r dasg wedi cael ei neilltuo iddo.",
  USER_GUIDE_15A38: "Cliciwch ar dasg i weld rhagor o opsiynau.",
  USER_GUIDE_15A39: "Cliciwch ar ",
  USER_GUIDE_15A40: "Cwblhau’r dasg hon",
  USER_GUIDE_15A41:
    " i nodi bod tasg wedi ei chwblhau.  Gallwch barhau i weld y dasg gyda ",
  USER_GUIDE_15A42: "llinelldrwodd",
  USER_GUIDE_15A43:
    " ar y dudalen Tasgau i ddangos ei bod wedi cael ei chwblhau.  Cliciwch ar ",
  USER_GUIDE_15A44: "Cwblhau’r",
  USER_GUIDE_15A45: " dasg hon i farcio tasg fel bod wedi ei chwblhau.",
  USER_GUIDE_15A46: "Os ydych chi eisiau dileu’r dasg yn llwyr, cliciwch ar ",
  USER_GUIDE_15A47: "Dileu",
  USER_GUIDE_15A48: "Cliciwch ar ",
  USER_GUIDE_15A49: "Golygu",
  USER_GUIDE_15A50:
    " i wneud newidiadau i’r dasg (gallwch newid teitl y dasg, pwy sy’n gyfrifol amdani neu ei hychwanegu at restr). Cliciwch ar ",
  USER_GUIDE_15A51: "Arbed",
  USER_GUIDE_15A52: " pan rydych chi’n gwneud newidiadau.",
  USER_GUIDE_15A53:
    "Gallwch bostio sylwad sy’n ymwneud â thasg benodol drwy deipio eich neges yn y bocs a ddarperir.  Cliciwch ar ",
  USER_GUIDE_15A54: "Anfon",
  USER_GUIDE_15A55: " i anfon eich sylwad.",
  USER_GUIDE_15A56: "Golygu Rhestrau Tasgau",
  USER_GUIDE_15A57:
    "Mae dau rif mewn cylch (ar yr ochr dde) i bob rhestr dasgau.  Mae'r cylch ",
  USER_GUIDE_15A58: "llwyd",
  USER_GUIDE_15A59:
    " yn nodi nifer yr eitemau sydd wedi'u cwblhau yn y rhestr, ac mae'r cylch ",
  USER_GUIDE_15A60: "du",
  USER_GUIDE_15A61:
    " yn nodi nifer yr eitemau agored/sydd i ddod/i'w gwneud yn y rhestr.",
  USER_GUIDE_15A62:
    "Cliciwch ar y tab Teitl rhestr dasgau am ragor o opsiynau.Gallwch wneud y canlynol ",
  USER_GUIDE_15A63: "Ychwanegu tasg newydd",
  USER_GUIDE_15A64:
    "Clicio ar dasg presennol i’w golygu (golygu gwybodaeth tasg, marcio tasg fel bod wedi’i chwblhau, newid i bwy mae’r dasg wedi’i haseinio iddi, dileu tasu neu bostio sylw).",
  USER_GUIDE_15A65: "Aildrefnu tasgau yn ôl eu blaenoriaeth",
  USER_GUIDE_15A66: "Cofiwch glicio ar ",
  USER_GUIDE_15A67: "Arbed",
  USER_GUIDE_15A68: " pan fyddwch chi’n ychwanegu neu’n golygu cofnodion.",
  USER_GUIDE_Q16: "Calendr",
  USER_GUIDE_16A1:
    "Edrychwch ar ddigwyddiadau yn ôl mis calendr (rhestr Mis). Mae’r eitemau wedi’u codio yn ôl lliw ar yr ap ffôn symudol.",
  USER_GUIDE_16A2: "Mae rhif glas yn dangos dyddiad heddiw",
  USER_GUIDE_16A3:
    "Mae cylch glas mawr yn dangos y dyddiad sydd wedi’i ddewis ar hyn o bryd.  Bydd y rhestr isod yn dangos yr holl ddigwyddiadau sy’n digwydd ar ôl y dyddiad hwn.",
  USER_GUIDE_16A4:
    "Mae dot glas yn bod rhywun wedi’u marcio fel dim ar gael ar y dyddiad hwnnw ",
  USER_GUIDE_16A5:
    "Mae’r llinell las o dan y golwg wythnos llorwedd yn ehangu’r calendr hwn i lenwi’r sgrin gyfan",
  USER_GUIDE_16A6: "Mae’r llinell ",
  USER_GUIDE_16A7: "las o dan y",
  USER_GUIDE_16A8:
    " golwg wythnos llorwedd yn ehangu’r calendr hwn i lenwi’r sgrin gyfan",
  USER_GUIDE_16A9: "Sut i greu digwyddiad",
  USER_GUIDE_16A10: "Cliciwch ar y botwm ",
  USER_GUIDE_16A11: "Creu digwyddiad newydd",
  USER_GUIDE_16A12: " ar y dudalen Calendr.",
  USER_GUIDE_16A13: "Rhowch deitl i’ch digwyddiad (e.e. Apwyntiad meddyg)",
  USER_GUIDE_16A14:
    "Rhowch leoliad y digwyddiad (e.e. Long Lane Surgery, 20 Long Lane, Llundain)",
  USER_GUIDE_16A15: "Dewiswch ddyddiad eich digwyddiad",
  USER_GUIDE_16A16: "Dewiswch amser dechrau a gorffen eich digwyddiad",
  USER_GUIDE_16A17:
    "Dewiswch pa aelod o'ch cylch yr hoffech ei wahodd i fynychu (ticiwch ar bob person rydych chi eisiau iddynt dderbyn gwahoddiad drwy e-bost i'r digwyddiad hwn)",
  USER_GUIDE_16A18:
    "Gallwch wahodd pobl sydd ddim yn perthyn i chi i’ch cylch Jointly hefyd, drwy ychwanegu eu e-byst yn y blwch a ddarperir.",
  USER_GUIDE_16A19: "Cliciwch ar",
  USER_GUIDE_16A20: "Arbed",
  USER_GUIDE_16A21: " i greu eich digwyddiad.",
  USER_GUIDE_16A22: "Creu digwyddiadau sy’n ailddigwydd",
  USER_GUIDE_16A23:
    "Os oes gennych chi ddigwyddiad sy'n ailddigwydd bob dydd, wythnos neu fis, gallwch greu cyfres o ",
  USER_GUIDE_16A24: "ddigwyddiadau ",
  USER_GUIDE_16A25:
    " mewn un, trwy glicio ar y blwch ticio Ailddigwydd wrth greu'r digwyddiad. Bydd digwyddiadau sy'n ailddigwydd yn cael eu creu hyd at y dyddiad a roddwch yn ",
  USER_GUIDE_16A26: "Ailddigwydd Tan",
  USER_GUIDE_16A27:
    "Os byddwch yn trefnu digwyddiad misol i ailddigwydd ar ddiwrnod sydd ddim mewn mis penodol (e.e. ar y 31ain i'w ailadrodd ym mis Mehefin), bydd y digwyddiad yn cael ei gynnal ar ddiwrnod olaf y mis hwnnw yn lle hynny.",
  USER_GUIDE_16A28: "Dyblygu digwyddiadau",
  USER_GUIDE_16A29:
    "Gallwch wneud i ddigwyddiadau calendr presennol ailddigwydd, i greu digwyddiadau ychwanegol sy’n cynnwys gwybodaeth debyg.",
  USER_GUIDE_16A30: "Cliciwch ar ddigwyddiad ac yna, ar ",
  USER_GUIDE_16A31: "Dyblygu",
  USER_GUIDE_16A32:
    ".  Yn syml, newidiwch yr amser, dyddiad, ac ati, a chliciwch ar ",
  USER_GUIDE_16A33: "Arbed",
  USER_GUIDE_16A34:
    " i greu digwyddiad newydd.  Bydd y digwyddiad gwreiddiol yn aros yr un fath.",
  USER_GUIDE_16A35: "Sut i olygu/dileu digwyddiad",
  USER_GUIDE_16A36: "Ar y dudalen ",
  USER_GUIDE_16A37: "Calendr",
  USER_GUIDE_16A38:
    " (golwg Rhestr neu olwg Mis) cliciwch ar y digwyddiad rydych chi eisiau ei olygu",
  USER_GUIDE_16A39: "Cliciwch ar",
  USER_GUIDE_16A40: "Golygu",
  USER_GUIDE_16A41:
    "os ydych chi eisiau newid yr wybodaeth am y digwyddiad (fel lleoliad, dyddiad ac amser y digwyddiad neu’r bobl sydd wedi cael eu gwahodd i’r digwyddiad). Cofiwch ",
  USER_GUIDE_16A42: "Arbed",
  USER_GUIDE_16A43: " eich newidiadau.",
  USER_GUIDE_16A44: "Cliciwch ar ",
  USER_GUIDE_16A45: "Dileu",
  USER_GUIDE_16A46:
    " os ydych chi eisiau dileu’r digwyddiad o’ch calendr.  Ydy hyn yn anfon unrhyw fath o neges i’r rheiny sydd wedi derbyn y digwyddiad?",
  USER_GUIDE_16A47:
    "Gallwch bostio sylw sy’n ymwneud â’r digwyddiad hwn drwy ddefnyddio’r blwch a ddarperir",
  USER_GUIDE_16A48: "Golygu, dyblygu a ",
  USER_GUIDE_16A49: " dileu digwyddiadau sy’n ailddigwydd ",
  USER_GUIDE_16A50:
    "Pam rydych yn golygu neu’n dileu Digwyddiad sy’n ailddigwydd, byddwch yn cael yr opsiwn o ddileu’r digwyddiad ungiol hwnnw, neu bob digwyddiad sydd i ddod yn y gyfres.",
  USER_GUIDE_16A51:
    "Fel arall, gallwch ddileu’r holl gyfres a chreu un arall yn ei lle.",
  USER_GUIDE_Q17: "Cydamseru calendr",
  USER_GUIDE_17A1:
    "Ar rai dyfeisiau symudol, gallwch gydamseru eich calendr Google neu iCloud â'ch calendr Jointly. Mae’r nodwedd hon ar gael ar ddyfeisiau sydd â dim ond un calendr Google neu iCloud wedi'i osod arnynt yn unig.",
  USER_GUIDE_17A2:
    "Bydd newidiadau rydych chi'n eu gwneud i'r naill galendr neu'r llall yn cael eu diweddaru ar y llall, er y gallai fod oedi byr tra bod y calendrau'n cydamseru.",
  USER_GUIDE_17A3:
    "Gallwch alluogi hyn yn y gosodiadau cylch (tapiwch ar lun y cylch sydd ar dop y ddewislen), ewch i'r tab Aelodau a Gosodiadau a throwch 'Cydamseru calendr cylch’ ymlaen. Efallai y bydd yn cymryd sbel i'ch eitemau calendr gydamseru.",
  USER_GUIDE_17A4:
    "Gallwch analluogi cydamseru calendrau trwy droi hyn i ffwrdd, ond bydd eitemau o'ch calendr Jointly yn dal i aros yng nghwmwl eich calendr, a bydd angen eu dileu â llaw wedyn.",
  USER_GUIDE_Q18: "Meddyginiaethau",
  USER_GUIDE_18A1: "O’r ddewislen, ewch i’r tab ",
  USER_GUIDE_18A2: "Meddyginiaethau",
  USER_GUIDE_18A3: ". Ar y dudalen hon, gallwch wneud y canlynol:",
  USER_GUIDE_18A4: "Creu cofnod ar gyfer meddyginiaeth ( ",
  USER_GUIDE_18A5:
    "os ydych chi’n weinyddwr cylch neu wedi cael caniatâd perthnasol",
  USER_GUIDE_18A6:
    "Gweld meddyginiaeth heddiw, a chael syniad o’r amser y dylid ei chymryd yn ogystal â’r ddos ( ",
  USER_GUIDE_18A7: "Golwg",
  USER_GUIDE_18A8: "Heddiw).",
  USER_GUIDE_18A9:
    "Gweld pob meddyginiaeth bresennol a blaenorol mewn rhestr ( ",
  USER_GUIDE_18A10: "golwg Pob meddyginiaeth",
  USER_GUIDE_18A11: " )",
  USER_GUIDE_18A12: "Sut i greu cofnod meddyginiaeth newydd",
  USER_GUIDE_18A13:
    "Mae angen i chi fod yn weinyddwr neu gael caniatâd i greu cofnod meddyginiaeth newydd",
  USER_GUIDE_18A14: "Cliciwch ar y ",
  USER_GUIDE_18A15: "botwm Creu meddyginiaeth ",
  USER_GUIDE_18A16: "newydd",
  USER_GUIDE_18A17: "Teipiwch enw’r feddyginiaeth",
  USER_GUIDE_18A18:
    "Gallwch ychwanegu llun o’r feddyginiaeth os ydych chi eisiau, drwy glicio ar ",
  USER_GUIDE_18A19: "Dewis llun",
  USER_GUIDE_18A20:
    "Nodwch ar gyfer pa gyflwr mae’n rhaid cymryd y feddyginiaeth",
  USER_GUIDE_18A21:
    "Dewiswch o’r rhestr pa mor aml y dylid cymryd y feddyginiaeth (bod dydd, wythnos, ac ati)",
  USER_GUIDE_18A22:
    "Dewiswch yr amser a’r ddos (ychwanegwch amser a dos arall os dylid cymryd y feddyginiaeth mwy nag unwaith y dydd)",
  USER_GUIDE_18A23:
    "Dewiswch Presennol neu Blaenorol i nodi os ydy hon yn feddyginiaeth presennol neu flaenorol",
  USER_GUIDE_18A24:
    "Dewiswch y dyddiad cychwyn (ac yn opsiynol, y dyddiad gorffen)",
  USER_GUIDE_18A24A:
    "Ychwanegwch leoliad i ddangos ble mae'r feddyginiaeth yn cael ei storio. Gallwch gofnodi lleoliadau newydd neu gallwch ddewis o leoliadau a grëwyd yn flaenorol.",
  USER_GUIDE_18A24B: "Ychwanegwch nodyn yn y bocs a ddarperir (opsiynol)",
  USER_GUIDE_18A25: "Ychwanegwch nodyn yn y bocs a ddarperir (opsiynol)",
  USER_GUIDE_18A26: "Cliciwch ar  ",
  USER_GUIDE_18A27: "Arbed",
  USER_GUIDE_18A28: " i greu eich cofnod",
  USER_GUIDE_18A29: "Sut i olygu meddyginiaeth",
  USER_GUIDE_18A30:
    "(Mae angen i chi fod yn weinyddwr neu gael caniatâd i olygu meddyginiaeth)",
  USER_GUIDE_18A31: "Ar y dudalen ",
  USER_GUIDE_18A32: "Meddyginiaethau",
  USER_GUIDE_18A33:
    " (Golwg Heddiw neu golwg Pob Meddyginiaeth) cliciwch ar y feddyginiaeth rydych chi eisiau ei golygu.",
  USER_GUIDE_18A34: "Cliciwch ar ",
  USER_GUIDE_18A35: "Golygu",
  USER_GUIDE_18A36:
    " os ydych chi eisiau newid yr wybodaeth am y feddyginiaeth (fel enw’r feddyginiaeth, amlder, amser, dos, ac ati). Cofiwch ",
  USER_GUIDE_18A37: "Arbed",
  USER_GUIDE_18A38: " eich newidiadau.",
  USER_GUIDE_18A39: "Cliciwch ar ",
  USER_GUIDE_18A40: "Dileu",
  USER_GUIDE_18A41: " os ydych chi eisiau dileu’r feddyginiaeth.",
  USER_GUIDE_18A42:
    "Gallwch bostio sylwad sy’n ymwneud â’r feddyginiaeth hon drwy ddefnyddio’r bocs a ddarperir.",

  USER_GUIDE_Q50: "Categorïau a hidlwyr",
  USER_GUIDE_50A1:
    "Pan fyddwch yn creu neu'n golygu nodyn, digwyddiad, tasg neu gyswllt, gallwch glicio ar y gwymplen Categori i'w neilltuo i gategori sy'n bodoli eisoes neu i greu categori newydd. Gallwch greu categorïau newydd ymlaen llaw hefyd, drwy ddewis Golygu Categorïau",
  USER_GUIDE_50A2:
    "Yn yr adrannau hyn, gallwch glicio ar y botwm hidlo ar y dde uchaf (eicon twmffat) i weld yr eitemau sydd wedi cael categori penodol yn unig, neu gallwch ddewis eiconau categori lluosog, a gallai hyn fod yn ddefnyddiol i gymharu gwahanol fathau o wybodaeth. I weld y rhestr lawn o eitemau eto, cliciwch ar yr eicon categori twmffat (twmffat gydag x arno).",

  USER_GUIDE_Q51: "Dyfeisiau",
  USER_GUIDE_51A1:
    "Bydd Jointly yn cysylltu â rhai dyfeisiau iechyd a monitro penodol er mwyn gallu storio gwybodaeth ohonynt a'i rhannu ag aelodau'r cylch. Byddwn yn gwneud cyhoeddiadau am y dyfeisiau y gellir cysylltu â nhw ar Jointly yn yr adran Gosodiadau cylch > Dyfeisiau.",
  USER_GUIDE_51A2:
    "Marciwch gategori fel un preifat wrth ei greu neu ei olygu drwy wirio'r bocs 'Ydy hwn yn breifat'. Yna, dim ond gweinyddwyr cylch neu aelodau sydd wedi cael mynediad i gategorïau preifat mewn lleoliadau Cylch sydd yn gallu gweld unrhyw eitemau sydd wedi cael eu neilltuo i gategori preifat, yn Gosodiaau Cylch.",
  USER_GUIDE_51A3:
    "Bydd eitemau categori preifat yn cael eu cuddio oddi wrth aelodau heb eu hawdurdodi. Ar gyfer digwyddiadau, bydd deiliad lle sydd wedi'i farcio'n 'eitem breifat' yn amlwg i'r aelodau hyn fel eu bod yn gwybod bod rhywbeth wedi'i archebu ar y dyddiad/amser hwnnw, ond ni fyddant yn gallu gweld unrhyw fanylion pellach am yr eitemau hyn.",
  USER_GUIDE_51A4:
    "Ni fydd aelodau heb eu hawdurdodi yn gweld diweddariadau i eitemau categori preifat yn eu porthiant gweithgaredd na'u hysbysiadau ychwaith.",
  USER_GUIDE_51A5:
    "Gallwch ddewis p’un a hoffech dderbyn hysbysiadau drwy ap neu e-bost ar gyfer dyfais neu beidio, yn y gosodiadau HysbysiadauNotification settings.",

  USER_GUIDE_Q19: "Dyfeisiau",
  USER_GUIDE_19A1:
    "Bydd Jointly yn cysylltu â rhai dyfeisiau iechyd a monitro penodol er mwyn gallu storio gwybodaeth ohonynt a'i rhannu ag aelodau'r cylch. Byddwn yn gwneud cyhoeddiadau am y dyfeisiau y gellir cysylltu â nhw ar Jointly yn yr adran Gosodiadau cylch > Dyfeisiau.",
  USER_GUIDE_19A2:
    "Gallwch ddewis p’un a hoffech dderbyn hysbysiadau drwy ap neu e-bost ar gyfer dyfais neu beidio, yn y gosodiadau Hysbysiadau",
  USER_GUIDE_Q20: "Cysylltiadau",
  USER_GUIDE_20A1: "O’r ddewislen, ",
  USER_GUIDE_20A2: "cliciwch ",
  USER_GUIDE_20A3: " ar y tab Cysylltiadau",
  USER_GUIDE_20A4:
    "Cael mynediad i restr o’ch cysylltiadau (mae hyn yn cynnwys aelodau o’ch cylch a chysyltliadau defnyddiol eraill sydd wedi’u storio).",
  USER_GUIDE_20A5: "Creu cyswllt newydd",
  USER_GUIDE_20A6: "Ar y dudalen Cysylltiadau, cliciwch ar y botwm ",
  USER_GUIDE_20A7: "Creu cyswllt newydd",
  USER_GUIDE_20A8:
    "Ychwanegwch fanylion y cyswllt ac os ydych chi eisiau, rhowch nodyn yn y bocs a ddarperir. Cofiwch y gallai ychwanegu manylion cyfeiriad fod yn ddefnyddiol ar gyfer rhai cysylltiadau. Er enghraifft, os ydych chi’n rhoi manylion Meddyg Teulu, gallai arbed amser ac ymdrech y tro nesaf y bydd angen i chi neu aelod arall o'ch cylch fynd yno i wneud nodyn o'r cyfeiriad.",
  USER_GUIDE_20A8A:
    "Gallwch osod cyswllt fel 'Cyswllt argyfwng (ICE)' hefyd, a byddant yn ymddangos gydag eicon ffôn coch wrth eu hymyl ar y rhestr cysylltiadau.",
  USER_GUIDE_20A9: "Cliciwch ar ",
  USER_GUIDE_20A10: " Arbed",
  USER_GUIDE_20A11: " i greu’r cyswllt",
  USER_GUIDE_20A12: "Gweld/Golygu cyswllt",
  USER_GUIDE_20A13:
    "Ar y dudalen Cysylltiadau, gallwch weld rhestr lawn o’ch cysylltiadau.  Sgroliwch i fyny neu i lawr i ddod o hyd i gyswllt.",
  USER_GUIDE_20A14: "Cliciwch ar y cyswllt i’w olygu.  Ar y sgrîn sy’n dilyn:",
  USER_GUIDE_20A15: "Cliciwch ar ",
  USER_GUIDE_20A16: " Golygu ",
  USER_GUIDE_20A17: "i wneud newidiadau i’r manylion cyswllt.  Cofiwch ",
  USER_GUIDE_20A18: "Arbed",
  USER_GUIDE_20A19: " eich newidiadau.",
  USER_GUIDE_20A20: "Cliciwch ar  ",
  USER_GUIDE_20A21: "Dileu ",
  USER_GUIDE_20A22:
    "os ydych chi eisiau cael gwared ar y cyswllt o restr cysylltiadau eich cylch Jointly. ",
  USER_GUIDE_20A23: "Cofiwch, ",
  USER_GUIDE_20A24:
    "os ydych chi’n dileu cyswllt, mae hyn yn dileu’r cyswllt yn llwyr o'ch rhestr gysylltiadau Jointly. Ni fyddwch chi nac aelodau eraill o'ch cylch yn gallu cael gafael ar y wybodaeth hon eto.",
  USER_GUIDE_20A25: "* Sylwch ",
  USER_GUIDE_20A26:
    "na allwch chi newid manylion cyswllt aelod arall o'ch cylch Jointly. Os byddwch yn sylwi ar wybodaeth anghywir neu anghyflawn, cysylltwch â'r person fel ei fod yn gallu golygu ei wybodaeth bersonol.",

  USER_GUIDE_Q21: "Gosodiadau cylch a dileu eich cylch",
  USER_GUIDE_21A1:
    "Mae’n rhaid i chi fod yn weinyddwr cylch i fedru rheoli gosodiadau cylch Jointly. Mae gweinyddwr yn gallu",
  USER_GUIDE_21A2: "Gwahodd aelodau newydd a dileu aelodau o’r cylch",
  USER_GUIDE_21A3: "Gwneud aelodau o gylch yn ",
  USER_GUIDE_21A4: "weithredol ",
  USER_GUIDE_21A5: " neu’n ",
  USER_GUIDE_21A6: "anweithredol",
  USER_GUIDE_21A7:
    ".  Os ydych chi’n gwneud aelod yn anweithredol, mae hynny’n golygu na fyddant yn rhan o’ch cylch rhagor.  Ni fyddwch yn gallu neilltuo Tasgau iddyn nhw rhagor neu eu gwahodd nhw i Ddigwyddiadau, ac ni fyddant yn derbyn unrhyw hysbysiadau",
  USER_GUIDE_21A8:
    "Rhoi caniatâd i aelodau eraill o’r cylch hwn i fod yn Weinyddwyr",
  USER_GUIDE_21A9:
    "Rhoi caniatâd i aelodau eraill o’r cylch i ychwanegu/golygu Meddyginiaethau",
  USER_GUIDE_21A10:
    "Rhoi caniatâd i aelodau eraill o’r cylch i weld eitemau preifat. Categorïau Nodyn / Dyfais sydd wedi cael eu nodi fel preifat.",
  USER_GUIDE_21A11: "Gadael y cylch eich hun",
  USER_GUIDE_21A12:
    "Dileu’r cylch - rhaid i bob aelod arall fod wedi gadael neu wedi cael eu symud cyn y gellir dileu cylch (os ydych chi eisiau dileu eich cyfrif, rhaid i chi wneud hyn drwy’r tab 'Golygu/dileu cyfrif ' ar y brif ddewislen)",
  USER_GUIDE_21A13:
    "Os ydych chi eisiau atal rhywun rhag golygu meddyginiaethau, edrych ar eitemau preifat neu ddileu pobl o'r cylch, gwnewch yn siŵr hefyd nad oes tic wrth ymyl ‘gweinyddwr cylch '",

  USER_GUIDE_Q52: "Allgludo eich data",
  USER_GUIDE_52A1:
    "Gallwch allgludo eich data ar unrhyw adeg o Jointly i fformatau Word, PDF neu CSV. Sylwch fod yn rhaid i chi fod yn weinyddwr cylch i allgludo data o gylch.",
  USER_GUIDE_52A2:
    "Mae'n bosibl allgludo'r holl ddata o fewn Jointly, neu allgludo set benodol o wybodaeth yn unig (dewis llwytho i lawr e.e. dim ond Tasgau, Digwyddiadau ac ati yn unig neu eitemau a grëwyd o fewn ystod dyddiad yn unig, ac ati). Gallwch allgludo cynllun wrth gefn hefyd, sy'n cynnwys cyfres o wybodaeth hanfodol y gallwch ei rhannu gyda rhywun rhag ofn y bydd argyfwng. Gallwch ddewis cynnwys neu eithrio unrhyw wybodaeth rydych chi wedi'i marcio'n breifat o fewn Jointly (eitemau wedi eu neilltuo i Gategorïau Preifat).",
  USER_GUIDE_52A3:
    "Gallwch  ddewis cynnwys delweddau neu atodiadau wrth allgludo. Bydd enwau ffeiliau atodiadau yn Jointly  yn ymddangos wrth ymyl pob eitem yn eich dogfen(au) allgludo testun.",
  USER_GUIDE_52A4:
    "I allgludo data, ewch i Gosodiadau Cyfrif > Allgludo eich data a dewiswch yr opsiynau i ddewis y data rydych chi eisiau eu hallgludo. Bydd Clicio ar Allgludo yn anfon dolen we i'r cyfeiriad e-bost y mae eich cyfrif Jointly wedi'i gofrestru iddo. Ar ôl clicio ar y ddolen, rhaid i chi roi eich cyfrinair Jointly er diogelwch.",
  USER_GUIDE_52A5:
    "Bydd y data sydd yn cael eu hallgludo yn llwytho i lawr fel ffeil zip. Os dewisoch chi ddewisiadau allgludo Word neu PDF, yna bydd yr holl ddata y gofynnwyd amdanynt yn cael eu hallgludo fel un ddogfen (un ddogfen ar gyfer pob cylch, os ydych chi wedi dewis llwytho data i lawr ar gyfer mwy nag un cylch). Os dewisoch chi'r opsiwn allgludo CSV, yna bydd pob adran (e.e. Nodiadau, Tasgau ac ati) yn cael ei hallgludo fel ffeil CSV ar wahân.",
  USER_GUIDE_52A6:
    "Ni allwn ddiogelu data rydych chi'n ei allgludo o Jointly. Dylech wneud yn siŵr bod unrhyw wybodaeth sensitif y byddwch yn ei llwytho i lawr yn cael ei storio'n ddiogel.",
  USER_GUIDE_52A6A: "",
  USER_GUIDE_52A6B: "",

  USER_GUIDE_Q22: "Symud rhwng cylchoedd Jointly",
  USER_GUIDE_22A1:
    "Os oes gennych chi fwy nag un cylch Jointly, mae’n rhwydd symud rhwng eich cylchoedd!",
  USER_GUIDE_22A2: "O'r ddewislen, sgroliwch lawr i'ch ",
  USER_GUIDE_22A3: "cychoedd Jointly",
  USER_GUIDE_22A4:
    ". Islaw iddo, mae enwau’r bobl rydych chi’n gofalu amdanynt yn cael eu rhestru yn ôl trefn yr wyddor.",
  USER_GUIDE_22A5:
    "Cliciwch ar enw'r person rydych chi eisiau agor ei gylch Jointly (mae ffont coch yn dynodi enw’r cylch rydych chi ynddo ar hyn o bryd).",
  USER_GUIDE_22A6: "Ailadroddwch y camau uchod i newid nôl i gylch arall.",

  USER_GUIDE_Q23: "Gosodiadau cyfrif / dileu eich cyfrif",
  USER_GUIDE_23A1:
    "Yn yr adran hon, gallwch olygu eich gwybodaeth gyswllt (gall aelodau eraill o’ch cylch weld hyn), newid eich cyfrinair neu ddileu eich cyfrif.",
  USER_GUIDE_23A1A: "",
  USER_GUIDE_23A1B: "",
  USER_GUIDE_23A1C: "",
  USER_GUIDE_23A2:
    "Mae’n rhaid i chi ddileu eich cylch os ydych chi eisiau dileu’r holl wybodaeth sydd wedi’i storio yn Jointly (ewch i gosodiadau Cylch a dileu eich cylch).  Mae’n rhaid i chi adael neu ddileu unrhyw gylchoedd gweithredol cyn eich bod chi’n gallu dileu eich cyfrif defnyddiwr. ",

  USER_GUIDE_Q24: "Allgofnodi",
  USER_GUIDE_24A1: "Sgroliwch lawr i ",
  USER_GUIDE_24A2: "Amdanoch chi o’r ddewislen",
  USER_GUIDE_24A3: "Cliciwch ",
  USER_GUIDE_24A4: "ar Allgofnodi ",
  USER_GUIDE_24A5:
    " i adael Jointly. Mae angen i chi gofio eich gwybodaeth logio mewn i fewngofnodi i Jointly. Os ydych chi'n defnyddio Jointly ar ffôn clyfar personol neu ffôn cartref personol, ffôn gwaith neu ddyfais gludadwy, gallwch barhau i fod wedi mewngofnodi a medru cyrchu Jointly bob amser drwy glic syml.",

  USER_GUIDE_Q25: "Ffurfweddu hysbysiadau",
  USER_GUIDE_25A1:
    "Gall Jointly anfon hysbysiadau e-bost neu ap atoch chi (gelwir y rhain yn ",
  USER_GUIDE_25A2: "hysbysiadau gwthio hefyd",
  USER_GUIDE_25A3:
    ") pan fydd gwybodaeth benodol yn cael ei diweddaru neu pan fydd digwyddiadau'n ddyledus. Gallwch ddefnyddio'r adran hon i ddewis pa hysbysiadau rydych chi’n eu derbyn drwy dicio neu ddad-dicio’r blychau.",
  USER_GUIDE_25A4: "Gall Jointly e-bostio crynhoad drwy e-bost o",
  USER_GUIDE_25A5: " weithgaredd Cylch atoch chi hefyd",
  USER_GUIDE_25A6:
    ", sy'n cynnwys rhestr o'r holl wybodaeth wedi'i diweddaru yn eich cylch y diwrnod neu'r wythnos flaenorol. Ticiwch y blychau os hoffech chi gael y rhain. Anfonir crynoadau am 8am ac anfonir y crynhoad wythnosol bob bore Llun.",

  USER_GUIDE_Q26: "Rhoi gwybod am rhywbeth",
  USER_GUIDE_26A1:
    "Os ydych chi’n cael problem dechnegol neu os oes gennych chi ymholiad technegol, anfonwch e-bost at: ",
  USER_GUIDE_26A2: "Mae’r ",
  USER_GUIDE_26A3: "canllaw defnyddwyr Jointly ",
  USER_GUIDE_26A4: " hwn a’r  ",
  USER_GUIDE_26A5:
    " adrannau Cwestiynau Cyffredin yn cwmpasu nifer o bynciau. Bwriwch olwg, efallai y gwelwch beth ydych chi’n chwilio amdano.",
  USER_GUIDE_26A6:
    "Os oes gennych chi ymholiad/awgrym cyffredinol, anfonwch e-bost i ",
  USER_GUIDE_26A7: "",

  USER_GUIDE_Q28: "Atgof",
  USER_GUIDE_28A1: "Llywiwch i'r opsiwn Atgoffa o'r ddewislen. Ar y dudalen hon gallwch:",
  USER_GUIDE_28A2: "Creu Nodyn Atgoffa newydd.",
  USER_GUIDE_28A3: "Gweld rhestr o Nodiadau Atgoffa cyfredol, eu dyddiad a'u hamser cychwyn, disgrifiad, yr aelod sy'n gyfrifol am y nodyn atgoffa, a dewis golygu neu ddileu'r nodyn atgoffa.",
  USER_GUIDE_28A4: "Sut i greu Rhestr Atgoffa newydd",
  USER_GUIDE_28A5: "Llywiwch i'r opsiwn Atgoffa o'r ddewislen.",
  USER_GUIDE_28A6: "Cliciwch ar y botwm 'Ychwanegu Atgoffa'.",
  USER_GUIDE_28A7: "Rhowch y Teitl ar gyfer eich nodyn atgoffa newydd (er enghraifft, Siopa Groser).",
  USER_GUIDE_28A8: "Dewiswch pwy o'ch cylch gofal sy'n gyfrifol am y rhestr Atgoffa hon.",
  USER_GUIDE_28A9: "Rhowch ddisgrifiad ar gyfer eich Nodyn Atgoffa newydd (er enghraifft “siopa wythnosol Modryb Jane”).",
  USER_GUIDE_28A10: "Dewiswch y dyddiad cychwyn rydych chi am i'r rhestr fynd yn fyw ohono.",
  USER_GUIDE_28A11: "Dewiswch yr amser cychwyn yr hoffech i'r rhestr fynd yn fyw ohono.",
  USER_GUIDE_28A12: "Dewiswch pa mor aml yr hoffech i'r rhestr ailddigwydd (er enghraifft wythnosol, misol ac ati).",
  USER_GUIDE_28A13: "Dewiswch ddyddiad yr hoffech i'r rhestr hon ei hailadrodd tan.",
  USER_GUIDE_28A14: "Cliciwch ar y botwm 'Cadw'.",
  USER_GUIDE_28A15: "Nawr fe welwch y Nodyn Atgoffa newydd wedi'i restru. Rydych chi nawr eisiau ychwanegu eitemau at y rhestr Atgoffa sydd newydd ei chreu. Sylwch fod y broses hon yr un peth wrth olygu naill ai rhestr Atgoffa sydd newydd ei chreu, neu restr sy'n bodoli eisoes sydd eisoes yn weithredol.",
  USER_GUIDE_28A16: "Sut i ychwanegu eitemau llinell at eich rhestr Atgoffa newydd neu bresennol.",
  USER_GUIDE_28A17: "Cliciwch ar y rhestr Atgoffa y mae angen i chi ychwanegu eitemau llinell ati.",
  USER_GUIDE_28A18: "Bydd hyn yn agor naill ai rhestr wag (os yw'n rhestr newydd) neu restr bresennol o eitemau, o dan y tab “Pawb”.",
  USER_GUIDE_28A19: "Cliciwch ar y botwm 'Ychwanegu Eitem'.",
  USER_GUIDE_28A20: "Rhowch enw'r eitem rhestr (er enghraifft “Tatws”), ac yna disgrifiad. Wedi'i ddilyn gan y botwm 'Cadw'. Ailadroddwch y broses hon ar gyfer pob eitem llinell yr hoffech ei hychwanegu at eich rhestr Atgoffa. Wrth i chi wneud hynny, fe welwch yr eitemau rhestr Atgoffa wedi'u hychwanegu.",
  USER_GUIDE_28A21: "Sut i olygu eitemau llinell ar eich rhestr Atgoffa newydd neu bresennol.",
  USER_GUIDE_28A22: "O'r rhestr Atgoffa a agorwyd gennych, cliciwch ar yr eicon pensil wrth ymyl eitem llinell i olygu ei fanylion. Unwaith y byddwch wedi gorffen golygu eich eitem llinell, cliciwch ar y botwm “Cadw”. Fel arall, os ydych am ddileu eich eitem llinell, dewiswch yr eicon bin sbwriel.",
  USER_GUIDE_28A23: "Sut i weithredu eitemau llinell ar Restr Atgoffa.",
  USER_GUIDE_28A24: "Llywiwch i'r opsiwn Atgoffa o'r ddewislen.",
  USER_GUIDE_28A25: "Dewiswch yr enw Atgoffa yr ydych am ei weithredu, er enghraifft “rhestr siopa wythnosol”. Bydd hyn yn agor y rhestr Atgoffa cyfatebol.",
  USER_GUIDE_28A26: "Wrth i chi gwblhau pob eitem ar y rhestr, ticiwch hi i ffwrdd trwy glicio ar y cylch i'r chwith o enw'r eitem, bydd hwn wedyn yn newid i dic gwyn mewn cylch coch, a bydd yr eitem llinell yn dangos fel y mae wedi'i chroesi. Peidiwch â phoeni os yw hon yn rhestr yr ydych yn bwriadu ei hailadrodd. Ar y dyddiad a'r amser pan fydd y rhestr i fod i ailadrodd, bydd yr eitemau'n cael eu rhestru fel rhai sy'n aros i'w cwblhau eto. Os gwnewch gamgymeriad, gallwch chi “ddad-dic” yr eitem trwy glicio ar y cylch coch.",
  USER_GUIDE_28A27: "Sut i weld hanes rhestr Atgoffa.",
  USER_GUIDE_28A28: "Rhan o bŵer y swyddogaeth Atgoffa yw gallu gweld yn glir pan weithredwyd eitemau llinell yn flaenorol gan aelodau'r cylch.",
  USER_GUIDE_28A29: "Gyda'ch rhestr Atgoffa ar agor, dewiswch y tab 'Hanes' o frig y sgrin. Bydd hyn yn dangos yr holl eitemau hanes ar-lein o'r rhestr Atgoffa gyfredol.",

  FAQ_HEADING: "Cwestiynau Cyffredin Jointly",
  FAQ_Q1: "Beth ydy Jointly?",
  FAQ_1A1:
    "Ap ffôn symudol ac ar-lein ydy Jointly, sydd wedi cael ei greu gan ofalwyr, ar gyfer gofalwyr ",
  FAQ_1A2:
    "Mae wedi’i ddylunio i wneud gofalu ychydig yn haws, yn llai o straen ac yn llawer mwy trefnus. Mae'n cyfuno negeseuon grŵp gyda nodweddion defnyddiol eraill, sy’n cynnwys rhestrau o bethau i'w gwneud a meddyginiaethau, calendr a mwy! Gallwch ei ddefnyddio gydag eraill neu ar eich pen eich hun.",
  FAQ_Q2: "Ar ba ddyfeisiau y gallaf ddefnyddio Jointly?",
  FAQ_2A1: "Gallwch ddefnyddio Jointly ar y dyfeisiau canlynol:",
  FAQ_2A2: "iPhone ac iPad, fersiwn iOS 9.0 ac uwch",
  FAQ_2A3: "Dyfeisiau Android, fersiwn 5.0 ac uwch.",
  FAQ_2A4:
    "Y rhan fwyaf o borwyr gwe modern (cyfrifiaduron cartref, gliniaduron, ffonau clyfar, tabledi, ac ati) ",
  FAQ_2A5:
    "Unwaith rydych chi’n creu cyfrif Jointly, gallwch ei ddefnyddio i fewngofnodi i Jointly ar unrhyw ddyfais o’ch dewis. Gallwch lawrlwytho’r ap ar eich iPhone, iPad, ffôn Android neu dabled, neu ewch i ",
  FAQ_2A6:
    " o unrhyw gyfrifiadur tabled, ffôn symudol, gliniadur neu gyfrifiadur cartref arall.",
  FAQ_Q3: "Sut ydw i’n defnyddio Jointly?",
  FAQ_3A1: "Darllenwch ganllaw ",
  FAQ_3A2: "defnyddwyr Jointly i gael ",
  FAQ_3A3:
    " rhagor o fanylion ar sut i ddefnyddio Jointly, sy’n cynnwys sut i gofrestru, sut i wahodd defnyddwyr eraill i’ch cylch, a sut i reoli, golygu a dileu gwybodaeth.",
  FAQ_3A4:
    "Nodwch y gall pob aelod o'ch cylch weld yr wybodaeth sydd yn cael ei rhannu ar Jointly. Defnyddiwch eich synnwyr cyffredin am ba wybodaeth y gallwch ei rhannu gydag aelodau o’ch cylch.",
  FAQ_Q4: "Ydy Jointly am ddim?",
  FAQ_4A1: "Gallwch fynd i ",
  FAQ_4A2:
    " neu lawrlwytho’r ap am ddim i gael mynediad i fideo Jointly, i ganllaw defnyddwyr Jointly ac i’r Cwestiynau Cyffredin, er mwyn cael rhagor o wybodaeth am Jointly.",
  FAQ_4A3:
    "Mae Jointly’n gweithio gyda chylchoedd gofal.  Gallwch brynu cylch Jointly drwy dalu £2.99 unwaith, naill ai drwy’r storfeydd Apple neu Google Play, neu drwy fynd i ",
  FAQ_4A4:
    "Unwaith rydych chi wedi prynu cylch Jointly ar gyfer y person rydych chi’n gofalu amdano, gallwch wahodd cymaint o bobl ag y dymunwch i rannu'r gofal gyda chi. Nid oes cost ychwanegol i chi na'r bobl rydych yn eu gwahodd i ymuno â chi. Fel arall, gallwch ddefnyddio Jointly ar eich pen eich hun i ofalu am rywun. Yn yr achos hwn, byddwch yn dal i orfod prynu cylch Jointly.",
  FAQ_4A5:
    "Nodyn: Mae rhai cyflogwyr a darparwyr gwasanaeth yn cynnig Jointly. Yn yr achos hwn, gallwch ofyn am daleb ar gyfer Jointly, a fydd yn caniatáu i chi gyfnewid Jointly am ddim.",
  FAQ_Q5: "Pam ydw i’n gorfod talu am Jointly?",
  FAQ_5A1: "Mae Jointly yn cael ei ddatblygu gan ",
  FAQ_5A2: "Carers UK",
  FAQ_5A3:
    ", sef elusen sydd wedi cael ei sefydlu i helpu'r miliynau o bobl sy'n gofalu am aelod o’r teulu neu ffrind hŷn, anabl neu wael. Rydym yn gwneud hynny drwy ymgyrchu a thrwy ddarparu cyngor, gwybodaeth a chymorth i ofalwyr. Ein nod yw gwneud bywydau gofalwyr yn haws, a dyna pam rydym wedi creu Jointly. Mae’n rhaid i ni godi ffi fechan amdano, i dalu am y costau o’i redeg ac am y costau chynnal a chadw.",
  FAQ_Q6:
    "Rwyf wedi derbyn cod mynediad am ddim ar gyfer Jointly gan sefydliad fel awdurdod lleol, darparwr iechyd neu ofal neu gan fy nghyflogwr. Oes angen i mi brynu Jointly o hyd?",
  FAQ_6A1:
    "Na, os ydych chi wedi cael cod ar gyfer Jointly, mae hynny'n golygu bod sefydliad wedi prynu cylch Jointly ar eich cyfer. Gallwch ddefnyddio'r cod yn ",
  FAQ_6A2:
    " a dechrau defnyddio Jointly ar-lein neu ar eich ffôn symudol. Dim ond unwaith y gellir defnyddio cod mynediad; os ydych chi’n gofalu am fwy nag un person ac os hoffech chi greu mwy nag un cylch Jointly, gallwch naill ai ofyn i'ch cyflogwr neu ddarparwr gwasanaeth i roi cod newydd i chi, neu gallwch brynu cylch Jointly eich hun (taliad unwaith yn unig o £2.99).",
  FAQ_Q7:
    "Sut ydw i’n gallu defnyddio cod mynediad Jointly i greu cylch newydd?",
  FAQ_7A1:
    "Os ydych chi wedi cael Cod Mynediad am Ddim gan eich cyflogwr neu ddarparwr gwasanaeth, gellir defnyddio hwn i ddefnyddio cylch Jointly yn rhad ac am ddim.",
  FAQ_7A2:
    "Os nad oes gennych chi gyfrif gyda Jointly, defnyddiwch y ffurflen gofrestru i greu cyfrif.  Os oes gennych chi gyfrif Jointly yn barod, dylech fewngofnodi i’ch cyfrif. ",
  FAQ_7A3:
    "Byddwch yn gorfod creu cylch newydd pan fyddwch yn mewngofnodi i Jointly am y tro cyntaf. Gallwch ddechrau creu eich cylch drwy lenwi rhywfaint o wybodaeth ddefnyddiol am y person rydych chi’n gofalu amdano. Gallwch bob amser olygu'r wybodaeth hon yn nes ymlaen o'r proffil.",
  FAQ_7A4:
    "Pan fyddwch chi’n creu eich cylch, defnyddiwch yr opsiwn ' Cod Mynediad am Ddim ' a rhowch y cod mae eich cyflogwr neu ddarparwr gwasanaeth wedi'i roi i chi.",
  FAQ_Q8: "Sut y gallaf ddefnyddio cod mynediad Jointly i greu cylch demo? ",
  FAQ_8A1:
    "Os ydych chi wedi creu cylch demo yn barod ac eisiau defnyddio Cod Mynediad am Ddim i'w ddatgloi, dylech fewngofnodi i Jointly, agor y gosodiadau cylch trwy glicio ar yr eicon cog ar dop eich dewislen, a chlicio ar ",
  FAQ_8A2: "'Datgloi’r cylch hwn'",
  FAQ_8A3: " ar waelod y sgrîn.",
  FAQ_8A4: "Rhowch eich cod yn y bocs sy’n ymddangos, a chliciwch ar Redeem.",
  FAQ_Q9:
    "Rwyf wedi gwahodd rhywun i ymuno â fy nghylch, ond dydyn nhw heb dderbyn fy e-bost gwahoddiad.",
  FAQ_9A1:
    "Gwnewch yn siŵr eich bod chi wedi cofnodi'r wybodaeth gywir ar y ffurflen wahoddiad. Gallwch weld hyn yn eich gwahoddiadau i ddod, a gallwch anfon gwahoddiadau o fan hyn hefyd. Gofynnwch iddynt wirio nad yw eich e-bost gwahoddiad wedi cael ei flocio’n anghywir fel sbam. ",
  FAQ_9A2: "Os ydy’r person yn cofrestru cyfrif newydd ar ",
  FAQ_9A4:
    " neu ar yr ap ffôn symudol yn defnyddio’r cyfeiriad e-bost yr anfonoch y gwahoddiad iddo, yna byddant yn gallu derbyn y gwahoddiad pan fyddant yn mewngofnodi. ",
  FAQ_9A5: "Os ydych chi’n cael rhagor o broblemau, anfonwch e-bost i",
  FAQ_Q10: "Nid wyf yn gallu lawrlwytho’r ap i fy nyfais Android/iOS",
  FAQ_10A1:
    "I ddefnydido ap ffôn symudol Jointly, rydych chi angen fersiwn Android 5.0 neu ddiweddarach o Jointly, neu iOS fersiwn 9.0 neu ddiweddarach. Os ydych yn rhedeg fersiwn hŷn ac nad yw'r AppStore yn gadael i chi lawrlwytho'r ap, ceisiwch ddiweddaru eich meddalwedd ffôn neu ddefnyddio'r fersiwn ar y we yn ",
  FAQ_10A2:
    "Mae fersiwn gwe Jointly yn gweithio yn yr un ffordd â’r fersiwn ap.",
  FAQ_Q11:
    "Rwy’n edrych ar ôl mwy nag un person.  A gaf i ddau neu fwy o gylchoedd gofal Jointly?",
  FAQ_11A1:
    "Cewch, gallwch greu dau neu fwy o gylchoedd gofal.  Gallwch brynu cylch newydd (am £2.99) drwy glicio ",
  FAQ_11A2: "+ Create a circle",
  FAQ_11A3: " .",
  FAQ_Q12:
    "Rwyf wedi anghofio fy enw defnyddiwr neu fy nghyfrinair.  Beth sy’n rhaid i mi ei wneud?",
  FAQ_12A1: "Os ydych chi wedi anghofio eich cyfrinair, ewch i ",
  FAQ_12A2:
    " neu agorwch yr ap Jointly ar eich iPhone, iPad, ffôn Android neu dabled. Ar y sgrîn Mewngofnodi, cliciwch ar Wedi anghofio eich cyfrinair? Yn y sgrîn sy'n dilyn, rhowch eich cyfeiriad e-bost. Byddwn yn anfon e-bost atoch; dilynwch y ddolen yn yr e-bost i ailosod eich cyfrinair. Nawr, gallwch ddefnyddio eich cyfrinair newydd i fewngofnodi.",
  FAQ_12A3: "Os ydych chi wedi anghofio eich enw defnyddiwr,",
  FAQ_12A4:
    " eich enw defnyddiwr yw eich cyfeiriad e-bost.  Os ydych chi wedi newid gosodiadau eich cyfrif yn ddiweddar, yn cynnwys newid eich cyfeiriad e-bost, bydd angen i chi ddefnyddio’r cyfeiriad e-bost newydd i fewngofnodi.  ",
  FAQ_12A5: "Os ydych chi’n cael trafferth mewngofnodi, e-bostiwch ",
  FAQ_Q13: "Ydy’n Jointly’n gweithio heb gysylltiad â’r rhyngrwyd?",
  FAQ_13A1:
    "Gallwch agor yr ap Jointly a gweld ei gynnwys pan nad ydych chi ar-lein, ond ni fyddwch chi’n gallu gwneud newidiadau (ychwanegu, golygu neu ddileu cynnwys) os nad ydych chi ar-lein.",
  FAQ_13A2:
    "Mae’n bosibl na fydd data oddi ar-lein ar gael os nad ydych wedi bod ar-lein am amser neu os ydy eich dyfais wedi clirio man storio dros dro Jointly.  Ni allwch ddarllen negeseuon os nad ydych chi wedi’ch cysylltu â’r rhyngrwyd.  Mae’n bosibl y bydd y data ar Jointly yn hen os nad ydy eich dyfais wedi cydamseru â’r gweinydd yn ddiweddar.",
  FAQ_Q14: "Storio/diogelu data",
  FAQ_14A1:
    "Mae eich holl ddata yn cael ei drosglwyddo trwy gyswllt wedi’i amgryptio i weinydd diogel.  Os bydd y gwasanaeth yn methu, byddwch yn gallu lawrlwytho eich data yn y fformat .cvs.",
  FAQ_Q15: "Rwy’n cael problemau technegol.  Gyda phwy y gallaf gysylltu?",
  FAQ_15A1: "Os ydych chi’n cael problemau technegol, e-bostiwch ",
  FAQ_Q16: "Sut ydw i’n dileu fy nghylch a fy nghyfrif Jointly?",
  FAQ_16A1:
    "Darllenwch yr adrannau ‘Circle settings and deleting your circle’ a ‘Edit/delete Account’ yn y canllaw defnyddwyr.  Mae’n rhaid i chi ddileu/adael unrhyw gylchoedd cyn i chi fedru dileu eich cyfrif Jointly.",
  FAQ_Q17: "Mae gennyf awgrym/adborth.  Gyda pwy y dylswn i gysylltu?",
  FAQ_17A1: "Os hoffech chi wneud awgrym neu anfon adborth, e-bostiwch ",
  FAQ_17A2: "",

  UNKNOWN_USER: "anhysbys defnyddiwr",
  UNKNOWN_TASK: "anhysbys tasg",
  UNKNOWN_EVENT: "anhysbys digwyddiad",
  UNKNOWN: "anhysbys",
  UNKNOWN_CIRCLE: "anhysbys cylchoedd",
  UNKNOWN_MED: "anhysbys meddyginiaeth",
  UNKNOWN_SERVICE: "anhysbys service",
  UNKNOWN_NOTE: "anhysbys nodyn",

  DAYS_MON: "Llun",
  DAYS_TUE: "Maw",
  DAYS_WED: "Mer",
  DAYS_THUR: "Iau",
  DAYS_FRI: "Gwe",
  DAYS_SAT: "Sad",
  DAYS_SUN: "Sul",

  MONTH_JANUARY: "Ionawr",
  MONTH_FEBRYARY: "Chwefror",
  MONTH_MARCH: "Mawrth",
  MONTH_APRIL: "Ebrill",
  MONTH_MAY: "Mai",
  MONTH_JUNE: "Mehefin",
  MONTH_JULY: "Gorffennaf",
  MONTH_AUGUEST: "Awst",
  MONTH_SEPTEMBER: "Medi",
  MONTH_OCTOBER: "Hydref",
  MONTH_NOVEMBER: "Tachwedd",
  MONTH_DECEMBER: "Rhagfyr",

  MONTH_JAN: "Ion",
  MONTH_FEB: "Chwe",
  MONTH_MAR: "Maw",
  MONTH_APR: "Ebr",
  MONTH_M: "Mai",
  MONTH_JUN: "Meh",
  MONTH_JUL: "Gor",
  MONTH_AUG: "Aws",
  MONTH_SEP: "Med",
  MONTH_OCT: "Hyd",
  MONTH_NOV: "Tach",
  MONTH_DEC: "Rhag",

  NEW_TEXT_1: "Ydych chi’n siŵr eich bod chi eisiau dileu’r nodyn hwn?",
  NEW_TEXT_2: "Ydych chi’n siŵr eich bod chi eisiau dileu’r dasg hon?",
  NEW_TEXT_3:
    "Bydd unrhyw dasgau sydd yn cael eu neilltuo i'r rhestr hon ddim yn cael eu dileu, ond byddant yn cael eu tynnu o'r rhestr. Ydych chi'n siŵr eich bod chi eisiau dileu’r rhestr dasgau hon?",
  NEW_TEXT_4: "Ydych chi’n siŵr eich bod chi eisiau dileu’r feddyginiaeth hon?",
  NEW_TEXT_5:
    "Ydych chi’n siŵr eich bod chi eisiau dileu pob achos o’r digwyddiad hwn?",
  NEW_TEXT_6: "Mae hwn yn ddigwyddiad sy’n ailddigwydd",
  NEW_TEXT_7: "Golygu pob digwyddiad yn y dyfodol",
  NEW_TEXT_8: "Golygu’r digwyddiad hwn yn unig",
  NEW_TEXT_9: "Cyflwyno",
  NEW_TEXT_10:
    "Os ydych chi wedi cysylltu eich cylch Jointly â dyfais allanol, yna bydd negeseuon o'r ddyfais honno yn ymddangos yn yr adran hon.",
  NEW_TEXT_11:
    "Rhagor o wybodaeth am y dyfeisiau y gall Jointly gysylltu â nhw",
  NEW_TEXT_12: "Dileu’r Cyfrif",
  NEW_TEXT_13: "Canlyniadau Chwilio",

  BASIC_INFO: "Gwybodaeth sylfaenol",
  BASIC_INFO_TEXT1:
    "Rhowch wybodaeth am y person sy'n derbyn gofal. Gallwch weld meysydd ychwanegol a chreu eich meysydd personol eich hun ar y tab 'Gwybodaeth ychwanegol'.",
  LIKESCALLED: "Yn hoffi cael ei alw",
  PREFER_NAME: "hoff enw",
  DETILS_CIRCLE: "Manylion unrhyw anawsterau penodol",
  DETAILS_TOOLTIP:
    "e.e. Manylion cyfathrebu, symudedd, deheurwydd neu gof, anawsterau golwg neu glyw, problemau canolbwyntio, a ellir gadael y person rydych yn gofalu amdano ar ei ben ei hun ac am ba mor hir, neu anawsterau eraill y gallai rhywun ddod ar eu traws wrth geisio helpu.",
  DETAILS_PLACEHOLDER:
    "e.g. Has problems with short term memory and can't understand speech without hearing aid",
  ASSISTANCE:
    "Pa gymorth arbennig mae'r unigolyn rydych chi'n gofalu amdano ei angen?",
  ASSISTANCE_TOOLTIP:
    "Gallai helpu i ddechrau o'r peth cyntaf yn y bore i'r peth olaf yn y nos, i gynnwys yr holl gymorth penodol sydd ei angen ar y person. Dylech gynnwys p’un a oes angen cymorth yn ystod y nos arnoch hefyd. Dylech gynnwys unrhyw faterion emosiynol/seicolegol ac anghenion cymorth.",
  POPUP_SAVE:
    "Cofiwch arbed eich gwybodaeth sylfaenol isod cyn ychwanegu gwybodaeth ychwanegol",
  ADDITIONAL_INFO: "Gwybodaeth ychwanegol",
  ADDITIONAL_TEXT1:
    "Gallwch ddefnyddio'r dudalen hon i gadw gwybodaeth ychwanegol am yr unigolyn rydych chi'n gofalu amdano",
  ADDITIONAL_TEXT2:
    "Rydym wedi darparu rhywfaint o enghreifftiau o'r math o wybodaeth a allai fod yn ddefnyddiol i chi ei storio yn Jointly. Gallwch symud neu ddileu unrhyw rai o'r meysydd hyn, neu greu eich un eich hun i addasu Jointly fel ei fod yn fwy addas i'ch sefyllfa ofalu. Os ydych chi eisiau cyfyngu mynediad i faes, gallwch ei farcio'n 'Breifat' yn defnyddio'r eicon clo, a dewis pa aelodau all gael gafael ar wybodaeth categori preifat.",
  ADDITIONAL_TEXT3:
    "Cliciwch yma i gael rhagor o wybodaeth ar sut i ddefnyddio Jointly ar gyfer cynllunio wrth gefn",
  ADDITIONAL_TEXT4: "Cynllunio wrth gefn",
  ADDITIONAL_TEXT5:
    "Mae cael cynllun wrth gefn rhag ofn y bydd argyfwng pan na fyddwch chi'n gallu darparu cefnogaeth yn bwysig dros ben. Os ydych chi'n diweddaru'r adrannau hyn, gallwch rannu gyda'r cylch neu allgludo'r wybodaeth fel cynllun wrth gefn i gael ei rannu gyda rhywun proffesiynol neu rhywun arall sy'n darparu cefnogaeth.",
  OTHERS_DELETE_ALL_TITLE: "Ydych chi eisiau dileu pob hysbysiad?",
  OTHERS_DELETE_ALL:
    "Ydych chi'n siŵr eich bod chi eisiau dileu pob neges? Ni fyddwch yn gallu dadwneud y weithred hon",

  CUSTOM_FIELD_NAME_BLANK_ERROR: "Rhowch enw'r maes",
  CUSTOM_FIELD_NAME: "Enw'r maes",
  CUSTOM_FIELD_TYPE: "Enw'r maes",
  SHORT_TEXT: "Testun byr",
  LONG_TEXT: "Testun hir",
  ADD_FIELD: "Ychwanegu meysydd",
  DATE: "Dyddiad",

  CUST_FIELD1: "Golygu meysydd personol",
  CUST_FIELD2: "Ychwanegu maes personol",
  CUSTOM_FIELD: "Ychwanegu meysydd personol",
  CUST_FIELD3:
    "(dewisol) rhowch ddisgrifiad o beth mae'r maes hwn yn cael ei ddefnyddio ar ei gyfer",
  CUST_FIELD4: "Ydy hwn yn breifat?",
  CUST_FIELD5: "Ydych chi’n siŵr eich bod chi eisiau dileu’r maes hwn?",
  CUST_FIELD6: "Golygu proffil",
  CUST_FIELD7: "Rhagor o wybodaeth",
  CUST_FIELD8: "Llai o wybodaeth",
  CUST_FIELD9: "Cynllunio wrth gefn",
  CUST_FIELD10: "Gwahoddwch rywun i ymuno â’ch cylch Jointly",
  CUST_FIELD11: "RHIF FFÔN ARGYFWNG",
  CUST_FIELD12: "Chi",
  CUST_FIELD13: "Mwy o opsiynau",
  CUST_FIELD14:
    "Cliciwch ar 'Ymuno' i gysylltu eich cylch Jointly â'r ddyfais hon. Bydd hyn yn awdurdodi'r ddyfais i anfon gwybodaeth i'ch cylch Jointly. Cyn gynted ag y byddwch yn derbyn gwybodaeth gan ddyfais, bydd yn cael ei storio mewn adran 'Dyfeisiau' a fydd yn ymddangos yn y ddewislen ar y chwith ar Jointly. Os hoffech ddatgysylltu eich cylch o ddyfais, yna dewiswch 'Gadael'",
  CUST_FIELD15: "Dilëwch bob un",
  CUST_FIELD16: "Lleoliad",
  CUST_FIELD17: "Heb ei nodi",
  CUST_FIELD18: "Ychwanegu lleoliad newydd",
  CUST_FIELD19:
    "Ceisiwch gynnwys unrhyw wybodaeth sy'n helpu i leoli a chael mynediad i leoliad y feddyginiaeth",
  CUST_FIELD20: "Yn lle mae'r feddyginiaeth yn cael ei chadw?",
  CUST_FIELD21:
    "Nodiadau ychwanegol (gwybodaeth ar ail-lenwi, yr help sydd ei angen, ac ati)",
  CUST_FIELD22: "Nodiadau ychwanegol",
  CUST_FIELD23: "Rhif ffôn argyfwng (ICE)",
  CATS1: "Dim categori",
  CATS2: "Categorïau",
  CATS4: "Dim rhestr wedi'i darganfod",
  EXPORT1: "Allgludo Llawn/Personol",
  EXPORT2: "Opsiynau llwytho i lawr",
  EXPORT3: "Dewiswch Gylch",
  EXPORT4: "Dewiswch gylch",
  EXPORT5: "Dylech gnnwys y categori data preifat",
  EXPORT6: "Dewiswch fformat",
  EXPORT7: "Mwy o opsiynau",
  EXPORT8: "Dylech gynnwys atodiadau o luniau/dogfennau",
  EXPORT9: "Allgludo pob adran",
  EXPORT10:
    "Dylech gynnwys tasgau wedi'u cwblhau a digwyddiadau o'r gorffennol",
  EXPORT11: "Dylech gynnwys data o gategorïau penodol yn unig",
  EXPORT12: "Dewiswch gategorïau",
  EXPORT13:
    "Mae'r gosodiad hwn ond yn berthnasol i ddata o'r adrannau Nodiadau, Digwyddiadau, Tasgau a Chysylltiadau. Bydd yr holl ddata arall o'r adrannau y gofynnwyd amdanynt yn cael eu cynnwys.",
  EXPORT14: "Dewiswch ddyddiad ar gyfer digwyddiadau",
  EXPORT15: "Dyddiad dechrau",
  EXPORT16: "Dyddiad gorffen",
  EXPORT17: "Dyddiad creu'r eitem",
  EXPORT18: "Allgludo",
  EXPORT19:
    "Dylech ond allgludo data sydd wedi cael eu creu o fewn yr ystod dyddiad",
  EXPORT20: "Cynllun wrth gefn",
  EXPORT21:
    "Defnyddiwch hwn i allgludo set o wybodaeth hanfodol o Jointly y gallwch ei rhannu gyda rhywun os na allwch ofalu oherwydd salwch neu unrhyw reswm arall. Gallwch ddewis cynnwys neu eithrio unrhyw wybodaeth rydych chi wedi'i marcio'n breifat o fewn Jointly.",
  EXPORT22: "Pa wybodaeth fydd yr eitem allgludo hwn yn ei gynnwys?",
  EXPORT23:
    "Yr holl wybodaeth sy'n cael ei storio ym mroffil cylch y person rydych chi'n gofalu amdano",
  EXPORT24:
    "Pob cyswllt ac aelod o'ch cylch, gan gynnwys eu holl wybodaeth gyswllt sydd wedi'i storio yn Jointly",
  EXPORT25: "Yr holl wybodaeth ar feddyginiaeth, dognau a lleoliadau",
  EXPORT26:
    "(dewisol) Nodiadau - gallwch ddewis allgludo'r holl Nodiadau neu'r rheiny sydd wedi'u neilltuo i gategorïau penodol yn unig.",
  EXPORT27:
    "Os ydych chi eisiau allgludo unrhyw wybodaeth ychwanegol, yna defnyddiwch yr opsiwn allgludo meysydd  'Personol', naill ai yn lle neu yn ychwanegol at allgludo eich cynllun wrth gefn.",
  EXPORT28: "Dewiswch Gylch",
  EXPORT29: "Dylech gynnwys y categori data preifat",
  EXPORT30: "Dylech gynnwys Nodiadau",
  EXPORT31: "Dewiswch fformat",
  EXPORT32: "Dylech gynnwys atodiadau o luniau/dogfennau",
  EXPORT33: "Teipiwch eich term chwilio uchod",
  EXPORT34: "Categorïau Pob Nodyn",
  EXPORT35: "Cynhwyswch nodiadau atgoffa gweithredol yn unig",
  EXPORT36: "Er Atgof",
  EXPORTTOCSV: "Allforio i CSV",

  WELCOME1: "Croeso i Jointly!",
  WELCOME2:
    "Ap ffôn symudol ac ar-lein ydy Jointly, a grëwyd gan ofalwyr i ofalwyr. Mae wedi cael ei ddylunio i wneud gofalu ychydig yn haws, yn llai o straen ac yn fwy trefnus.",
  WELCOME3:
    "Os oes gennych unrhyw ymholiadau am Jointly neu unrhyw awgrymiadau ynghylch sut y gallwn ei wella i gyd-fynd yn well â'ch sefyllfa ofalu, yna cysylltwch â ni yn jointlyappsupport@carersuk.org",
  WELCOME4: "Beth sy'n newydd",
  WELCOME5:
    "Mae Jointly wedi cael ei ddiweddaru!  Mae nodweddion newydd yn cynnwys y canlynol:",
  WELCOME6:
    "Gallwch ddefnyddio'r swyddogaeth Nodyn atgoffa, i sefydlu naill ai “rhestrau” atgoffa un tro, neu'n ailddigwydd.",
  WELCOME7:
    "Gallwch greu rhestrau atgoffa ar gyfer pethau fel siopa bwyd, neu dasgau, ac ychwanegu eitemau llinell yn hawdd ar gyfer pob rhestr.",
  WELCOME8:
    "Mae'n hawdd ticio'r eitemau llinell hyn i ffwrdd wrth i ofalwr weithredu'r rhestr Atgoffa.",
  WELCOME9:
    "Mae pob rhestr Atgoffa yn cofnodi hanes llawn gweithredu, felly mae'n hawdd gweld pwy wnaeth beth a phryd, gan roi tawelwch meddwl llawn i chi.",
  WELCOME10:
    "",
  WELCOME11:
    "Gweler y Canllaw Defnyddiwr am ragor o wybodaeth am y rhain a nodweddion eraill ar y Cyd.",
  WELCOME12: "Rhestrau atgoffa",

  TABLEFULLNAME: "Enw llawn",
  TABLEFIRSTNAME: "Enw cyntaf",
  TABLELASTNAME: "Enw olaf",
  TABLEEMAIL: "E-bost",
  TABLEJOINEDDATE: "Dyddiad Ymuno",
  TABLEUSERTYPE: "Math o Ddefnyddiwr",
  TABLESTATUS: "Statws",

  ANALYTICS_START_DATE: "Dyddiad cychwyn",
  ANALYTICS_END_DATE: "Dyddiad Gorffen",
  ANALYTICS_PROMOCODE: "Cod promo",
  ANALYTICS_TOTAL_USERS: "Cyfanswm y Defnyddwyr",
  ANALYTICS_ENGLISH_USERS: "Defnyddwyr Saesneg",
  ANALYTICS_WELSH_USERS: "Defnyddwyr Cymraeg",
  ANALYTICS_ANDROID_USERS: "Defnyddwyr Android",
  ANALYTICS_IOS_USERS: "Defnyddwyr iOS",

  COMMENTS_SORT_BY_LABEL: "Trefnu sylwadau",
  COMMENTS_SORT_BY_NEWEST_FIRST: "Y diweddaraf yn gyntaf",
  COMMENTS_SORT_BY_OLDEST_FIRST: "Hynaf yn gyntaf",
  COMMENTS_DELETE: "Dileu",
  COMMENTS_EDIT: "Golygu",

  MAINTENANCE_TITLE:
    "Mae'r safle i lawr ar gyfer gwaith cynnal a chadw ar hyn o bryd",
  MAINTENANCE_PARA1: "Ymddiheurwn am unrhyw anghyfleustra a achosir.",
  MAINTENANCE_PARA2: "Rydyn ni bron â gwneud.",

  PAGE_NOT_FOUND_TITLE: "Wps! Tudalen heb ei chanfod",
  PAGE_NOT_FOUND_PARA:
    "Mae'n ddrwg gennym, nid yw'r dudalen yr ydych yn chwilio amdani yn bodoli.",
  PAGE_NOT_FOUND_BACK: "Yn ôl adref",
};
